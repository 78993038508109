import { Reducer } from "use-immer";
import { baseUrl } from "api/config";
import { FieldAttribute, FieldType, FormField, IQuestionState, ISection, ISectionQuery } from "..";
import { IQuestionQuery } from "client/containers";
import { IUseErrorHook } from "shared/hooks";
import { DropResult } from "react-beautiful-dnd";

export type IQuestionAction =
	| {
			type: "ON_VALUE_CHANGE";
			parentId: number | undefined;
			fieldId: number;
			formId: number;
			fieldAttribute: FieldAttribute;
			fieldType: FieldType;
			value: string | undefined;
			valueId?: number;
	  }
	| {
			type: "ADD_NEW_FORMFIELD";
			formId: number;
			sectionId: number;
			fieldId: number;
	  }
	| {
			type: "SET_QUESTIONS";
			formFields: FormField[];
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  }
	| {
			type: "SET_SECTIONS";
			sections: ISection[];
	  }
	| {
			type: "ON_FIELD_TYPE_CHANGE";
			parentId: number | undefined;
			fieldId: number;
			fieldType: FieldType;
	  }
	| {
			type: "ADD_NEW_OPTION";
			parentId: number | undefined;
			fieldId: number;
			valueId: number;
	  }
	| {
			type: "ON_REMOVE_QUESTION";
			parentId: number | undefined;
			fieldId: number;
	  }
	| {
			type: "ON_CREATE";
	  }
	| {
			type: "ON_REMOVE_OPTION";
			parentId: number | undefined;
			fieldId: number;
			valueId: number;
	  }
	| {
			type: "ADD_SUB_QUESTION";
			fieldId: number;
	  }
	| {
			type: "RESET";
	  }
	| {
			type: "ON_DRAG_QUESTION";
			dropResult: DropResult;
			parentId: number | undefined;
	  }
	| {
			type: "RESET_DIRTY_AND_BUSY";
	  };

export const questionReducer: Reducer<IQuestionState, IQuestionAction> = (
	draft,
	action
): IQuestionState => {
	let formField: FormField | undefined = undefined;
	switch (action.type) {
		case "RESET_DIRTY_AND_BUSY":
			draft.isBusy = false;
			draft.isDirty = false;
			return draft;
		case "ON_DRAG_QUESTION":
			if (action.parentId) {
				if (action.dropResult.source && action.dropResult.destination) {
					const formField = findFormField(
						draft.formFields,
						action.parentId ? action.parentId : 0
					);
					if (formField) {
						const [removed] = formField.childFormFields.splice(
							action.dropResult.source.index,
							1
						);
						formField.childFormFields.splice(
							action.dropResult.destination.index,
							0,
							removed
						);
					}
				}
			} else {
				if (action.dropResult.source && action.dropResult.destination) {
					draft.sortList = [];
					const [removed] = draft.formFields.splice(action.dropResult.source.index, 1);
					draft.formFields.splice(action.dropResult.destination.index, 0, removed);
				}
			}

			draft.formFields.forEach((formField: FormField, index: number) => {
				formField.sortOrder = index;
				formField.childFormFields.forEach(
					(childFormField: FormField, childIndex: number) => {
						childFormField.sortOrder = childIndex;
					}
				);
				//draft.sortList.push({ id: formField.fieldId, sortOrder: formField.sortOrder });
			});
			draft.isDirty = true;

			return draft;
		case "RESET":
			draft.canSubmit = false;
			draft.formFields = [];
			draft.isBusy = false;
			draft.nextFieldId = 0;
			draft.nextValueId = 0;
			draft.isDirty = false;
			return draft;
		case "ON_VALUE_CHANGE":
			draft.isDirty = true;
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.formFields, action.parentId);
				if (parentFormField) {
					formField = parentFormField.childFormFields.find(
						(s) => s.fieldId === action.fieldId
					);
				}
			} else {
				formField = findFormField(draft.formFields, action.fieldId);
			}
			if (formField) {
				switch (action.fieldAttribute) {
					case "Required":
						formField.isRequired = action.value ? false : true;
						return draft;
					case "title":
						formField.title = action.value;
						return draft;
					case "description":
						formField.description = action.value;
						return draft;
					case "fieldCode":
						formField.fieldCode = action.value;
						return draft;
					case "optionTitle":
						const formTitleValue = formField.formFieldValues.find(
							(s) => s.valueId === action.valueId
						);
						if (formTitleValue) {
							formTitleValue.title = action.value ? action.value : "";
						}
						return draft;
					case "optionValue":
						const formValue = formField.formFieldValues.find(
							(s) => s.valueId === action.valueId
						);
						if (formValue) {
							formValue.value = action.value ? action.value : "";
							formValue.title = action.value ? action.value : "";
						}
						return draft;
					case "displayWhen":
						formField.fieldConfiguration.displayWhen = action.value;
						return draft;
					case "questionNo":
						formField.questionNo = action.value ? action.value : "";
						return draft;
					case "min":
						formField.fieldConfiguration.min = action.value;
						return draft;
					case "max":
						formField.fieldConfiguration.max = action.value;
						return draft;
					default:
						throw Error("unknown action");
				}
			}
			return draft;
		case "SET_QUESTIONS":
			draft.formFields = action.formFields;
			draft.sortList = [];
			draft.formFields.forEach((question: FormField, index: number) => {
				question.parentId = question.parentId === null ? undefined : question.parentId;
				//draft.sortList.push({ id: question.fieldId, sortOrder: index });
			});
			draft.isBusy = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		case "SET_SECTIONS":
			draft.sections = action.sections;
			draft.isBusy = false;
			return draft;
		case "ADD_SUB_QUESTION":
			draft.nextFieldId = draft.nextFieldId - 1;
			draft.nextValueId = draft.nextValueId - 1;
			formField = findFormField(draft.formFields, action.fieldId);
			if (formField) {
				formField.childFormFields.push({
					sortOrder: 0,
					questionNo: "",
					parentId: formField.fieldId,
					fieldId: draft.nextFieldId,
					formId: formField.formId,
					fieldConfiguration: { displayWhen: "", min: "", max: "", answerOptions: [] },
					sectionId: formField.sectionId,
					fieldCode: "",
					title: "",
					description: "",
					fieldType: 1,
					isRequired: false,
					default: "",
					formFieldValues: [
						{
							valueId: draft.nextValueId,
							fieldId: action.fieldId,
							title: "",
							value: "",
							answers: [],
							sortOrder: 0,
						},
					],
					visible: false,
					childFormFields: [],
				});
			}
			return draft;
		case "ADD_NEW_FORMFIELD":
			draft.nextFieldId = draft.nextFieldId - 1;
			draft.nextValueId = draft.nextValueId - 1;
			draft.formFields.push({
				sortOrder: 0,
				questionNo: "",
				parentId: undefined,
				fieldId: action.fieldId,
				formId: action.formId,
				sectionId: action.sectionId,
				fieldConfiguration: { displayWhen: "", min: "", max: "", answerOptions: [] },
				fieldCode: "",
				title: "",
				description: "",
				fieldType: 1,
				isRequired: false,
				default: "",
				formFieldValues: [
					{
						valueId: draft.nextValueId,
						fieldId: action.fieldId,
						title: "",
						value: "",
						answers: [],
						sortOrder: 0,
					},
				],
				visible: false,
				childFormFields: [],
			});
			return draft;
		case "ON_FIELD_TYPE_CHANGE":
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.formFields, action.parentId);
				if (parentFormField) {
					formField = parentFormField.childFormFields.find(
						(s) => s.fieldId === action.fieldId
					);
				}
			} else {
				formField = findFormField(draft.formFields, action.fieldId);
			}
			if (formField) {
				formField.formFieldValues = [];
				formField.fieldType = action.fieldType;
				if (action.fieldType === FieldType.PF23) {
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Advisor Name",
						value: "",
						valueId: 0,
						answers: [],
						sortOrder: 1,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Business",
						value: "",
						valueId: 0,
						answers: [],
						sortOrder: 2,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Contact",
						value: "",
						valueId: 0,
						answers: [],
						sortOrder: 3,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Good",
						value: "good",
						valueId: 0,
						answers: [],
						sortOrder: 4,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Ok",
						value: "ok",
						valueId: 0,
						answers: [],
						sortOrder: 5,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "Poor",
						value: "poor",
						valueId: 0,
						answers: [],
						sortOrder: 6,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "N/A",
						value: "N/A",
						valueId: 0,
						answers: [],
						sortOrder: 7,
					});
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "None, but we need someone",
						value: "none",
						valueId: 0,
						answers: [],
						sortOrder: 8,
					});
				} else {
					formField.formFieldValues.push({
						fieldId: formField.fieldId,
						title: "",
						value: "",
						valueId: 0,
						answers: [],
						sortOrder: 9,
					});
				}
			}
			return draft;
		case "ADD_NEW_OPTION":
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.formFields, action.parentId);
				if (parentFormField) {
					formField = parentFormField.childFormFields.find(
						(s) => s.fieldId === action.fieldId
					);
				}
			} else {
				formField = findFormField(draft.formFields, action.fieldId);
			}
			draft.nextValueId = draft.nextValueId - 1;
			if (formField) {
				formField.formFieldValues.push({
					fieldId: formField.fieldId,
					title: "",
					value: "",
					valueId: draft.nextValueId,
					answers: [],
					sortOrder: 0,
				});
			}
			return draft;
		case "ON_REMOVE_QUESTION":
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.formFields, action.parentId);
				if (parentFormField) {
					parentFormField.childFormFields = parentFormField.childFormFields.filter(
						(s) => s.fieldId !== action.fieldId
					);
				}
			} else {
				draft.formFields = draft.formFields.filter((s) => s.fieldId !== action.fieldId);
			}
			return draft;
		case "ON_CREATE":
			const newQuestions = draft.formFields.filter((s) => s.fieldId <= 0);
			setfieldIdForCreate(newQuestions);
			draft.canSubmit = true;
			return draft;
		case "ON_REMOVE_OPTION":
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.formFields, action.parentId);
				if (parentFormField) {
					formField = parentFormField.childFormFields.find(
						(s) => s.fieldId === action.fieldId
					);
				}
			} else {
				formField = findFormField(draft.formFields, action.fieldId);
			}
			if (formField) {
				if (formField.formFieldValues.length > 1)
					formField.formFieldValues = formField.formFieldValues.filter(
						(s) => s.valueId !== action.valueId
					);
			}
			return draft;
		default:
			throw Error("unknown action");
	}
};

const setfieldIdForCreate = (formFields: FormField[]): FormField[] => {
	for (let i: number = 0; i < formFields.length; i += 1) {
		formFields[i].fieldId = 0;
		formFields[i].formFieldValues.forEach((option) => {
			option.fieldId = 0;
			option.valueId = 0;
		});
		formFields[i].childFormFields = setfieldIdForCreate(formFields[i].childFormFields);
	}
	return formFields;
};

const findFormField = (formFields: FormField[], fieldId: number): FormField | undefined => {
	let result: FormField | undefined = undefined;
	for (let i: number = 0; i < formFields.length; i += 1) {
		if (formFields[i].fieldId === fieldId) {
			result = formFields[i];
			return result;
		} else {
			result = findFormField(formFields[i].childFormFields, fieldId);
			if (result !== undefined) {
				return result;
			}
		}
	}
	return result;
};

const axios = require("axios");

export const getSections = (
	dispatcher: React.Dispatch<IQuestionAction>,
	query: ISectionQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		axios
			.get(baseUrl + "Form/Sections/" + query.formId)
			.then((res: any) => {
				const sections: ISection[] = res.data;
				dispatcher({ type: "SET_SECTIONS", sections });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const createQuestion = async (
	dispatcher: React.Dispatch<IQuestionAction>,
	formFields: FormField[],
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "SET_BUSY", isBusy: true });
	try {
		axios
			.post(baseUrl + "Form/AddQuestions", formFields)
			.then((res: any) => {
				dispatcher({ type: "RESET" });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getQuestionsForEdit = async (
	dispatcher: React.Dispatch<IQuestionAction>,
	query: IQuestionQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.post(baseUrl + "Form/Questions", query)
			.then((res: any) => {
				const formFields = res.data as FormField[];
				dispatcher({ type: "SET_QUESTIONS", formFields });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const updateQuestions = async (
	dispatcher: React.Dispatch<IQuestionAction>,
	formFields: FormField[],
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.put(baseUrl + "Form/EditQuestions", formFields)
			.then((res: any) => {
				dispatcher({ type: "RESET_DIRTY_AND_BUSY" });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};
