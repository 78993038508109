import { Stack, TextField, PrimaryButton, Text, Link } from "@fluentui/react";
import { baseUrl } from "api/config";
import Axios from "axios";
import React from "react";
import { useImmer } from "use-immer";
import { AvForm } from "availity-reactstrap-validation";
import { Spinner } from "reactstrap";
import { Input } from "../components/Controls/Input";

export const ForgotPassword = (props: any) => {
	const [state, setState] = useImmer({
		isBusy: false,
		isSuccess: false,
		emailAddress: "",
		errorMessage: "",
		emailAddressErrorMessage: "",
	});
	const handleClick = () => {
		setState((draft) => {
			draft.isBusy = true;
		});
		Axios.post(baseUrl + `auth/forgot/${state.emailAddress}`)
			.then((res) => {
				setState((draft) => {
					draft.isBusy = false;
					draft.isSuccess = true;
				});
			})
			.catch((err) => console.log(err));
	};
	const onChange = (event: any) => {
		setState((draft) => {
			draft.emailAddress = event.target.value;
		});
	};

	return (
		<>
			<h3 className="head-style-01">Forgot Password</h3>
			{state.errorMessage && (
				<div>
					<span style={{ color: "red" }}>{state.errorMessage}</span>
				</div>
			)}
			{state.isSuccess ? (
				<>
					<h4>Password has been sent to your registered email address</h4>
					<button className="primary-button" onClick={props.toggleClick}>
						LOGIN {state.isBusy && <Spinner />}
					</button>
				</>
			) : (
				<AvForm className="base-form" onValidSubmit={handleClick}>
					<Input
						type="email"
						name="email"
						label="Email"
						required="true"
						validate={{ email: true }}
						autoComplete="off"
						value={state.emailAddress}
						onChange={onChange}
					/>
					<button className="primary-button" type="submit">
						RESET PASSWORD {state.isBusy && <Spinner />}
					</button>
					<div className="signup-link">
						<Link onClick={props.toggleClick}>Back</Link>
					</div>
				</AvForm>
			)}
		</>
	);

	return (
		<>
			<Stack.Item>
				<Text variant={"xxLarge"}>Forgot Password</Text>
			</Stack.Item>
			{state.errorMessage && (
				<Stack.Item>
					<span style={{ color: "red" }}>{state.errorMessage}</span>
				</Stack.Item>
			)}

			{state.isSuccess ? (
				<h4>Password has been sent to your registered email address</h4>
			) : (
				<>
					<Stack.Item>
						<TextField
							type={"email"}
							value={state.emailAddress}
							onChange={onChange}
							label="Email"
							errorMessage={state.emailAddressErrorMessage}
						/>
					</Stack.Item>
					<Stack horizontal horizontalAlign="end">
						<Stack.Item align={"end"}>
							<Link onClick={props.toggleClick}>Back</Link>
						</Stack.Item>
					</Stack>
					<Stack.Item>
						<PrimaryButton
							style={{ width: "100%" }}
							text="Retrieve"
							onClick={handleClick}
						/>
					</Stack.Item>
				</>
			)}

			{state.isBusy && (
				<Stack.Item>
					<Spinner label="Signing In..." ariaLive="assertive" labelPosition="right" />
				</Stack.Item>
			)}
		</>
	);
};
