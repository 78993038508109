import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Master } from "./master";
import { initializeIcons } from "@uifabric/icons";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
//import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//import "./assets/css/main.css";
import "./assets/main.scss";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";

initializeIcons();

Sentry.init({
	dsn: "https://ec51f6f0e3824f50bd4d096e644ffe9f@o801338.ingest.sentry.io/5904138",
});

ReactDOM.render(<Master />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
