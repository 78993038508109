import { useImmer } from "use-immer";
import { IUseErrorHook, IErrorState } from ".";
import React from "react";

export const useError = (): IUseErrorHook => {
	const [state, setState] = useImmer<IErrorState>({ visible: false, errorMessage: "" });

	const onError = React.useCallback(
		(errorMessage: string | undefined) => {
			setState((draft) => {
				draft.visible = true;
				draft.errorMessage = errorMessage;
			});
		},
		[setState]
	);

	const onErrorClose = React.useCallback(() => {
		setState((draft) => {
			draft.visible = false;
			draft.errorMessage = "";
		});
	}, [setState]);

	return {
		errorstate: state,
		onError,
		onErrorClose,
	};
};
