import { getTheme, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();

export const useStyles = () => {
	return mergeStyleSets({
		root: {
			margin: "0px 30px 30px 30px",
		},
		cardBlock: {
			padding: "20px 25px 25px 25px",
			boxShadow: theme.effects.elevation8,
			backgroundColor: "white",
			width: "350px",
			height: "124px",
		},
		card: {
			padding: "20px 25px 25px 25px",
			boxShadow: theme.effects.elevation8,
			backgroundColor: "white",
		},
		persona: {
			width: "100px",
		},
	});
};
