import { baseUrl } from "api/config";
import { IUseErrorHook } from "shared/hooks";
import { Reducer } from "use-immer";
import { IUser, IUserQuery, IUserRole, IUserState, UserFieldMode } from "..";

export type IUserAction =
	| {
			type: "SET_USERS";
			users: IUser[];
	  }
	| {
			type: "SET_USER_AND_USER_ROLES";
			user: IUser;
			userRoles: IUserRole[];
	  }
	| {
			type: "ADD_USER";
	  }
	| {
			type: "UPDATE_USER";
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  }
	| {
			type: "ON_CHANGE";
			userFieldMode: UserFieldMode;
			newValue?: string;
	  }
	| {
			type: "SUBMITTED";
	  }
	| {
			type: "SET_USER_ROLES";
			userRoles: IUserRole[];
	  };

export const userReducer: Reducer<IUserState, IUserAction> = (draft, action): IUserState => {
	switch (action.type) {
		case "SET_USERS":
			draft.users = action.users;
			draft.isBusy = false;
			draft.init = false;
			return draft;
		case "SET_USER_AND_USER_ROLES":
			draft.init = false;
			draft.selectedUser = action.user;
			draft.roles = action.userRoles;
			draft.isBusy = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		case "ON_CHANGE":
			switch (action.userFieldMode) {
				case "Email":
					draft.selectedUser.emailAddress = action.newValue;
					return draft;
				case "LastName":
					draft.selectedUser.lastName = action.newValue;
					return draft;
				case "FirstName":
					draft.selectedUser.firstName = action.newValue;
					return draft;
				case "Designation":
					draft.selectedUser.designation = action.newValue;
					return draft;
				case "RoleId":
					if (action.newValue) draft.selectedUser.roleId = parseInt(action.newValue, 10);
					return draft;
				case "UserStatus":
					if (action.newValue) draft.selectedUser.userStatus = parseInt(action.newValue);
					return draft;
				default:
					throw Error("unknown action");
			}
		case "SUBMITTED":
			draft.submitted = true;
			return draft;
		case "SET_USER_ROLES":
			draft.isBusy = false;
			draft.roles = action.userRoles;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");

export const getUsers = (
	dispatcher: React.Dispatch<IUserAction>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "User")
			.then((res: any) => {
				const users: IUser[] = res.data;
				dispatcher({ type: "SET_USERS", users });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const createUser = (
	dispatcher: React.Dispatch<IUserAction>,
	user: IUser,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.post(baseUrl + "User", user)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_BUSY", isBusy: false });
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const updateUser = (
	dispatcher: React.Dispatch<IUserAction>,
	user: IUser,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.put(baseUrl + "User", user)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getUserById = (
	dispatcher: React.Dispatch<IUserAction>,
	query: IUserQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "User/" + query.userId)
			.then((res: any) => {
				let user: IUser = res.data;
				let userRoles: IUserRole[] = [];
				try {
					dispatcher({ type: "SET_BUSY", isBusy: true });
					axios
						.get(baseUrl + "User/Roles")
						.then((res: any) => {
							userRoles = res.data;
							dispatcher({ type: "SET_USER_AND_USER_ROLES", user, userRoles });
						})
						.catch((err: any) => {
							onError(err.response.data);
						});
				} catch (e) {
					onError(e.message);
				}
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getUserRoles = (
	dispatcher: React.Dispatch<IUserAction>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "User/Roles")
			.then((res: any) => {
				const userRoles: IUserRole[] = res.data;
				dispatcher({ type: "SET_USER_ROLES", userRoles });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const onRemoveUser = (
	dispatcher: React.Dispatch<IUserAction>,
	userId: number,
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "SET_BUSY", isBusy: true });
	try {
		axios
			.delete(baseUrl + "User/" + userId)
			.then((res: any) => {
				getUsers(dispatcher, onError);
			})
			.catch((err: any) => {
				onError(err.response.data);
				dispatcher({ type: "SET_BUSY", isBusy: false });
			});
	} catch (e) {
		onError(e.message);
		dispatcher({ type: "SET_BUSY", isBusy: false });
	}
};
