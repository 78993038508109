import { INavStyles } from "@fluentui/react";

export const navStyles: Partial<INavStyles> = {
	root: {
		minWidth: 208,
		height: "100vh",
		boxSizing: "border-box",
		border: "1px solid #eee",
	},
};
