import { Stack, TextField } from "@fluentui/react";
import { AuthStateContext } from "authentication";
import React, { useContext } from "react";
import { IQuestionControlProps } from "shared/containers";

export const CurrencyControl: React.FC<IQuestionControlProps> = (props) => {
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const {
		parentId,
		disabled,
		formId,
		fieldId,
		label,
		value,
		valueId,
		fieldAttribute,
		fieldType,
		errorMessage,
		onChange,
	} = props;

	//onst reg = /^(-?\d+(\.\d{0,2})?|\.?\d{1,2})$/;
	const reg = /^\-?\d*\.?\d*$/;
	const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const val: string = event.target.value ? event.target.value : "";
		if (onChange) {
			if (val) {
				if (reg.test(val)) {
					onChange(parentId, fieldId, formId, fieldAttribute, fieldType, val, valueId);
				}
			} else {
				onChange(parentId, fieldId, formId, fieldAttribute, fieldType, val, valueId);
			}
		}
	};
	return (
		// <div className="col-sm-8">
		<div className="form-group">
			{/* <div className="col-md-8"> */}
			{label && <label>{label}</label>}
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<span className="input-group-text">
						{user.currency === "" ? user.currency : "$"}
					</span>
				</div>
				<input
					type="text"
					disabled={disabled}
					value={value}
					onChange={onValueChange}
					className="form-control"
				/>
			</div>
			{/* </div> */}
		</div>
		// </div>
	);
	// return (
	// 	<Stack horizontal>
	// 		<Stack.Item>&#36;&nbsp;</Stack.Item>
	// 		<Stack.Item>
	// 			<TextField
	// 				disabled={disabled}
	// 				label={label}
	// 				value={value}
	// 				errorMessage={errorMessage}
	// 				onChange={onValueChange}
	// 			/>
	// 		</Stack.Item>
	// 	</Stack>
	// );
};
