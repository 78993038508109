import React from "react";
import { IChildAnswerProps } from ".";
import { Answers } from "..";

export const ChildAnswer: React.FC<IChildAnswerProps> = (props) => {
	const { errors, childFormFields, onChange, removeOption, addOption } = props;
	return (
		<Answers
			errors={errors}
			questions={childFormFields}
			onChange={onChange}
			removeOption={removeOption}
			addOption={addOption}
		/>
	);
};
