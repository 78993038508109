import React from "react";
import { IAnswerProps, getAnswerStyle, AnswerOption } from ".";
import { IStackTokens, Stack } from "@fluentui/react";
import { PreviewControl } from "shared/containers";
import { ChildAnswer } from "..";
import { FieldType } from "admin/questionare";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const Answer: React.FC<IAnswerProps> = (props) => {
	const { errors, question, onChange, removeOption, addOption } = props;
	const classes = getAnswerStyle();
	const {
		questionNo,
		parentId,
		fieldId,
		formId,
		title,
		fieldType,
		formFieldValues,
		childFormFields,
		visible,
		fieldConfiguration,
	} = question;

	const isPlainText = fieldType !== FieldType.PlainText && fieldType !== FieldType.SectionHeading;

	const backGroundcolor: string = errors.errors.find((d) => d.fieldId === fieldId)
		? "rgb(255 216 220)"
		: "white";

	return (
		<>
			{(parentId === undefined || visible) && (
				<>
					<div
						style={{ backgroundColor: backGroundcolor }}
						className={parentId === undefined ? "item-block m-b-20" : "m-b-20"}
					>
						<div className="item-padd questionnaire-block">
							<p>
								{isPlainText && questionNo && <b>{questionNo}.</b>}
								{title && (
									<>
										<PreviewControl
											parentId={parentId}
											fieldId={fieldId}
											formId={formId}
											fieldType={FieldType.PlainText}
											fieldAttribute={"answer"}
											value={title}
											disabled={true}
										/>
										{/* <span className="req">*</span> */}
									</>
								)}
							</p>
							<div className="form-wrap">
								{formFieldValues.length === 0 ? (
									<PreviewControl
										parentId={parentId}
										fieldId={fieldId}
										formId={formId}
										fieldType={fieldType}
										fieldAttribute={"answer"}
										disabled={false}
									/>
								) : (
									<AnswerOption
										parentId={parentId}
										fieldId={fieldId}
										formId={formId}
										fieldType={fieldType}
										formFieldValues={formFieldValues}
										fieldConfiguration={fieldConfiguration}
										onChange={onChange}
										removeOption={removeOption}
										addOption={addOption}
									/>
								)}

								{/* <div className="col-sm-4">
									<div className="sub-text">Years</div>
								</div> */}
								{childFormFields.length > 0 && (
									<ChildAnswer
										errors={errors}
										childFormFields={childFormFields}
										onChange={onChange}
										removeOption={removeOption}
										addOption={addOption}
									/>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);

	// return (
	// 	<>
	// 		{(parentId === undefined || visible) && (
	// 			<Stack className={classes.root} tokens={verticalGapStackTokens}>
	// 				{isPlainText && questionNo && (
	// 					<Stack.Item>
	// 						<b>{questionNo}</b>
	// 					</Stack.Item>
	// 				)}
	// 				{title && (
	// 					<PreviewControl
	// 						parentId={parentId}
	// 						fieldId={fieldId}
	// 						formId={formId}
	// 						fieldType={FieldType.PlainText}
	// 						fieldAttribute={"answer"}
	// 						value={title}
	// 						disabled={true}
	// 					/>
	// 				)}
	// 				{formFieldValues.length === 0 ? (
	// 					<PreviewControl
	// 						parentId={parentId}
	// 						fieldId={fieldId}
	// 						formId={formId}
	// 						fieldType={fieldType}
	// 						fieldAttribute={"answer"}
	// 						disabled={false}
	// 					/>
	// 				) : (
	// 					<AnswerOption
	// 						parentId={parentId}
	// 						fieldId={fieldId}
	// 						formId={formId}
	// 						fieldType={fieldType}
	// 						formFieldValues={formFieldValues}
	// 						fieldConfiguration={fieldConfiguration}
	// 						onChange={onChange}
	// 						removeOption={removeOption}
	// 						addOption={addOption}
	// 					/>
	// 				)}
	// 				{childFormFields.length > 0 && (
	// 					<Stack.Item>
	// 						<ChildAnswer
	// 							childFormFields={childFormFields}
	// 							onChange={onChange}
	// 							removeOption={removeOption}
	// 							addOption={addOption}
	// 						/>
	// 					</Stack.Item>
	// 				)}
	// 			</Stack>
	// 		)}
	// 	</>
	// );
};
