import React from "react";
import { Nav } from "@fluentui/react";
import { Link, useHistory } from "react-router-dom";
import { navStyles } from "admin/questionare";
import "./CustomNav.css";
import { useImmer } from "use-immer";

export const CustomNav = (props) => {
	const history = useHistory();

	const { navLinkGroups, activeSection } = props;
	const [menu, setMenu] = useImmer(navLinkGroups[0]);
	const isActive = (key) => {
		if (history.location.search.includes(`section=${key}`)) {
			return "active";
		} else {
			return "";
		}
	};

	const Expand = (key) => {
		setMenu((draft) => {
			draft.links[key].isExpanded = !draft.links[key].isExpanded;
		});
	};

	const onLinkClick = (ev, item) => {
		if (item) {
			const urlLink = `${item.url}`;
			history.replace(urlLink);
		}
		ev && ev.preventDefault();
	};

	const LoadMenu = () => {
		return (
			<>
				{menu.links &&
					menu.links.map((item, index) => {
						return (
							<li className={isActive(item.key)} key={item.key}>
								<Link to={item.url} onClick={() => Expand(index)}>
									{item.name}
								</Link>
								{item.links.length > 0 && (
									<span
										className="submenu-indicator"
										onClick={() => Expand(index)}
									>
										{item.isExpanded ? "-" : "+"}
									</span>
								)}
								{/* {item.links && <LoadMenu submenu={item.links} />} */}
								<ul className={`submenu ${item.isExpanded && "show"}`}>
									{item.links &&
										item.links.map((submenu) => {
											return (
												<li
													className={isActive(submenu.key)}
													key={submenu.key}
												>
													<Link to={submenu.url}>{submenu.name} </Link>
												</li>
											);
										})}
								</ul>
							</li>
						);
					})}
			</>
		);
	};
	return (
		<>
			<div id="answer-menu" className="answer-menu">
				<div className="answer-menu-header">Sections </div>
				<ul>
					<LoadMenu />
				</ul>
			</div>
		</>
	);
	return (
		<>
			<Nav
				selectedKey={activeSection}
				className={navStyles.root}
				onLinkClick={onLinkClick}
				groups={navLinkGroups}
			/>
		</>
	);
};
