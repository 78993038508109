import { Stack } from "@fluentui/react";
import ProtectedRoute from "authentication/ProtectedRoute";
import React from "react";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { IUserProps } from ".";
import { CreateUser, Users } from "..";
import { useStyles } from "./User.styles";

export const User: React.FC<IUserProps> = (props) => {
	const { onUserNavigateBack } = props;
	const match = useRouteMatch();
	const history = useHistory();
	const classes = useStyles();

	const onGetDetail = (userId: number) => {
		const link = `${match.path}/edit?userId=${userId}`;
		history.push(link);
	};

	// const onEdit = (userId?: number) => {
	// 	const link = `${match.path}/edit?userId=${userId}`;
	// 	history.push(link);
	// };

	const onCreate = () => {
		const link = `${match.path}/create`;
		history.push(link);
	};

	return (
		<Switch>
			<ProtectedRoute
				exact={true}
				path={`${match.path}`}
				render={() => {
					return <Users onGetDetail={onGetDetail} onCreate={onCreate} />;
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/create`}
				render={() => {
					return <CreateUser mode={"CREATE"} onUserNavigateBack={onUserNavigateBack} />;
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/edit`}
				render={() => {
					return <CreateUser mode={"EDIT"} onUserNavigateBack={onUserNavigateBack} />;
				}}
			/>
		</Switch>
	);
};
