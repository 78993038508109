import { mergeStyleSets } from "@fluentui/react";

export const useStyles = () => {
	return mergeStyleSets({
		root: {
			height: "calc(100vh - 35px)",
		},
		nav: {
			height: "calc(100vh - 35px)",
		},
		formHolder: {
			height: "calc(100vh - 35px)",
		},
		formContent: {
			height: "calc(100vh - 94px)",
			overflowY: "auto",
		},
	});
};
