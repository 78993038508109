import { getTheme, mergeStyleSets } from "@fluentui/react";
const theme = getTheme();

export const useStyles = () => {
	return mergeStyleSets({
		commandBar: {
			backgroundColor: "red",
		},
		card: {
			padding: "20px 25px 25px 25px",
			boxShadow: theme.effects.elevation8,
			backgroundColor: "white",
		},
		cardMain: {
			boxShadow: theme.effects.elevation8,
			backgroundColor: "white",
		},
		cardMainHeading: {
			padding: "20px 20px 20px 20px",
			height: "10%",
			borderBottomStyle: "solid",
			borderBottomWidth: "1px",
			borderBottomColor: "#cec9c9",
		},
		cardContent: {
			padding: "30px 30px 30px 30px",
			height: "90%",
		},
		smallCard: {
			width: "180px",
			height: "100%",
		},
		count: {
			marginLeft: "32px",
		},
	});
};
