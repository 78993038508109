import { TextField } from "@fluentui/react";
import React from "react";
import { IQuestionControlProps } from "shared/containers";

export const TextAreaControl: React.FC<IQuestionControlProps> = (props) => {
	const {
		parentId,
		disabled,
		formId,
		fieldId,
		label,
		value,
		valueId,
		fieldAttribute,
		fieldType,
		errorMessage,
		onChange,
	} = props;

	const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (onChange)
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				fieldType,
				event.target.value,
				valueId
			);
	};
	return (
		// <div className="col-sm-12">
		<div className="form-group">
			{label && <label>{label}</label>}
			<textarea
				className="form-control"
				disabled={disabled}
				value={value}
				onChange={onValueChange}
			/>
		</div>
		// </div>
	);
	// return (
	// 	<TextField
	// 		multiline
	// 		autoAdjustHeight
	// 		disabled={disabled}
	// 		label={label}
	// 		value={value}
	// 		errorMessage={errorMessage}
	// 		onChange={onValueChange}
	// 	/>
	// );
};
