import { IUser, IUserQuery } from "admin/user";
import { baseUrl } from "api/config";
import { LogOut, UserAuthDispatcher } from "authentication/auth.action";
import Axios from "axios";
import { Input } from "client/components/Controls/Input";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Card, Modal, ModalBody, ModalFooter } from "reactstrap";
import { ErrorDialog, Loading } from "shared/components";
import { useError } from "shared/hooks";
import { useImmer } from "use-immer";
import { IEditProfileProps, IEditProfileState, INewPassword } from ".";
import { useStyles } from "./EditProfile.styles";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CreateBipUser, CreateClient } from "admin";
import { UserType } from "authentication";

export const EditProfile: React.FC<IEditProfileProps> = (props) => {
	const queryString = require("query-string");
	const location = useLocation();
	const userQuery = queryString.parse(location.search) as IUserQuery;
	const classes = useStyles();
	const userAuthDispatcher = UserAuthDispatcher();
	const [state, setState] = useImmer<IEditProfileState>({
		confirmPassword: "",
		newPassword: "",
		oldPassword: "",
		confirmPasswordError: "",
		newPasswordError: "",
		oldPasswordError: "",
		changePasswordIsDirty: false,
		hideDialog: true,
		user: {
			client: "",
			roleId: 0,
			userId: 0,
			userType: UserType.Admin,
			emailAddress: "",
			firstName: "",
			lastName: "",
			userStatus: 0,
			designation: "",
			roleName: "",
		},
		emailAddressError: "",
		firstNameError: "",
		lastNameError: "",
		isDirty: false,
		isBusy: true,
		isPasswordMatchedError: false,
	});
	const { user } = state;
	const { firstName, lastName, emailAddress, roleName } = user;
	const { errorstate, onErrorClose, onError } = useError();
	useEffect(() => {
		if (userQuery && userQuery.userId) {
			Axios.get(baseUrl + "User/" + userQuery.userId)
				.then((res) => {
					const user: IUser = res.data;
					setState((draft) => {
						draft.user = user;
						draft.isBusy = false;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userQuery.userId]);

	if (state.isBusy) {
		return <Loading />;
	}

	const onFirstNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.firstName = newValue;
			draft.isDirty = true;
		});
	};

	const onLastNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.lastName = newValue;
			draft.isDirty = true;
		});
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.emailAddress = newValue;
			draft.isDirty = true;
		});
	};

	const onOldPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.oldPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const onNewPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.newPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const onConfirmPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.confirmPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const openDialog = () => {
		setState((draft) => {
			draft.hideDialog = false;
		});
	};

	const validateUpdate = (): boolean => {
		setState((draft) => {
			draft.firstNameError = draft.user.firstName ? "" : "*Required";
			draft.lastNameError = draft.user.lastName ? "" : "*Required";
			draft.emailAddressError = draft.user.emailAddress ? "" : "*Required";
		});
		if (state.firstNameError && state.lastNameError && state.emailAddressError) {
			return false;
		} else {
			return true;
		}
	};

	const onSave = () => {
		if (validateUpdate()) {
			setState((draft) => {
				draft.isBusy = true;
			});
			Axios.put(baseUrl + "User", state.user)
				.then((res) => {
					setState((draft) => {
						draft.isBusy = false;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
	};

	const validateNewMessage = (): boolean => {
		let status: boolean = false;

		setState((draft) => {
			draft.newPasswordError = draft.newPassword ? "" : "*Required";
			draft.confirmPasswordError = draft.confirmPassword ? "" : "*Required";
			draft.oldPasswordError = draft.oldPassword ? "" : "*Required";
			draft.isPasswordMatchedError = false;
		});
		if (state.newPassword && state.confirmPassword && state.oldPassword) {
			status = true;
			if (state.newPassword !== state.confirmPassword) {
				setState((draft) => {
					draft.isPasswordMatchedError = true;
				});
				return false;
			}
		}
		return status;
	};

	const onSaveNewPassWord = () => {
		if (validateNewMessage()) {
			const newPassword: INewPassword = {
				userId: state.user.userId,
				currentPassword: state.oldPassword,
				newPassword: state.newPassword,
			};
			setState((draft) => {
				draft.isBusy = true;
			});
			Axios.put(baseUrl + "User/ChangePassword", newPassword)
				.then((res) => {
					LogOut(userAuthDispatcher);
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
	};

	const closeAndReset = () => {
		setState((draft) => {
			draft.hideDialog = true;
			draft.confirmPassword = "";
			draft.newPassword = "";
			draft.oldPassword = "";
		});
	};

	const onCancel = () => {
		closeAndReset();
	};
	const onClientNavigateBack = () => {};
	const onDismiss = (ev?: React.MouseEvent<HTMLButtonElement>) => {
		closeAndReset();
	};
	// return (
	// 	<CreateBipUser
	// 		userId={userQuery.userId}
	// 		mode={"EDIT"}
	// 		onBipUserNavigateBack={onClientNavigateBack}
	// 	/>
	// );

	return (
		<>
			{user.userType === UserType.Admin ? (
				<>
					<Breadcrumb tag="nav" listTag="div">
						<BreadcrumbItem tag={Link} to="/bip/home">
							Dashboard
						</BreadcrumbItem>
						<BreadcrumbItem active>Edit Profile</BreadcrumbItem>
					</Breadcrumb>
					<ErrorDialog
						errorMessage={errorstate.errorMessage}
						show={errorstate.visible}
						onClose={onErrorClose}
					/>
					<AvForm onValidSubmit={onSave}>
						<div className="col-md-8">
							<Card>
								<div className="card-body">
									<div className="form-wrap">
										<div className="row">
											<div className="col-sm-6">
												<AvField
													label="First Name"
													name="FirstName"
													value={firstName}
													onChange={onFirstNameChange}
													type="text"
													required
												/>
											</div>
											<div className="col-sm-6">
												<AvField
													label="Last Name"
													name="LastName"
													value={lastName}
													onChange={onLastNameChange}
													type="text"
													required
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												<AvField
													label="Email"
													name="Email"
													value={emailAddress}
													type="email"
													onChange={onEmailChange}
													required
												/>
											</div>
											<div className="col-sm-6">
												<AvField
													label="Role"
													name="Role"
													value={roleName}
													disabled
													type="text"
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												<button
													type="button"
													className="btn btn-outline-primary"
													onClick={openDialog}
												>
													Change Password
												</button>
											</div>
										</div>
										<button className="btn btn-primary mt-2" type="submit">
											Save
										</button>
									</div>
								</div>
							</Card>
						</div>
					</AvForm>
					<Modal centered isOpen={!state.hideDialog}>
						<AvForm onValidSubmit={onSaveNewPassWord}>
							<div className="modal-header">Change Password</div>
							<ModalBody>
								<div className="form-wrap">
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"oldPassword"}
												label="Old Password"
												value={state.oldPassword}
												onChange={onOldPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"newPassword"}
												label="New Password"
												value={state.newPassword}
												onChange={onNewPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"confirmPassword"}
												label="Confirm Password"
												value={state.confirmPassword}
												onChange={onConfirmPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-secondary" onClick={closeAndReset}>
									Cancel
								</button>
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</ModalFooter>
						</AvForm>
					</Modal>
				</>
			) : (
				<CreateBipUser
					userId={userQuery.userId}
					mode={"EDIT"}
					onBipUserNavigateBack={onClientNavigateBack}
				/>
			)}
		</>
	);

	// return (
	// 	<>
	// 		<Stack tokens={verticalGapStackTokens} className={classes.root}>
	// 			<Stack.Item>
	// 				<Breadcrumb
	// 					items={itemsWithHref}
	// 					maxDisplayedItems={3}
	// 					ariaLabel="Breadcrumb with items rendered as links"
	// 					overflowAriaLabel="More links"
	// 				/>
	// 			</Stack.Item>
	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack className={classes.form}>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="First Name"
	// 							value={firstName}
	// 							onChange={onFirstNameChange}
	// 							errorMessage={state.firstNameError}
	// 						/>
	// 					</Stack.Item>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="Last Name"
	// 							value={lastName}
	// 							errorMessage={state.lastNameError}
	// 							onChange={onLastNameChange}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="Email"
	// 							value={emailAddress}
	// 							errorMessage={state.emailAddressError}
	// 							onChange={onEmailChange}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<PrimaryButton text={"Change Password"} onClick={openDialog} />
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack.Item>
	// 					<PrimaryButton disabled={!state.isDirty} text={"SAVE"} onClick={onSave} />
	// 				</Stack.Item>
	// 			</Stack>
	// 		</Stack>
	// 		<Dialog
	// 			hidden={state.hideDialog}
	// 			dialogContentProps={dialogContentProps}
	// 			modalProps={modalProps}
	// 			onDismiss={onDismiss}
	// 		>
	// 			<TextField
	// 				label="Old Password"
	// 				value={state.oldPassword}
	// 				errorMessage={state.oldPasswordError}
	// 				onChange={onOldPasswordChange}
	// 			/>
	// 			<TextField
	// 				label="New Password"
	// 				value={state.newPassword}
	// 				errorMessage={state.newPasswordError}
	// 				onChange={onNewPasswordChange}
	// 			/>
	// 			<TextField
	// 				label="Confirm Password"
	// 				value={state.confirmPassword}
	// 				errorMessage={state.confirmPasswordError}
	// 				onChange={onConfirmPasswordChange}
	// 			/>
	// 			{state.isPasswordMatchedError && (
	// 				<Text style={{ color: "red" }}>*No match in new and confirm password </Text>
	// 			)}
	// 			<DialogFooter>
	// 				<PrimaryButton
	// 					disabled={!state.changePasswordIsDirty}
	// 					onClick={onSaveNewPassWord}
	// 					text="Save"
	// 				/>
	// 				<DefaultButton onClick={onCancel} text="Cancel" />
	// 			</DialogFooter>
	// 		</Dialog>
	// 	</>
	// );
};
