import { baseUrl } from "api/config";
import Axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { ErrorDialog } from "shared/components";
import { useError } from "shared/hooks";
import { useImmer } from "use-immer";
import { useStyles } from "./Roles.styles";

export interface IRolesContainerProps {
	editPermission: (roleId: number, roleName: string) => void;
}

export interface IRole {
	roleId: number;
	roleName: string;
	description: string;
}

export const RolesContainer: React.FC<IRolesContainerProps> = (props) => {
	const { editPermission } = props;
	const { errorstate, onErrorClose, onError } = useError();
	const [roles, setRoles] = useImmer<IRole[]>([]);

	useEffect(() => {
		Axios.get(baseUrl + "User/Roles/")
			.then((res) => {
				const roles: IRole[] = res.data;
				setRoles((draft) => {
					draft = roles;
					return draft;
				});
			})
			.catch((err) => {
				onError(err.response.data);
			});
	}, [onError, setRoles]);

	return (
		<>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem tag={Link} to="home">
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem active>Settings</BreadcrumbItem>
			</Breadcrumb>
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<ErrorDialog
							errorMessage={errorstate.errorMessage}
							show={errorstate.visible}
							onClose={onErrorClose}
						/>
						<div className="card-header bg-white">
							<h4>Roles and Permissions</h4>
						</div>
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>Name</th>
									<th>Description</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{roles.map((item) => {
									return (
										<tr>
											<td>{`${item.roleName}`}</td>
											<td>{item.description}</td>
											<td>
												<button
													className="btn btn-primary"
													disabled={item.roleId === 1 ? true : false}
													onClick={() =>
														editPermission &&
														editPermission(item.roleId, item.roleName)
													}
												>
													Permissions
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<div className="form-group">
								<label>Send Grid SMTP Key</label>
								<input
									className="form-control"
									disabled
									value="SG.w3pYqdLdQ62TTwwBzf7bfQ.1RPp-KyskrQ53C0Azf3wDN2Tctk4Jp0bePZdo4J2f6"
								></input>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
	// return (
	// 	<Stack className={classes.root}>
	// 		<Stack tokens={sectionStackTokens}>
	// 			<Stack>
	// 				<Breadcrumb
	// 					items={itemsWithHref}
	// 					maxDisplayedItems={3}
	// 					ariaLabel="Breadcrumb with items rendered as links"
	// 					overflowAriaLabel="More links"
	// 				/>
	// 			</Stack>
	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack>
	// 				<DetailsList
	// 					items={roles}
	// 					columns={_columns}
	// 					setKey="set"
	// 					layoutMode={DetailsListLayoutMode.justified}
	// 					selectionPreservedOnEmptyClick={true}
	// 					selectionMode={SelectionMode.none}
	// 					onRenderItemColumn={onRenderItemColumn}
	// 				/>
	// 			</Stack>
	// 			<Stack className={classes.cardMain}>
	// 				<Stack.Item className={classes.cardMainHeading}>
	// 					<Stack.Item>
	// 						<Text variant={"xLarge"}>Email Settings</Text>
	// 					</Stack.Item>
	// 				</Stack.Item>
	// 				<Stack horizontal className={classes.cardContent}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="SMTP Key"
	// 							value="SG.w3pYqdLdQ62TTwwBzf7bfQ.1RPp-KyskrQ53C0Azf3wDN2Tctk4Jp0bePZdo4J2f6"
	// 							disabled
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 			</Stack>
	// 		</Stack>
	// 	</Stack>
	// );
};
