import React from "react";
import { ActionMode, EditQuestionProps, IQuestionDescriptionQuery, useQuestion } from ".";
import { useHistory, useRouteMatch, useLocation, Switch } from "react-router-dom";
import {
	INavLinkGroup,
	INavLink,
	Stack,
	IStackTokens,
	CommandBar,
	ICommandBarItemProps,
} from "@fluentui/react";
import { ISection } from "..";
import ProtectedRoute from "authentication/ProtectedRoute";
import { useStyles } from "./EditQuestion.styles";
import { CustomNav } from "admin/questionare/components/Navbar/CustomNav";
import { QuestionsForm } from "admin/questionare";
import { useError } from "shared/hooks";
import { ErrorDialog } from "shared/components";
import { Input } from "client/components/Controls/Input";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const EditQuestion: React.FC<EditQuestionProps> = (props) => {
	const queryString = require("query-string");
	const history = useHistory();
	const location = useLocation();
	const match = useRouteMatch();
	const questionQuery = queryString.parse(location.search) as IQuestionDescriptionQuery;
	const classes = useStyles();
	const { errorstate, onErrorClose, onError } = useError();
	const { state, onChange, onUpdate, addNewOption, removeOption, onDragQuestion } = useQuestion(
		questionQuery.form,
		parseInt(questionQuery.section, 10),
		false,
		onError
	);
	const { isDirty, formFields, isBusy } = state;

	React.useEffect(() => {
		if (questionQuery.section === undefined && state.sections.length > 0) {
			const link = `${match.path}?form=${questionQuery.form}&section=${state.sections[0].sectionId}`;
			history.push(link);
		}
	}, [
		history,
		match.path,
		questionQuery.form,
		questionQuery.section,
		state.sections,
		state.sections.length,
	]);

	const isParentExpanded = (section: ISection) => {
		return section.subSection.filter((c) => c.sectionId === parseInt(questionQuery.section))
			.length > 0
			? true
			: false;
	};
	// const getNavLinkGroup = (): INavLinkGroup[] => {
	// 	let navLinkGroups: INavLinkGroup[] = [{ links: [] }];
	// 	state.sections.forEach((section: ISection, index: number) => {
	// 		const link: INavLink = {
	// 			links: [],
	// 			isExpanded: isParentExpanded(section),
	// 			key: section.sectionId.toString(),
	// 			name: section.name,
	// 			url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
	// 		};
	// 		section.subSection &&
	// 			section.subSection.forEach((section: ISection, index: number) => {
	// 				const childLink: INavLink = {
	// 					isExpanded: isParentExpanded(section),
	// 					key: section.sectionId.toString(),
	// 					name: section.name,
	// 					url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
	// 				};
	// 				link.links?.push(childLink);
	// 			});
	// 		navLinkGroups[0].links.push(link);
	// 	});
	// 	return navLinkGroups;
	// };
	const getNavLinkGroup = () => {
		let navLinkGroups = [{ links: [] as any }];
		state.sections.forEach((section: ISection, index: number) => {
			const link = {
				links: [] as any,
				isExpanded: isParentExpanded(section),
				key: section.sectionId.toString(),
				name: section.name,
				total: section.totalQuestions,
				answered: section.answered,
				url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
			};
			section.subSection &&
				section.subSection.forEach((section: ISection, index: number) => {
					const childLink = {
						isExpanded: isParentExpanded(section),
						key: section.sectionId.toString(),
						name: section.name,
						total: section.totalQuestions,
						answered: section.answered,
						url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
					};
					link.links?.push(childLink);
				});
			navLinkGroups[0].links.push(link);
		});
		return navLinkGroups;
	};

	const onClose = () => {
		const urlLink = `${match.path.replace("edit", "")}details?formId=${questionQuery.form}`;
		history.push(urlLink);
	};

	const onPreview = () => {
		const urlLink = `${match.path.replace("edit", "")}preview?form=${
			questionQuery.form
		}&section=${questionQuery.section}`;
		history.push(urlLink);
	};

	const _farItems: ICommandBarItemProps[] = [
		{
			key: "save",
			text: "Save",
			ariaLabel: "Save",
			iconProps: { iconName: "Save" },
			disabled: !isDirty,
			onClick: () => {
				onUpdate();
			},
		},
		{
			key: "preview",
			text: "Preview",
			ariaLabel: "Preview",
			iconProps: { iconName: "Preview" },
			onClick: () => {
				onPreview();
			},
		},
		{
			key: "close",
			text: "Close",
			ariaLabel: "Close",
			iconProps: { iconName: "ChromeClose" },
			onClick: () => {
				onClose();
			},
		},
	];
	return (
		<Switch>
			<ProtectedRoute
				exact={true}
				path={`${match.path}`}
				render={() => {
					return (
						<>
							<div className="row">
								<div className="col-md-12">
									<div className="border-0 card mb-2 p-2">
										<div className="row">
											<div className="col-md-6">
												{/* <input
													className="form-control"
													name="title"
												></input> */}
											</div>
											<div className="col-md-6">
												<button
													className="btn btn-outline-danger float-right ml-2"
													onClick={onClose}
												>
													Close
												</button>
												<button
													className="btn btn-outline-dark float-right ml-2"
													onClick={onPreview}
												>
													Preview
												</button>
												<button
													className="btn btn-outline-primary float-right ml-2"
													onClick={onUpdate}
												>
													Save
												</button>
											</div>
										</div>
									</div>
									{/* <CommandBar
										style={{
											borderStyle: "solid",
											borderColor: "#eeeeee",
											borderWidth: "1px",
										}}
										items={[]}
										farItems={_farItems}
									/> */}
								</div>
								<div className="row">
									<ErrorDialog
										errorMessage={errorstate.errorMessage}
										show={errorstate.visible}
										onClose={onErrorClose}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									{getNavLinkGroup()[0].links.length > 0 && (
										<CustomNav
											activeSection={questionQuery.section}
											navLinkGroups={getNavLinkGroup()}
										/>
									)}
								</div>

								<div className="col-md-9">
									<QuestionsForm
										parentId={undefined}
										isParent={true}
										isBusy={isBusy}
										action={ActionMode.Edit}
										formFields={formFields}
										onChange={onChange}
										addNewOption={addNewOption}
										removeOption={removeOption}
										onDragQuestion={onDragQuestion}
									/>
								</div>
							</div>
						</>
					);
				}}
			/>
		</Switch>
	);

	// return (
	// 	<Switch>
	// 		<ProtectedRoute
	// 			exact={true}
	// 			path={`${match.path}`}
	// 			render={() => {
	// 				return (
	// 					<Stack className={classes.root}>
	// 						<Stack.Item grow={12}>
	// 							<CommandBar
	// 								style={{
	// 									borderStyle: "solid",
	// 									borderColor: "#eeeeee",
	// 									borderWidth: "1px",
	// 								}}
	// 								items={[]}
	// 								farItems={_farItems}
	// 							/>
	// 						</Stack.Item>
	// 						<Stack>
	// 							<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 						</Stack>
	// 						<Stack.Item grow={12} className={classes.formHolder}>
	// 							<Stack horizontal>
	// 								{/* <Navbar navLinkGroups={getNavLinkGroup()} /> */}
	// 								<CustomNav navLinkGroups={getNavLinkGroup()} />
	// 								<Stack
	// 									tokens={verticalGapStackTokens}
	// 									grow={11}
	// 									className={classes.formContent}
	// 								>
	// 									<QuestionsForm
	// 										isParent={true}
	// 										isBusy={isBusy}
	// 										action={ActionMode.Edit}
	// 										formFields={formFields}
	// 										onChange={onChange}
	// 										addNewOption={addNewOption}
	// 										removeOption={removeOption}
	// 										onDragQuestion={onDragQuestion}
	// 									/>
	// 								</Stack>
	// 							</Stack>
	// 						</Stack.Item>
	// 					</Stack>
	// 				);
	// 			}}
	// 		/>
	// 	</Switch>
	// );
};
