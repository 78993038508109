import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import Axios from "axios";
import { baseUrl } from "api/config";
import { useError } from "shared/hooks";
import { ErrorDialog, Loading } from "shared/components";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export interface IEditPermissionProps {
	roleName: string;
	goToSettings: () => void;
}

export interface IEditPermissionState {
	permissions: IPermission[];
	isDirty: boolean;
	isLoading: boolean;
}

export interface IPermission {
	permissionId: number;
	moduleId: number;
	roleId: number;
	create: boolean;
	read: boolean;
	update: boolean;
	delete: boolean;
	moduleName: string;
	appModule: string;
	userRole: string;
}

export interface IPermissionQuery {
	roleId?: number;
}

export const EditPermissionContainer: React.FC<IEditPermissionProps> = (props) => {
	const { goToSettings, roleName } = props;
	const location = useLocation();
	const queryString = require("query-string");
	const permissionQuery = queryString.parse(location.search) as IPermissionQuery;
	const { errorstate, onErrorClose, onError } = useError();
	const [state, setState] = useImmer<IEditPermissionState>({
		isDirty: false,
		permissions: [],
		isLoading: false,
	});

	useEffect(() => {
		if (permissionQuery.roleId) {
			setState((draft) => {
				draft.isLoading = true;
				return draft;
			});
			Axios.get(baseUrl + "User/Permissions/" + permissionQuery.roleId)
				.then((res) => {
					const permiessions: IPermission[] = res.data;
					setState((draft) => {
						draft.permissions = permiessions;
						draft.isLoading = false;
						return draft;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSave = () => {
		setState((draft) => {
			draft.isLoading = true;
			return draft;
		});
		Axios.put(baseUrl + "User/Permissions", state.permissions)
			.then((res) => {
				setState((draft) => {
					draft.isLoading = false;
					return draft;
				});
			})
			.catch((err) => {
				onError(err.response.data);
			});
	};

	type PermissionType = "create" | "delete" | "update" | "read";
	const onChangePermission = (moduleId: number, permissionType: PermissionType) => {
		setState((draft) => {
			let p = draft.permissions.find((s) => s.moduleId === moduleId);
			if (p) {
				switch (permissionType) {
					case "create":
						p.create = !p.create;
						draft.isDirty = true;
						return draft;
					case "delete":
						p.delete = !p.delete;
						draft.isDirty = true;
						return draft;
					case "read":
						p.read = !p.read;
						draft.isDirty = true;
						return draft;
					case "update":
						p.update = !p.update;
						draft.isDirty = true;
						return draft;
				}
			}
		});
	};

	return (
		<>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem tag={Link} to="/admin/home">
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem tag={Link} to="/admin/settings">
					Settings
				</BreadcrumbItem>
				<BreadcrumbItem active>Edit Permission {roleName} </BreadcrumbItem>
			</Breadcrumb>
			{state.isLoading ? (
				<Loading />
			) : (
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<ErrorDialog
								errorMessage={errorstate.errorMessage}
								show={errorstate.visible}
								onClose={onErrorClose}
							/>
							<div className="card-header bg-white">
								<h4>Permissions</h4>
							</div>
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th>Name</th>
										<th>Create</th>
										<th>Read</th>
										<th>Update</th>
										<th>Delete</th>
									</tr>
								</thead>
								<tbody>
									{state.permissions.map((item) => {
										return (
											<tr>
												<td>{`${item.moduleName}`}</td>
												<td>
													<div className="pt-checkbox">
														<input
															id={`c_${item.moduleId}`}
															type="checkbox"
															checked={item.create}
															onChange={() => {
																onChangePermission(
																	item.moduleId,
																	"create"
																);
															}}
														/>
														<label
															className="pt-checkbox"
															htmlFor={`c_${item.moduleId}`}
														></label>
													</div>
												</td>
												<td>
													<div className="pt-checkbox">
														<input
															id={`r_${item.moduleId}`}
															type="Checkbox"
															checked={item.read}
															onChange={() => {
																onChangePermission(
																	item.moduleId,
																	"read"
																);
															}}
														/>
														<label
															className="pt-checkbox"
															htmlFor={`r_${item.moduleId}`}
														></label>
													</div>
												</td>
												<td>
													<div className="pt-checkbox">
														<input
															id={`u_${item.moduleId}`}
															type="Checkbox"
															checked={item.update}
															onChange={() => {
																onChangePermission(
																	item.moduleId,
																	"update"
																);
															}}
														/>
														<label
															className="pt-checkbox"
															htmlFor={`u_${item.moduleId}`}
														></label>
													</div>
												</td>
												<td>
													<div className="pt-checkbox">
														<input
															id={`d_${item.moduleId}`}
															type="Checkbox"
															checked={item.delete}
															onChange={() => {
																onChangePermission(
																	item.moduleId,
																	"delete"
																);
															}}
														/>
														<label
															className="pt-checkbox"
															htmlFor={`d_${item.moduleId}`}
														></label>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="card-footer bg-white">
								<button
									className="btn btn-primary float-right"
									disabled={!state.isDirty}
									onClick={onSave}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);

	// return (
	// 	<Stack className={classes.root}>
	// 		<Stack tokens={sectionStackTokens}>
	// 			<Stack grow={12}>
	// 				<Stack.Item grow={10} align="start">
	// 					<Breadcrumb
	// 						items={itemsWithHref}
	// 						maxDisplayedItems={3}
	// 						ariaLabel="Breadcrumb with items rendered as links"
	// 						overflowAriaLabel="More links"
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item align="end">
	// 					<PrimaryButton text="Save" disabled={!state.isDirty} onClick={onSave} />
	// 				</Stack.Item>
	// 			</Stack>

	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack>
	// 				<DetailsList
	// 					items={state.permissions}
	// 					columns={_columns}
	// 					setKey="set"
	// 					layoutMode={DetailsListLayoutMode.justified}
	// 					selectionPreservedOnEmptyClick={true}
	// 					selectionMode={SelectionMode.none}
	// 					onRenderItemColumn={onRenderItemColumn}
	// 				/>
	// 			</Stack>
	// 		</Stack>
	// 	</Stack>
	// );
};
