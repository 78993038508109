import React from "react";
import { ILoadingProps } from ".";
import { Spinner } from "reactstrap";

export const Loading: React.FC<ILoadingProps> = (props) => {
	return (
		<div className="d-flex justify-content-center align-items-center">
			<Spinner color="primary">Loading!</Spinner>
		</div>
	);
};
