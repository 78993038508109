import React from "react";
import { IColumn, DetailsList, DetailsListLayoutMode, SelectionMode } from "@fluentui/react";
import { ISectionListProps } from ".";
import { ISection } from "admin/questionare";

export const SectionList: React.FC<ISectionListProps> = (props) => {
	const { sections } = props;
	const _columns: IColumn[] = [
		{
			key: "#",
			name: "#",
			fieldName: "#",
			minWidth: 20,
			maxWidth: 20,
			isResizable: true,
		},
		{
			key: "SectionTitle",
			name: "Section Title",
			fieldName: "name",
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "Items",
			name: "Items",
			fieldName: "totalQuestions",
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
	];

	const onRenderItemColumn = (item: ISection, index?: number, column?: IColumn) => {
		if (!column) return;

		if (column.key === "#" && index !== undefined) {
			return <>{index + 1}</>;
		}
		if (column.key === "SectionTitle") {
			return <>{item.name}</>;
		}
		if (column.key === "Items") {
			return <>{item.totalQuestions}</>;
		}
	};

	return (
		<DetailsList
			items={sections}
			columns={_columns}
			setKey="set"
			layoutMode={DetailsListLayoutMode.justified}
			selectionPreservedOnEmptyClick={true}
			selectionMode={SelectionMode.none}
			onRenderItemColumn={onRenderItemColumn}
		/>
	);
};
