import { IPermission } from "admin/settings";

export interface IAuthState {
	authenticated: string | null;
	token: string | null;
	refreshToken: string | null;
	expiry: string | null;
	user: IAuthenticatedUser;
	credential: IUserCredential;
	isBusy: boolean;
}

export interface IAuthenticatedUser {
	userId: number;
	roleId: number;
	userType: number;
	firstName: string;
	lastName: string;
	emailAddress: string;
	currency: string;
	userRolePermissions: IPermission[];
}

export enum Module {
	Home = 0,
	User = 1,
	Client = 2,
	BAQ = 3,
	Report = 4,
	Settings = 5,
	BIP = 6,
}

export interface IUserCredential {
	emailAddress: string | undefined;
	emailAddressErrorMessage: string | undefined;
	password: string | undefined;
	passwordErrorMessage: string | undefined;
	errorMessage: string | undefined;
}

export enum UserType {
	Admin = 1,
	BIP = 2,
	Client = 3,
}
