import React from "react";
import { IQuestionControlProps } from ".";
import {
	TextFieldControl,
	OptionValueControl,
	DateControl,
	TextAreaControl,
	RichTextControl,
	SortableInputControl,
	MinMaxControl,
	NoAnswerControl,
	TextAndCurrencyControl,
} from "shared/components";
import { FieldType } from "admin/questionare";

export const QuestionControl: React.FC<IQuestionControlProps> = (props) => {
	const { fieldType } = props;
	switch (fieldType) {
		case FieldType.Text:
		case FieldType.MultilineText:
			return <TextFieldControl {...props} />;
		case FieldType.Checkbox:
		case FieldType.MultiSelectCheckbox:
		case FieldType.Likert:
			return <OptionValueControl {...props} />;
		case FieldType.Date:
			return <DateControl {...props} />;
		case FieldType.TextArea:
		case FieldType.MultilineTextArea:
			return <TextAreaControl {...props} />;
		case FieldType.PlainText:
		case FieldType.SectionHeading:
			return <RichTextControl {...props} />;
		case FieldType.Sortable:
			return <SortableInputControl {...props} />;
		case FieldType.Percentage:
		case FieldType.Number:
		case FieldType.Currency:
			return <MinMaxControl {...props} />;
		case FieldType.NoAnswer:
			return <NoAnswerControl {...props} />;
		case FieldType.TextAndCurrency:
			return <TextAndCurrencyControl {...props} />;
	}
	return <div>unknown field type</div>;
};
