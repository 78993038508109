import { TextField } from "@fluentui/react";
import React from "react";
import { IQuestionControlProps } from "shared/containers";

export const NumberControl: React.FC<IQuestionControlProps> = (props) => {
	const {
		parentId,
		disabled,
		formId,
		fieldId,
		label,
		value,
		valueId,
		fieldAttribute,
		fieldType,
		errorMessage,
		onChange,
	} = props;

	//const reg = /^(-?\d+(\.\d{0,2})?|\.?\d{1,2})$/;
	const reg = /^\-?\d*\.?\d*$/;
	const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const val: string = event.target.value ? event.target.value : "";

		if (onChange) {
			if (val) {
				if (reg.test(val)) {
					onChange(parentId, fieldId, formId, fieldAttribute, fieldType, val, valueId);
				}
			} else {
				onChange(parentId, fieldId, formId, fieldAttribute, fieldType, val, valueId);
			}
		}
	};
	return (
		// <div className="col-sm-8">
		<div className="form-group">
			{label && <label>{label}</label>}
			<input
				type="text"
				className="form-control"
				disabled={disabled}
				value={value}
				onChange={onValueChange}
			/>
		</div>
		// </div>
	);
	// return (
	// 	<TextField
	// 		disabled={disabled}
	// 		label={label}
	// 		value={value}
	// 		errorMessage={errorMessage}
	// 		onChange={onValueChange}
	// 	/>
	// );
};
