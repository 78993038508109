import React from "react";

import { Stack } from "@fluentui/react";
import { IAnswerOptionProps } from ".";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { getObjectValueStyles } from "./Draggable.style";
import { LikertControlContainer } from "shared/components/LikertControl/LikertControlContainer";
import { PF23Control } from "shared/components";
import { FieldType, IFormFieldValue } from "admin/questionare";
import { AnswerField } from "client/containers";

export const AnswerOption: React.FC<IAnswerOptionProps> = (props) => {
	const {
		parentId,
		fieldId,
		fieldType,
		formId,
		formFieldValues,
		fieldConfiguration,
		onChange,
		removeOption,
		addOption,
	} = props;
	const classNames = getObjectValueStyles({});
	const rootStyle = classNames && classNames.root;
	const valueGroup = classNames && classNames.valueGroup;
	const valueGroupChild = classNames && classNames.valueGroupChild;
	const valueGroupChildContent = classNames && classNames.valueGroupChildContent;
	const handleStyle = classNames && classNames.handle;
	const handleInnerStyle = classNames && classNames.handleInner;

	const { max } = fieldConfiguration;

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}
		if (onChange) {
			onChange(
				parentId,
				fieldId,
				formId,
				"answer",
				FieldType.Sortable,
				result.source.index.toString() + "#" + result.destination.index.toString(),
				undefined
			);
		}
	};
	const getOptions = (): JSX.Element => {
		switch (fieldType) {
			case FieldType.PF23:
				return (
					<PF23Control
						fieldId={fieldId}
						parentId={parentId}
						formFieldValues={formFieldValues}
						onChange={onChange}
					/>
				);
			case FieldType.Likert:
				return (
					<LikertControlContainer
						fieldId={fieldId}
						parentId={parentId}
						formFieldValues={formFieldValues}
						fieldConfiguration={fieldConfiguration}
						onChange={onChange}
						removeOption={removeOption}
						addOption={addOption}
					/>
				);
			case FieldType.Sortable:
				return (
					<>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="list">
								{(droppableProvided) => (
									<div
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										className={valueGroup}
									>
										{formFieldValues &&
											formFieldValues.map(
												(
													formFieldValue: IFormFieldValue,
													index: number
												) => {
													return (
														<Draggable
															draggableId={`${formFieldValue.valueId}`}
															index={index}
															key={`${formFieldValue.valueId}`}
															isDragDisabled={false}
														>
															{(draggableProvided) => (
																<div
																	{...draggableProvided.draggableProps}
																	{...draggableProvided.dragHandleProps}
																	ref={draggableProvided.innerRef}
																	className={valueGroupChild}
																>
																	{max &&
																		index ===
																			parseInt(max, 10) && (
																			<hr className="custom-line"></hr>
																		)}
																	<Stack
																		horizontal
																		className={rootStyle}
																	>
																		<Stack.Item
																			key={`DnD_${index}`}
																			className={handleStyle}
																			disableShrink={true}
																		>
																			<div
																				{...draggableProvided.dragHandleProps}
																				className={
																					handleInnerStyle
																				}
																			/>
																		</Stack.Item>
																		<Stack.Item
																			key={`Item_${index}`}
																			grow={true}
																			className={
																				valueGroupChildContent
																			}
																		>
																			<AnswerField
																				parentId={parentId}
																				fieldId={fieldId}
																				fieldType={
																					fieldType
																				}
																				formId={formId}
																				fieldAttribute={
																					"answer"
																				}
																				disabled={false}
																				label={
																					formFieldValue.title
																				}
																				formFieldValue={
																					formFieldValue
																				}
																				errorMessage={
																					formFieldValue.errorMessage
																				}
																				onChange={onChange}
																			/>
																		</Stack.Item>
																	</Stack>
																</div>
															)}
														</Draggable>
													);
												}
											)}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</>
				);
			default:
				return (
					<>
						{formFieldValues.map((formFieldValue: IFormFieldValue, index: number) => {
							return (
								<>
									<AnswerField
										key={`${fieldId}_${formFieldValue.fieldId}_AnswerField_${index}`}
										parentId={parentId}
										fieldId={fieldId}
										fieldType={fieldType}
										formId={formId}
										fieldAttribute={"answer"}
										disabled={false}
										label={formFieldValue.title}
										formFieldValue={formFieldValue}
										errorMessage={formFieldValue.errorMessage}
										onChange={onChange}
									/>
								</>
							);
						})}
					</>
				);
		}
	};

	return <>{getOptions()}</>;
};
