import React from "react";
import { IQuestionControlProps } from "shared/containers";
import parse from "html-react-parser";

export const HtmlControl: React.FC<IQuestionControlProps> = (props) => {
	const regex = /^(<([^>]+)>)/gi;
	const { value } = props;
	let fixedHtml = value?.replace(regex, "").toString();
	return <>{parse(`${fixedHtml}`)}</>;
	// return <div dangerouslySetInnerHTML={{ __html: value?.replace(regex, "") ? value : "" }} />;
};
