import { FormField } from "admin/questionare";
import React from "react";
import { IAnswersProps, Answer } from ".";

export const Answers: React.FC<IAnswersProps> = (props) => {
	const { errors, questions, onChange, removeOption, addOption } = props;
	return (
		<>
			{questions.map((question: FormField, index: number) => {
				return (
					<Answer
						errors={errors}
						key={`${question.formId}_${question.fieldId}_Answer_${index}`}
						question={question}
						onChange={onChange}
						removeOption={removeOption}
						addOption={addOption}
					/>
				);
			})}
		</>
	);
};
