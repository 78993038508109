import { IClientUser, IUser, IUserQuery } from "admin/user";
import { baseUrl } from "api/config";
import { LogOut, UserAuthDispatcher } from "authentication/auth.action";
import Axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { useError } from "shared/hooks";
import { useImmer } from "use-immer";
import { IEditProfileProps, IEditProfileState, INewPassword } from ".";
import { useStyles } from "./EditProfile.styles";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { ICountry } from "admin/client";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { Input } from "client/components/Controls/Input";

export const EditProfile: React.FC<IEditProfileProps> = (props) => {
	const queryString = require("query-string");
	const location = useLocation();
	const userQuery = queryString.parse(location.search) as IUserQuery;
	const classes = useStyles();
	const { errorstate, onErrorClose, onError } = useError();
	const userAuthDispatcher = UserAuthDispatcher();
	const [state, setState] = useImmer<IEditProfileState>({
		confirmPassword: "",
		newPassword: "",
		oldPassword: "",
		confirmPasswordError: "",
		newPasswordError: "",
		oldPasswordError: "",
		changePasswordIsDirty: false,
		hideDialog: true,
		user: {
			client: "",
			roleId: 0,
			clientId: 0,
			emailAddress: "",
			firstName: "",
			lastName: "",
			userStatus: 0,
			designation: "",
			addressLine1: "",
			addressLine2: "",
			businessName: "",
			city: "",
			country: "",
			mobile: "",
			officePhone: "",
			street: "",
			zip: "",
			currency: "",
		},
		emailAddressError: "",
		firstNameError: "",
		lastNameError: "",
		isDirty: false,
		isBusy: true,
		isPasswordMatchedError: false,
	});
	const [countryList, setCountryList] = useImmer<ICountry[]>([]);
	const { user } = state;
	const {
		firstName,
		lastName,
		emailAddress,
		businessName,
		addressLine1,
		addressLine2,
		mobile,
		officePhone,
		street,
		zip,
		city,
		currency,
		country,
	} = user;

	useEffect(() => {
		Axios.get(baseUrl + "common/country").then((res) => {
			setCountryList((draft) => {
				return res.data;
			});
		});
	}, [setCountryList]);

	useEffect(() => {
		if (userQuery && userQuery.userId) {
			Axios.get(baseUrl + "Client/" + userQuery.userId)
				.then((res) => {
					const user: IClientUser = res.data;
					console.log("user", user);
					setState((draft) => {
						draft.user = user;
						draft.isBusy = false;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userQuery.userId]);

	if (state.isBusy) {
		return <Loading />;
	}

	const onFirstNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.firstName = newValue;
			draft.isDirty = true;
		});
	};

	const onLastNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.lastName = newValue;
			draft.isDirty = true;
		});
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.emailAddress = newValue;
			draft.isDirty = true;
		});
	};

	const onAddressLine1Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.addressLine1 = newValue;
			draft.isDirty = true;
		});
	};

	const onAddressLine2Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.addressLine2 = newValue;
			draft.isDirty = true;
		});
	};

	const onBusinessNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.businessName = newValue;
			draft.isDirty = true;
		});
	};

	const onCityChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.city = newValue;
			draft.isDirty = true;
		});
	};

	const onStreetChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.street = newValue;
			draft.isDirty = true;
		});
	};
	const onMobileChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.mobile = newValue;
			draft.isDirty = true;
		});
	};

	const onOfficePhoneChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.officePhone = newValue;
			draft.isDirty = true;
		});
	};

	const onZipChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.user.zip = newValue;
			draft.isDirty = true;
		});
	};

	const onCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setState((draft) => {
			draft.user.currency = event.target.value;
			draft.isDirty = true;
		});
	};

	const onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setState((draft) => {
			draft.user.country = event.target.value;
			draft.isDirty = true;
		});
	};

	// const onComboChange = (
	// 	event: React.FormEvent<HTMLDivElement>,
	// 	option?: IDropdownOption,
	// 	index?: number
	// ) => {
	// 	onChange && onChange("currency", option?.key.toString());
	// };

	// const onCountryChange = (
	// 	event: React.FormEvent<HTMLDivElement>,
	// 	option?: IDropdownOption,
	// 	index?: number
	// ) => {
	// 	onChange && onChange("CountryId", option?.key.toString());
	// };

	const onOldPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.oldPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const onNewPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.newPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const onConfirmPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		setState((draft) => {
			draft.confirmPassword = newValue;
			draft.changePasswordIsDirty = true;
		});
	};

	const openDialog = () => {
		setState((draft) => {
			draft.hideDialog = false;
		});
	};

	const validateUpdate = (): boolean => {
		setState((draft) => {
			draft.firstNameError = draft.user.firstName ? "" : "*Required";
			draft.lastNameError = draft.user.lastName ? "" : "*Required";
			draft.emailAddressError = draft.user.emailAddress ? "" : "*Required";
		});
		if (state.firstNameError && state.lastNameError && state.emailAddressError) {
			return false;
		} else {
			return true;
		}
	};

	const onSave = () => {
		if (validateUpdate()) {
			setState((draft) => {
				draft.isBusy = true;
			});
			Axios.put(baseUrl + "Client/" + state.user.clientId, state.user)
				.then((res) => {
					setState((draft) => {
						draft.isBusy = false;
					});
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
	};

	const validateNewMessage = (): boolean => {
		let status: boolean = false;

		setState((draft) => {
			draft.newPasswordError = draft.newPassword ? "" : "*Required";
			draft.confirmPasswordError = draft.confirmPassword ? "" : "*Required";
			draft.oldPasswordError = draft.oldPassword ? "" : "*Required";
			draft.isPasswordMatchedError = false;
		});
		if (state.newPassword && state.confirmPassword && state.oldPassword) {
			status = true;
			if (state.newPassword !== state.confirmPassword) {
				setState((draft) => {
					draft.isPasswordMatchedError = true;
				});
				return false;
			}
		}
		return status;
	};

	const onSaveNewPassWord = () => {
		if (validateNewMessage()) {
			const newPassword: INewPassword = {
				userId: state.user.clientId,
				currentPassword: state.oldPassword,
				newPassword: state.newPassword,
			};
			setState((draft) => {
				draft.isBusy = true;
			});
			Axios.put(baseUrl + "User/ChangePassword", newPassword)
				.then((res) => {
					LogOut(userAuthDispatcher);
				})
				.catch((err) => {
					onError(err.response.data);
				});
		}
	};

	const closeAndReset = () => {
		setState((draft) => {
			draft.hideDialog = true;
			draft.confirmPassword = "";
			draft.newPassword = "";
			draft.oldPassword = "";
		});
	};

	const onCancel = () => {
		closeAndReset();
	};

	const onDismiss = (ev?: React.MouseEvent<HTMLButtonElement>) => {
		closeAndReset();
	};

	return (
		<div className="container-wrapper">
			<div className="container">
				<div className="row">
					<AvForm className="col-sm-12" onValidSubmit={onSave}>
						<ErrorDialog
							errorMessage={errorstate.errorMessage}
							show={errorstate.visible}
							onClose={onErrorClose}
						/>
						<div className="intro-head">Edit Profile</div>
						<div className="item-block m-b-20">
							<div className="item-padd questionnaire-block">
								<div className="row">
									<div className="col-sm-8">
										<div className="form-wrap">
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="First Name"
														name="FirstName"
														value={firstName}
														onChange={onFirstNameChange}
														type="text"
														required
													/>
												</div>
												<div className="col-sm-6">
													<AvField
														label="Last Name"
														name="LastName"
														value={lastName}
														onChange={onLastNameChange}
														type="text"
														required
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="Email"
														name="Email"
														value={emailAddress}
														type="email"
														onChange={onEmailChange}
														required
													/>
												</div>
												<div className="col-sm-6">
													<div className="form-group">
														<label>Currency</label>
														<select
															className="form-control"
															name="cars"
															id="cars"
															value={currency}
															onChange={(e) => onCurrencyChange(e)}
														>
															<option
																style={{ display: "none" }}
															></option>
															<option value="$">AUD</option>
															<option value="$">USD</option>
															<option value="€">EURO</option>
															<option value="£">POUND</option>
														</select>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="Business Name"
														name="businessName"
														value={businessName}
														type="Text"
														onChange={onBusinessNameChange}
														required
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="Address Line 1"
														name="addressLine1"
														value={addressLine1}
														type="Text"
														onChange={onAddressLine1Change}
														required
													/>
												</div>
												<div className="col-sm-6">
													<AvField
														label="Address Line 2"
														name="addressLine2"
														value={addressLine2}
														type="Text"
														onChange={onAddressLine2Change}
														required
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="City"
														name="city"
														value={city}
														type="Text"
														onChange={onCityChange}
														required
													/>
												</div>
												<div className="col-sm-6">
													<AvField
														label="State"
														name="Street"
														value={street}
														type="Text"
														onChange={onStreetChange}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="Post Code"
														name="zip"
														value={zip}
														type="Text"
														onChange={onZipChange}
														required
													/>
												</div>
												<div className="col-sm-6">
													<div className="form-group">
														<label>Country</label>
														<select
															className="form-control"
															name="country"
															id="country"
															value={country}
															onChange={(e) => onCountryChange(e)}
														>
															<option
																style={{ display: "none" }}
															></option>
															{countryList.map(
																(
																	country: ICountry,
																	index: number
																) => {
																	return (
																		<option
																			value={
																				country.countryId
																			}
																		>
																			{country.name}
																		</option>
																	);
																}
															)}
														</select>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<AvField
														label="Mobile"
														name="mobile"
														value={mobile}
														type="Number"
														onChange={onMobileChange}
														required
													/>
												</div>
												<div className="col-sm-6">
													<AvField
														label="Office Phone"
														name="officePhone"
														value={officePhone}
														type="Number"
														onChange={onOfficePhoneChange}
														required
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-6">
													<button
														type="button"
														className="btn-secondary-c"
														onClick={openDialog}
													>
														Change Password
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="m-b-20">
							<div className="pagination">
								<button
									type="button"
									className="btn-secondary-c"
									onClick={onCancel}
								>
									CANCEL
								</button>
								<button
									className="btn-primary-c"
									disabled={!state.isDirty}
									type="submit"
								>
									SAVE
								</button>
							</div>
						</div>

						{/* <Dialog
							hidden={state.hideDialog}
							dialogContentProps={dialogContentProps}
							modalProps={modalProps}
							onDismiss={onDismiss}
						>
							<TextField
								label="Old Password"
								value={state.oldPassword}
								errorMessage={state.oldPasswordError}
								onChange={onOldPasswordChange}
							/>
							<TextField
								label="New Password"
								value={state.newPassword}
								errorMessage={state.newPasswordError}
								onChange={onNewPasswordChange}
							/>
							<TextField
								label="Confirm Password"
								value={state.confirmPassword}
								errorMessage={state.confirmPasswordError}
								onChange={onConfirmPasswordChange}
							/>
							{state.isPasswordMatchedError && (
								<Text style={{ color: "red" }}>
									*No match in new and confirm password{" "}
								</Text>
							)}
							<DialogFooter>
								<PrimaryButton
									disabled={!state.changePasswordIsDirty}
									onClick={onSaveNewPassWord}
									text="Save"
								/>
								<DefaultButton onClick={onCancel} text="Cancel" />
							</DialogFooter>
						</Dialog> */}
					</AvForm>
					<Modal centered isOpen={!state.hideDialog}>
						<AvForm onValidSubmit={onSaveNewPassWord}>
							<div className="modal-header">Change Password</div>
							<ModalBody className="questionnaire-block">
								<div className="form-wrap">
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"oldPassword"}
												label="Old Password"
												value={state.oldPassword}
												onChange={onOldPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"newPassword"}
												label="New Password"
												value={state.newPassword}
												onChange={onNewPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<Input
												name={"confirmPassword"}
												label="Confirm Password"
												value={state.confirmPassword}
												onChange={onConfirmPasswordChange}
												required="true"
												autoComplete="off"
											></Input>
										</div>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-secondary" onClick={closeAndReset}>
									Cancel
								</button>
								<button type="submit" className="btn btn-primary">
									Save
								</button>
							</ModalFooter>
						</AvForm>
					</Modal>
				</div>
			</div>
		</div>
	);

	// return (
	// 	<>
	// 		<Stack tokens={verticalGapStackTokens} className={classes.root}>
	// 			<Stack.Item>
	// 				<Breadcrumb
	// 					items={itemsWithHref}
	// 					maxDisplayedItems={3}
	// 					ariaLabel="Breadcrumb with items rendered as links"
	// 					overflowAriaLabel="More links"
	// 				/>
	// 			</Stack.Item>
	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack className={classes.form}>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="First Name"
	// 							value={firstName}
	// 							onChange={onFirstNameChange}
	// 							errorMessage={state.firstNameError}
	// 						/>
	// 					</Stack.Item>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="Last Name"
	// 							value={lastName}
	// 							errorMessage={state.lastNameError}
	// 							onChange={onLastNameChange}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="Email"
	// 							value={emailAddress}
	// 							errorMessage={state.emailAddressError}
	// 							onChange={onEmailChange}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<PrimaryButton text={"Change Password"} onClick={openDialog} />
	// 					</Stack.Item>
	// 				</Stack>
	// 				<Stack.Item>
	// 					<PrimaryButton disabled={!state.isDirty} text={"SAVE"} onClick={onSave} />
	// 				</Stack.Item>
	// 			</Stack>
	// 		</Stack>
	// <Dialog
	// 	hidden={state.hideDialog}
	// 	dialogContentProps={dialogContentProps}
	// 	modalProps={modalProps}
	// 	onDismiss={onDismiss}
	// >
	// 	<TextField
	// 		label="Old Password"
	// 		value={state.oldPassword}
	// 		errorMessage={state.oldPasswordError}
	// 		onChange={onOldPasswordChange}
	// 	/>
	// 	<TextField
	// 		label="New Password"
	// 		value={state.newPassword}
	// 		errorMessage={state.newPasswordError}
	// 		onChange={onNewPasswordChange}
	// 	/>
	// 	<TextField
	// 		label="Confirm Password"
	// 		value={state.confirmPassword}
	// 		errorMessage={state.confirmPasswordError}
	// 		onChange={onConfirmPasswordChange}
	// 	/>
	// 	{state.isPasswordMatchedError && (
	// 		<Text style={{ color: "red" }}>*No match in new and confirm password </Text>
	// 	)}
	// 	<DialogFooter>
	// 		<PrimaryButton
	// 			disabled={!state.changePasswordIsDirty}
	// 			onClick={onSaveNewPassWord}
	// 			text="Save"
	// 		/>
	// 		<DefaultButton onClick={onCancel} text="Cancel" />
	// 	</DialogFooter>
	// </Dialog>
	// 	</>
	// );
};
