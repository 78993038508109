import React from "react";
import {
	Stack,
	IStackTokens,
	PrimaryButton,
	DefaultButton,
	INavLinkGroup,
	INavLink,
} from "@fluentui/react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useQuestion, IQuestionDescriptionQuery, ActionMode } from ".";
import { ISection } from "..";
import { ErrorDialog, Loading } from "shared/components";
import { Navbar, QuestionsForm, QuestionsPreview } from "admin/questionare";
import { useError } from "shared/hooks";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const CreateQuestion: React.FC = () => {
	const queryString = require("query-string");
	const history = useHistory();
	const location = useLocation();
	const match = useRouteMatch();
	const { errorstate, onErrorClose, onError } = useError();
	const questionQuery = queryString.parse(location.search) as IQuestionDescriptionQuery;
	const {
		state,
		onCreate,
		onChange,
		addNew,
		onQuestionTypeChange,
		addNewOption,
		onRemoveQuestion,
		removeOption,
		addSubQuestion,
	} = useQuestion(questionQuery.form, parseInt(questionQuery.section, 10), true, onError);
	const { formFields, isDirty, isBusy } = state;

	if (isBusy) {
		return <Loading />;
	}

	const createFormFields = formFields.filter((s) => s.fieldId <= 0);
	const previewFormFields = formFields.filter((s) => s.fieldId > 0);

	const getNavLinkGroup = (): INavLinkGroup[] => {
		let navLinkGroups: INavLinkGroup[] = [{ links: [] }];
		state.sections.forEach((section: ISection, index: number) => {
			const link: INavLink = {
				links: [],
				isExpanded: false,
				key: section.name,
				name: section.name,
				url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
			};
			section.subSection &&
				section.subSection.forEach((section: ISection, index: number) => {
					const childLink: INavLink = {
						isExpanded: false,
						key: section.name,
						name: section.name,
						url: `${match.path}?form=${questionQuery.form}&section=${section.sectionId}`,
					};
					link.links?.push(childLink);
				});
			navLinkGroups[0].links.push(link);
		});
		return navLinkGroups;
	};

	const onClose = () => {
		const urlLink = `/`;
		history.push(urlLink);
	};

	return (
		<Stack horizontal>
			<Stack>
				<Navbar navLinkGroups={getNavLinkGroup()} />
			</Stack>
			<Stack grow={11} tokens={verticalGapStackTokens}>
				<Stack.Item align={"end"} tokens={horizontalGapStackTokens}>
					<PrimaryButton text="Add" onClick={addNew} />
					<PrimaryButton disabled={!isDirty} text="Save" onClick={onCreate} />
					<DefaultButton text="Close" onClick={onClose} />
				</Stack.Item>
				<Stack>
					<ErrorDialog
						errorMessage={errorstate.errorMessage}
						show={errorstate.visible}
						onClose={onErrorClose}
					/>
				</Stack>
				<Stack.Item>
					{createFormFields && (
						<QuestionsForm
							parentId={undefined}
							isParent={true}
							action={ActionMode.Create}
							formFields={createFormFields}
							addNewOption={addNewOption}
							onChange={onChange}
							onQuestionTypeChange={onQuestionTypeChange}
							onRemoveQuestion={onRemoveQuestion}
							removeOption={removeOption}
							addSubQuestion={addSubQuestion}
						/>
					)}
				</Stack.Item>
				<Stack.Item>
					{previewFormFields && <QuestionsPreview formFields={previewFormFields} />}
				</Stack.Item>
			</Stack>
		</Stack>
	);
};
