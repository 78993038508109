import React from "react";
import { INavbarProps, navStyles } from ".";
import { Nav } from "@fluentui/react";

export const Navbar: React.FC<INavbarProps> = (props) => {
	const { navLinkGroups } = props;
	return (
		<Nav
			selectedKey="key3"
			ariaLabel="Nav basic example"
			styles={navStyles}
			groups={navLinkGroups}
		/>
	);
};
