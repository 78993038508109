import React from "react";
import { IQuestionTypeSelectorProps } from ".";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { ActionMode, FieldType } from "admin/questionare";

export const QuestionTypeSelector: React.FC<IQuestionTypeSelectorProps> = (props) => {
	const { parentId, fieldType, fieldId, action, onQuestionTypeChange } = props;
	const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 200 } };
	const options: IDropdownOption[] = [
		{ key: FieldType.Text, text: "Text" },
		{ key: FieldType.MultilineText, text: "MultilineText" },
		{ key: FieldType.Number, text: "Number" },
		{ key: FieldType.Date, text: "Date" },
		{ key: FieldType.TextArea, text: "TextArea" },
		{ key: FieldType.Checkbox, text: "Checkbox" },
		{ key: FieldType.MultiSelectCheckbox, text: "MultiSelectCheckbox" },
		{ key: FieldType.Currency, text: "Currency" },
		{ key: FieldType.PlainText, text: "PlainText" },
		{ key: FieldType.Sortable, text: "Sortable" },
		{ key: FieldType.SectionHeading, text: "SectionHeading" },
		{ key: FieldType.MultilineTextArea, text: "MultilineTextArea" },
		{ key: FieldType.Percentage, text: "Percentage" },
		{ key: FieldType.NoAnswer, text: "NoAnswer" },
		{ key: FieldType.TextAndCurrency, text: "TextAndCurrency" },
		{ key: FieldType.Likert, text: "Likert" },
		{ key: FieldType.PF23, text: "PF23" },
	];

	const onChange = (
		event: React.FormEvent<HTMLDivElement>,
		option?: IDropdownOption,
		index?: number
	) => {
		if (option && option.key && onQuestionTypeChange)
			onQuestionTypeChange(parentId, parseInt(option.key.toString(), 10), fieldId);
	};
	const disabled = action === ActionMode.Edit;
	return (
		<Dropdown
			label="Question Type"
			disabled={disabled}
			selectedKey={fieldType}
			onChange={onChange}
			placeholder="Select an option"
			options={options}
			styles={dropdownStyles}
		/>
	);
};
