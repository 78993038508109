import React, { useState } from "react";
import { IQuestionsFormProps } from "./QuestionsForm.types";
import { QuestionForm } from ".";
import { IStackTokens, Stack } from "@fluentui/react";
import { Loading } from "shared/components";
import { FormField } from "admin/questionare";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { getQuestionFormDragStyles } from "./QuestionsFormDraggable.style";
import { ActionMode, FieldType } from "admin/questionare/containers";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const QuestionsForm: React.FC<IQuestionsFormProps> = (props) => {
	const {
		action,
		formFields,
		onChange,
		onQuestionTypeChange,
		addNewOption,
		onRemoveQuestion,
		removeOption,
		addSubQuestion,
		isBusy,
		isParent,
		parentId,
		onDragQuestion,
	} = props;

	const classNames = getQuestionFormDragStyles({});
	const rootStyle = classNames && classNames.root;
	const valueGroup = classNames && classNames.valueGroup;
	const valueGroupChild = classNames && classNames.valueGroupChild;
	const valueGroupChildContent = classNames && classNames.valueGroupChildContent;
	const handleStyle = classNames && classNames.handle;
	const handleInnerStyle = classNames && classNames.handleInner;
	const [toggle, setToggle] = useState(0);
	const regex = /<[^>]*>/gi;
	const headingregex = /<h[1-6][^>]*?>(?<TagText>.*?)<\/h[1-6]>/gi;
	if (isBusy) {
		return <Loading />;
	}

	const handleToggle = (FieldId: number) => {
		setToggle(toggle === FieldId ? 0 : FieldId);
	};

	const onDragQuestionEnd = (result: DropResult) => {
		if (!result.destination) {
			console.log("!result.destination");

			return;
		}
		if (result.destination.index === result.source.index) {
			console.log("result.destination.index === result.source.index");
			return;
		}
		if (onDragQuestion) {
			onDragQuestion(result, parentId);
		}
	};
	const getTitle = (title: string) => {
		const headings = headingregex.exec(title);
		if (headings && headings?.length > 0) {
			return headings[0].replace(regex, "").substring(0, 50);
		}
		return "";
	};
	if (action === ActionMode.Edit) {
		return (
			<div className="card">
				<DragDropContext onDragEnd={onDragQuestionEnd}>
					<Droppable droppableId="list">
						{(droppableProvided) => (
							<div
								ref={droppableProvided.innerRef}
								{...droppableProvided.droppableProps}
								className={valueGroup}
							>
								{formFields &&
									formFields.map((formField: FormField, index: number) => {
										return (
											<Draggable
												draggableId={`${formField.fieldId}`}
												index={index}
												key={`${formField.fieldId}`}
												isDragDisabled={false}
											>
												{(draggableProvided) => (
													<div
														{...draggableProvided.draggableProps}
														{...draggableProvided.dragHandleProps}
														ref={draggableProvided.innerRef}
													>
														<div
															className={
																"accordion-baq " +
																(toggle === formField.fieldId
																	? "active"
																	: "")
															}
															onClick={() =>
																handleToggle(formField.fieldId)
															}
														>
															Question # :{" "}
															{`${
																formField.questionNo
															}                 (${
																FieldType[formField.fieldType]
															}) ${getTitle(formField.title ?? "")}`}
														</div>
														<div
															className="panel"
															style={{
																display:
																	toggle === formField.fieldId
																		? "block"
																		: "none",
															}}
														>
															<div className="pb-3">
																<div key={`DnD_${index}`}>
																	<div
																		{...draggableProvided.dragHandleProps}
																		className={handleInnerStyle}
																	/>
																</div>
																<div key={`Item_${index}`}>
																	<QuestionForm
																		action={action}
																		key={`${index}_QuestionForm_${formField.fieldId}`}
																		formField={formField}
																		onChange={onChange}
																		onQuestionTypeChange={
																			onQuestionTypeChange
																		}
																		addNewOption={addNewOption}
																		onRemoveQuestion={
																			onRemoveQuestion
																		}
																		removeOption={removeOption}
																		addSubQuestion={
																			addSubQuestion
																		}
																		onDragQuestion={
																			onDragQuestion
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
								{droppableProvided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	} else {
		return (
			<Stack.Item tokens={verticalGapStackTokens}>
				{formFields.map((formField: FormField, index: number) => {
					return (
						<Stack.Item
							key={`Stack.Item ${index}_${formField.fieldId}`}
							tokens={verticalGapStackTokens}
						>
							<QuestionForm
								action={action}
								key={`${index}_QuestionForm_${formField.fieldId}`}
								formField={formField}
								onChange={onChange}
								onQuestionTypeChange={onQuestionTypeChange}
								addNewOption={addNewOption}
								onRemoveQuestion={onRemoveQuestion}
								removeOption={removeOption}
								addSubQuestion={addSubQuestion}
							/>
						</Stack.Item>
					);
				})}
			</Stack.Item>
		);
	}
};
