import {
	ChoiceGroup,
	IBreadcrumbItem,
	IChoiceGroupOption,
	IDropdownOption,
	IStackTokens,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BreadcrumbItem, Breadcrumb } from "reactstrap";
import { ErrorDialog, Loading } from "shared/components";
import { useError } from "shared/hooks";
import { IBipUserQuery, ICreateBipUserProps } from ".";
import { useStyles } from "./CreateBipUser.styles";
import { useBipUser } from "./useBipUser";
import { AvForm } from "availity-reactstrap-validation";
import { Card } from "reactstrap";
import { Input } from "client/components/Controls/Input";
import { useImmer } from "use-immer";
import Axios from "axios";
import { baseUrl } from "api/config";
import { ICountry } from "admin";
import { AuthStateContext, UserType } from "authentication";
const verticalGapStackTokens: IStackTokens = {
	childrenGap: 15,
	padding: 10,
};

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 5,
};

export const CreateBipUser: React.FC<ICreateBipUserProps> = (props) => {
	const { onBipUserNavigateBack } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const classes = useStyles();
	const bipUserQuery = queryString.parse(location.search) as IBipUserQuery;
	if (props.userId) bipUserQuery.bipId = props.userId;
	const { mode } = props;
	const { errorstate, onErrorClose, onError } = useError();
	const { state, onCreate, onSave, onChange } = useBipUser(mode, bipUserQuery, onError);
	const { selectedUsers } = state;
	const [countryList, setCountryList] = useImmer<ICountry[]>([]);
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const [localState, setlocalState] = useImmer({ isBusy: false, message: "" });
	const {
		emailAddress,
		firstName,
		lastName,
		companyName,
		userStatus,
		addressLine1,
		addressLine2,
		businessId,
		city,
		countryId,
		mobile,
		officePhone,
		street,
		zip,
	} = selectedUsers;

	useEffect(() => {
		Axios.get(baseUrl + "common/country").then((res) => {
			setCountryList((draft) => {
				return res.data;
			});
		});
	}, [setCountryList]);
	
	if (state.isBusy) {
		return <Loading />;
	}
	const options: IChoiceGroupOption[] = [
		{ key: "A", text: "Active" },
		{ key: "B", text: "Inactive" },
	];

	const itemsWithHref: IBreadcrumbItem[] = [
		{ text: "user", key: "f3", onClick: onBipUserNavigateBack },
	];

	if (mode === "CREATE") itemsWithHref.push({ text: "Create BIP User", key: "f4" });
	if (mode === "EDIT") itemsWithHref.push({ text: "Edit BIP User", key: "f4" });

	const onFirstNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("FirstName", newValue);
	};

	const onLastNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("LastName", newValue);
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Email", newValue);
	};
	const onBusinessNameChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("CompanyName", newValue);
	};

	const onAddressLine1Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("AddressLine1", newValue);
	};

	const onAddressLine2Change = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("AddressLine2", newValue);
	};
	const onBusinessIdChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("BusinessId", newValue);
	};
	const onCityChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("City", newValue);
	};
	const onStreetChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Street", newValue);
	};

	const onMobileChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Mobile", newValue);
	};

	const onOfficePhoneChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("OfficePhone", newValue);
	};

	const onZipChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string
	) => {
		onChange && onChange("Zip", newValue);
	};

	const onCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange && onChange("CountryId", event.target.value);
	};

	const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange("UserStatus", event.target.checked ? "1" : "0");
	};
	const onResetPassword = () => {
		setlocalState((draft) => {
			draft.isBusy = true;
		});
		Axios.post(baseUrl + `user/reset-password/${bipUserQuery.bipId}`)
			.then((res) => {
				if (res.status === 200) {
					setlocalState((draft) => {
						draft.isBusy = false;
						draft.message = res.data;
					});
				}
			})
			.catch((err) => {
				setlocalState((draft) => {
					draft.isBusy = false;
				});
			});
	};
	return (
		<>
			<ErrorDialog
				errorMessage={errorstate.errorMessage}
				show={errorstate.visible}
				onClose={onErrorClose}
			/>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Dashboard
				</BreadcrumbItem>
				{props.userId ? (
					<BreadcrumbItem active>Edit Profile</BreadcrumbItem>
				) : (
					<>
						<BreadcrumbItem tag={Link} to="/admin/bipuser">
							BIP User
						</BreadcrumbItem>
						<BreadcrumbItem active>
							{mode === "CREATE" ? "New BIP User" : "Edit BIP User"}
						</BreadcrumbItem>
					</>
				)}
			</Breadcrumb>
			<AvForm onValidSubmit={mode === "CREATE" ? onCreate : onSave}>
				<div className="col-md-8">
					<Card>
						<div className="card-body">
							<div className="form-wrap">
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="First Name"
											name="FirstName"
											value={firstName}
											onChange={onFirstNameChange}
											type="text"
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Last Name"
											name="LastName"
											value={lastName}
											onChange={onLastNameChange}
											type="text"
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Email"
											name="Email"
											helpMessage={
												props.userId
													? "Password will be send to this email."
													: ""
											}
											value={emailAddress}
											type="email"
											onChange={onEmailChange}
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Business Id"
											name="businessId"
											value={businessId}
											type="Text"
											onChange={onBusinessIdChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Business Name"
											name="businessName"
											value={companyName}
											type="Text"
											onChange={onBusinessNameChange}
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Address Line 1"
											name="addressLine1"
											value={addressLine1}
											type="Text"
											onChange={onAddressLine1Change}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Address Line 2"
											name="addressLine2"
											value={addressLine2}
											type="Text"
											onChange={onAddressLine2Change}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="City"
											name="city"
											value={city}
											type="Text"
											onChange={onCityChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="State"
											name="Street"
											value={street}
											type="Text"
											onChange={onStreetChange}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Post Code"
											name="zip"
											value={zip}
											type="Text"
											onChange={onZipChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label>Country</label>
											<Input
												name="country"
												id="country"
												type="select"
												value={countryId}
												onChange={onCountryChange}
											>
												<option style={{ display: "none" }}></option>
												{countryList.map(
													(country: ICountry, index: number) => {
														return (
															<option value={country.countryId}>
																{country.name}
															</option>
														);
													}
												)}
											</Input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Input
											label="Mobile"
											name="mobile"
											value={mobile}
											type="Number"
											onChange={onMobileChange}
											required
										/>
									</div>
									<div className="col-sm-6">
										<Input
											label="Office Phone"
											name="officePhone"
											value={officePhone}
											type="Number"
											onChange={onOfficePhoneChange}
										/>
									</div>
								</div>
								{!props.userId && (
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<div className="pt-checkbox">
													<input
														id={`status`}
														type="Checkbox"
														checked={userStatus === 0 ? false : true}
														onChange={onChangeStatus}
													/>
													<label
														className="pt-checkbox"
														htmlFor={`status`}
													>
														Is Active
													</label>
												</div>
											</div>
										</div>
									</div>
								)}

								<button className="btn btn-primary" type="submit">
									Save
								</button>
								{mode === "EDIT" && (
									<button
										type="button"
										className="btn btn-secondary float-right"
										disabled={localState.isBusy}
										onClick={onResetPassword}
									>
										Reset Password
									</button>
								)}
							</div>
						</div>
					</Card>
				</div>
			</AvForm>
		</>
	);

	// return (
	// 	<>
	// 		<Breadcrumb tag="nav" listTag="div">
	// 			<BreadcrumbItem tag={Link} to="/admin/home">
	// 				Dashboard
	// 			</BreadcrumbItem>
	// 			<BreadcrumbItem tag={Link} to="/admin/bipuser">
	// 				BIP User
	// 			</BreadcrumbItem>
	// 			<BreadcrumbItem active>
	// 				{mode === "CREATE" ? "New User" : "Edit User"}
	// 			</BreadcrumbItem>
	// 		</Breadcrumb>
	// 		<Stack tokens={verticalGapStackTokens} className={classes.root}>
	// 			<Stack.Item>
	// 				<Breadcrumb
	// 					items={itemsWithHref}
	// 					maxDisplayedItems={3}
	// 					ariaLabel="Breadcrumb with items rendered as links"
	// 					overflowAriaLabel="More links"
	// 				/>
	// 			</Stack.Item>
	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack className={classes.form}>
	// 				<Stack grow={12} horizontal tokens={horizontalGapStackTokens}>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="First Name"
	// 							value={firstName}
	// 							onChange={onFirstNameChange}
	// 						/>
	// 					</Stack.Item>
	// 					<Stack.Item grow={6}>
	// 						<TextField
	// 							label="Last Name"
	// 							value={lastName}
	// 							onChange={onLastNameChange}
	// 						/>
	// 					</Stack.Item>
	// 				</Stack>

	// 				<Stack.Item tokens={horizontalGapStackTokens}>
	// 					<TextField label="Email" value={emailAddress} onChange={onEmailChange} />
	// 				</Stack.Item>
	// 				<Stack.Item tokens={horizontalGapStackTokens}>
	// 					<ChoiceGroup
	// 						defaultSelectedKey="A"
	// 						options={options}
	// 						//onChange={_onChange}
	// 						label="Account Status"
	// 						required={true}
	// 					/>
	// 				</Stack.Item>
	// 				<Stack.Item>
	// 					{mode === "CREATE" ? (
	// 						<PrimaryButton text={"SAVE"} onClick={onCreate} />
	// 					) : (
	// 						<PrimaryButton text={"SAVE"} onClick={onSave} />
	// 					)}
	// 				</Stack.Item>
	// 			</Stack>
	// 		</Stack>
	// 	</>
	// );
};
