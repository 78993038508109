import {
	IButtonStyles,
	ICommandBarStyleProps,
	ICommandBarStyles,
	IStyleFunctionOrObject,
} from "@fluentui/react";
import { mergeStyleSets } from "office-ui-fabric-react";

// export const useStyles = () => {
// 	return mergeStyleSets({
// 		root: {
// 			minHeight: "35px",
// 			backgroundColor: "white",
// 		},
// 	});
// };

export const useStyles = (): IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> => {
	return mergeStyleSets({
		root: {
			minHeight: "35px",
			backgroundColor: "white",
			padding: "0px 0px 0px 0px",
		},
	});
};

export const useButtonStyles = (): IButtonStyles => {
	return mergeStyleSets({
		root: {
			//	backgroundColor: "blue",
		},
	});
};
