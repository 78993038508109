import React from "react";

import { FieldAttribute, FieldType, IUseQuestionHook } from ".";
import { useImmerReducer } from "use-immer";
import {
	createQuestion,
	getQuestionsForEdit,
	getSections,
	questionReducer,
	updateQuestions,
} from "admin/questionare";
import { IUseErrorHook } from "shared/hooks";
import { DropResult } from "react-beautiful-dnd";

export const useQuestion = (
	formId: number,
	sectionId: number,
	isForPreview: boolean,
	onError: IUseErrorHook["onError"]
): IUseQuestionHook => {
	const [state, questionStateDispatcher] = useImmerReducer(questionReducer, {
		formFields: [],
		sections: [],
		isDirty: false,
		nextFieldId: 0,
		nextValueId: 0,
		isBusy: true,
		canSubmit: false,
		sortList: [],
	});

	React.useEffect(() => {
		if (formId) {
			getSections(questionStateDispatcher, { formId }, onError);
		}
	}, [formId, onError, questionStateDispatcher]);

	React.useEffect(() => {
		if (formId && sectionId && !isForPreview) {
			getQuestionsForEdit(
				questionStateDispatcher,
				{ loadAnswers: false, formId, sectionId },
				onError
			);
		}
	}, [formId, isForPreview, onError, questionStateDispatcher, sectionId, state.isDirty]);

	React.useEffect(() => {
		if (formId && isForPreview && !state.isDirty) {
			getQuestionsForEdit(
				questionStateDispatcher,
				{ loadAnswers: false, formId, sectionId },
				onError
			);
		}
		if (!state.isDirty) {
			if (formId && sectionId && !isForPreview) {
				getQuestionsForEdit(
					questionStateDispatcher,
					{
						loadAnswers: false,
						formId,
						sectionId,
					},
					onError
				);
			}
		}
	}, [formId, isForPreview, onError, questionStateDispatcher, sectionId, state.isDirty]);

	React.useEffect(() => {
		if (state.canSubmit) {
			const questions = state.formFields.filter((s) => s.fieldId <= 0);
			if (questions) {
				createQuestion(questionStateDispatcher, questions, onError);
			}
		}
	}, [onError, questionStateDispatcher, state.canSubmit, state.formFields]);

	const onChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		questionStateDispatcher({
			type: "ON_VALUE_CHANGE",
			parentId,
			fieldId,
			formId,
			fieldAttribute,
			fieldType,
			value,
			valueId,
		});
	};

	const onCreate = () => {
		questionStateDispatcher({
			type: "ON_CREATE",
		});
	};

	const onUpdate = () => {
		updateQuestions(questionStateDispatcher, state.formFields, onError);
	};

	const addNew = () => {
		questionStateDispatcher({
			type: "ADD_NEW_FORMFIELD",
			formId,
			sectionId,
			fieldId: state.nextFieldId,
		});
	};

	const onQuestionTypeChange = (
		parentId: number | undefined,
		fieldType: FieldType,
		fieldId: number
	) => {
		questionStateDispatcher({
			type: "ON_FIELD_TYPE_CHANGE",
			parentId,
			fieldId,
			fieldType,
		});
	};
	const addNewOption = (parentId: number | undefined, fieldId: number) => {
		questionStateDispatcher({
			type: "ADD_NEW_OPTION",
			parentId,
			fieldId,
			valueId: state.nextValueId,
		});
	};

	const onRemoveQuestion = (parentId: number | undefined, fieldId: number) => {
		questionStateDispatcher({
			type: "ON_REMOVE_QUESTION",
			parentId,
			fieldId,
		});
	};

	const removeOption = (parentId: number | undefined, fieldId: number, valueId: number) => {
		questionStateDispatcher({
			type: "ON_REMOVE_OPTION",
			parentId,
			fieldId,
			valueId,
		});
	};

	const addSubQuestion = (fieldId: number) => {
		questionStateDispatcher({
			type: "ADD_SUB_QUESTION",
			fieldId,
		});
	};

	const onDragQuestion = (dropResult: DropResult, parentId: number | undefined) => {
		questionStateDispatcher({
			type: "ON_DRAG_QUESTION",
			dropResult,
			parentId,
		});
	};

	return {
		state,
		onChange,
		onCreate,
		onUpdate,
		addNew,
		onQuestionTypeChange,
		addNewOption,
		onRemoveQuestion,
		removeOption,
		addSubQuestion,
		onDragQuestion,
	};
};
