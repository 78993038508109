import React from "react";
import { IQuestionControlProps, QuestionControl } from "shared/containers";
import { Stack, IStackTokens } from "@fluentui/react";
import { FieldAttribute, FieldType, IFormFieldValue } from "admin/questionare";

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const OptionValueControl: React.FC<IQuestionControlProps> = (props) => {
	const { parentId, disabled, formId, fieldId, configuration, valueId, onChange } = props;
	const option: IFormFieldValue = configuration as IFormFieldValue;
	const onOptionTitleChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		if (onChange) onChange(parentId, fieldId, formId, "optionTitle", fieldType, value, valueId);
	};

	const onOptionValueChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		if (onChange) onChange(parentId, fieldId, formId, "optionValue", fieldType, value, valueId);
	};

	return (
		<div className="col-md-12">
			<div className="row">
				<div className="col-md-4">
					<QuestionControl
						label="Value"
						parentId={parentId}
						fieldId={fieldId}
						formId={formId}
						fieldType={FieldType.Text}
						value={option.value}
						valueId={valueId}
						disabled={disabled}
						fieldAttribute={"optionValue"}
						onChange={onOptionValueChange}
					/>
				</div>
				<div className="col-md-8">
					<QuestionControl
						label="Title"
						parentId={parentId}
						fieldId={fieldId}
						formId={formId}
						fieldType={FieldType.Text}
						value={option.title}
						valueId={valueId}
						fieldAttribute={"optionTitle"}
						disabled={disabled}
						onChange={onOptionTitleChange}
					/>
				</div>
			</div>
		</div>
	);
};
