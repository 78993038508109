import React, { useContext } from "react";

import { INavLink, INavLinkGroup, Stack } from "@fluentui/react";
import ProtectedRoute from "authentication/ProtectedRoute";
import { BipUser } from "admin/bipuser/pages";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useImmer } from "use-immer";
import { IDashBoardprops } from ".";
import { useStyles } from "./DashBoard.styles";
import { AuthStateContext } from "authentication/useAuthContext";
import { UserType } from "authentication";
import { Settings } from "../../settings/pages/Settings";
import { Client } from "admin/client";
import { User } from "admin/user";
import { Header, INavigationState, Navigation } from "..";
import { Home } from "admin/home";
import { EditProfile } from "admin/home/container";
import { Questionare } from "admin/questionare";
import { SignIn } from "admin/signIn";

export const DashBoard: React.FC<IDashBoardprops> = (props) => {
	const match = useRouteMatch();
	const classes = useStyles();
	const history = useHistory();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { roleId, userType } = user;

	const navLinkGroups: INavLinkGroup[] = [
		{
			links: [
				{
					name: "Home",
					url: `${match.path}/home`,
					key: "key1",
				},
				{
					name: "Client",
					url: `${match.path}/client`,
					key: "key4",
				},
				{
					name: "BAQ",
					url: `${match.path}/questionare`,
					key: "key5",
				},
			],
		},
	];

	if (userType === UserType.Admin) {
		navLinkGroups[0].links.splice(1, 0, {
			name: "User",
			url: `${match.path}/user`,
			key: "key2",
		});
		navLinkGroups[0].links.splice(2, 0, {
			name: "BIP User",
			url: `${match.path}/bipuser`,
			key: "key3",
		});
		navLinkGroups[0].links.splice(5, 0, {
			name: "Settings",
			url: `${match.path}/settings`,
			key: "key6",
		});
	}

	const [navigationState, setNavigationState] = useImmer<INavigationState>({
		selectedKey: "key1",
		isOpen: true,
		navLinkGroups,
	});

	const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
		if (item) {
			setNavigationState((draft) => {
				draft.selectedKey = item.key;
			});
			const urlLink = `${item.url}`;
			history.replace(urlLink);
		}
		ev && ev.preventDefault();
	};

	const onNavigatioPanelChange = () => {
		setNavigationState((draft) => {
			draft.isOpen = !draft.isOpen;
		});
	};

	const onEditProfile = () => {
		history.push(`${match.path}/editprofile?userId=${user.userId}`);
	};

	const onUserNavigateBack = () => {
		history.push(`${match.path}/user`);
	};

	const onBipUserNavigateBack = () => {
		history.push(`${match.path}/bipuser`);
	};

	const onClientNavigateBack = () => {
		history.push(`${match.path}/client`);
	};

	return (
		<Switch>
			<Route path={`${match.path}/signin`}>
				<SignIn />
			</Route>
			<div>
				<Header
					onEditProfile={onEditProfile}
					onNavigatioPanelChange={onNavigatioPanelChange}
				/>
				<div className="main-wrapper">
					{userType !== UserType.Client && (
						<div className="main-sidebar">
							<Navigation state={navigationState} onLinkClick={onLinkClick} />
						</div>
					)}

					<div className="main-content">
						<ProtectedRoute
							exact={true}
							path={`${match.path}/home`}
							render={() => {
								return <Home />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/questionare`}
							render={() => {
								return <Questionare />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/bipuser`}
							render={() => {
								return <BipUser onBipUserNavigateBack={onBipUserNavigateBack} />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/client`}
							render={() => {
								return <Client onClientNavigateBack={onClientNavigateBack} />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/settings`}
							render={() => {
								return <Settings />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/user`}
							render={() => {
								return <User onUserNavigateBack={onUserNavigateBack} />;
							}}
						/>
						<ProtectedRoute
							path={`${match.path}/editprofile`}
							render={() => {
								return <EditProfile />;
							}}
						/>
					</div>
				</div>
			</div>
		</Switch>
	);
	// return (
	// 	<Switch>
	// 		<Route path={`${match.path}/signin`}>
	// 			<SignIn />
	// 		</Route>
	// 		<Stack className={classes.main}>
	// 			<Stack grow={12}>
	// 				<Header
	// 					onEditProfile={onEditProfile}
	// 					onNavigatioPanelChange={onNavigatioPanelChange}
	// 				/>
	// 			</Stack>
	// 			<Stack grow={12} horizontal>
	// 				{userType !== UserType.Client && (
	// 					<Stack>
	// 						<Navigation state={navigationState} onLinkClick={onLinkClick} />
	// 					</Stack>
	// 				)}

	// 				<Stack grow={9} className={classes.content}>
	// 					<ProtectedRoute
	// 						exact={true}
	// 						path={`${match.path}/home`}
	// 						render={() => {
	// 							return <Home />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/questionare`}
	// 						render={() => {
	// 							return <Questionare />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/bipuser`}
	// 						render={() => {
	// 							return <BipUser onBipUserNavigateBack={onBipUserNavigateBack} />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/client`}
	// 						render={() => {
	// 							return <Client onClientNavigateBack={onClientNavigateBack} />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/settings`}
	// 						render={() => {
	// 							return <Settings />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/user`}
	// 						render={() => {
	// 							return <User onUserNavigateBack={onUserNavigateBack} />;
	// 						}}
	// 					/>
	// 					<ProtectedRoute
	// 						path={`${match.path}/editprofile`}
	// 						render={() => {
	// 							return <EditProfile />;
	// 						}}
	// 					/>
	// 				</Stack>
	// 			</Stack>
	// 		</Stack>
	// 	</Switch>
	// );
};
