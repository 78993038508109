import { Reducer } from "react";
import { baseUrl } from "api/config";
import { IForm, IFormState } from "..";
import { IUseErrorHook } from "shared/hooks";

export type IFormActions =
	| {
			type: "SET_FORMS";
			forms: IForm[];
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  };

export const formReducer: Reducer<IFormState, IFormActions> = (draft, action): IFormState => {
	switch (action.type) {
		case "SET_FORMS":
			draft.forms = action.forms;
			draft.isBusy = false;
			draft.init = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");
export const getActiveForms = (
	dispatcher: React.Dispatch<IFormActions>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "Form/active")
			.then((res: any) => {
				const forms: IForm[] = res.data;
				dispatcher({ type: "SET_FORMS", forms });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};
export const getForms = (
	dispatcher: React.Dispatch<IFormActions>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "Form")
			.then((res: any) => {
				const forms: IForm[] = res.data;
				dispatcher({ type: "SET_FORMS", forms });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};
