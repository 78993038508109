import { AnswerViewType, IAnswerDescriptionQuery, IUseAnswerHook, SaveType } from ".";
import { useImmerReducer } from "use-immer";
import React from "react";
import { FieldAttribute, FieldType } from "admin/questionare";
import {
	answerReducer,
	getQuestions,
	getSectionsForAnswer,
	saveAnswer,
	saveValidateAndSubmit,
	validateAndSubmit,
} from "client/actions";
import { IUseErrorHook } from "shared/hooks";

export const useAnswer = (
	clientId: number,
	formId: number,
	sectionId: number,
	viewType: AnswerViewType,
	onError: IUseErrorHook["onError"],
	goToHome: () => void
): IUseAnswerHook => {
	const [state, answerDispatcher] = useImmerReducer(answerReducer, {
		newDataId: -1,
		questions: [],
		answers: [],
		sections: [],
		isFormDirty: false,
		canSave: false,
		isBusy: false,
		isLoadingSections: false,
		activeSection: "",
		next: { sectionId: 0, disable: true },
		previous: { sectionId: 0, disable: true },
		formSummary: {
			answered: 0,
			formId: 0,
			isEnabled: false,
			pending: 0,
			reportId: 0,
			totalQuestions: 0,
			title: "",
			isSubmited: false,
		},
		autoSaveTime: "",
		errors: { errors: [] },
		saveType: null,
		canSubmit: false,
	});

	React.useEffect(() => {
		if (formId) {
			getSectionsForAnswer(answerDispatcher, { formId, clientId }, onError);
		}
	}, [answerDispatcher, formId, clientId, sectionId, onError]);

	React.useEffect(() => {
		if (sectionId && formId) {
			getQuestions(
				answerDispatcher,
				{ sectionId, formId, clientId },
				viewType,
				true,
				onError
			);
		}
	}, [answerDispatcher, clientId, formId, onError, sectionId, viewType]);

	React.useEffect(() => {
		if (sectionId && state.sections.length > 0) {
			answerDispatcher({ type: "SET_NEXT_PREVIOUS", sectionId });
		}
	}, [answerDispatcher, sectionId, state.next, state.sections, state.sections.length]);

	React.useEffect(() => {
		if (state.canSave) {
			saveAnswer(
				answerDispatcher,
				state.answers,
				{ sectionId, formId, clientId },
				state.saveType,
				onError
			);
		}
	}, [
		answerDispatcher,
		clientId,
		formId,
		onError,
		sectionId,
		state.answers,
		state.canSave,
		state.isFormDirty,
		state.saveType,
	]);

	// To save before answer before submit questionare
	React.useEffect(() => {
		if (state.canSubmit) {
			saveValidateAndSubmit(
				answerDispatcher,
				state.answers,
				{
					section: sectionId,
					form: formId,
					client: clientId,
				},
				onError,
				goToHome
			);
		}
	}, [
		answerDispatcher,
		clientId,
		formId,
		goToHome,
		onError,
		sectionId,
		state.answers,
		state.canSubmit,
	]);

	const onChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number,
		dataId?: number
	) => {
		answerDispatcher({
			type: "ON_CHANGE",
			parentId,
			fieldId,
			formId,
			fieldAttribute,
			fieldType,
			value,
			valueId,
			dataId,
		});
	};

	const onSave = (
		clientId: number,
		isBusy: boolean,
		answerViewType: AnswerViewType,
		saveType: SaveType | null
	) => {
		if (state.isFormDirty) {
			answerDispatcher({
				type: "ON_SAVE",
				clientId,
				isBusy,
				answerViewType,
				saveType,
				canSubmit: false,
			});
		}
	};

	const removeOption = (fieldId: number, dataId: number, valueId?: number) => {
		answerDispatcher({ type: "REMOVE_OPTION", dataId, fieldId, valueId });
	};

	const addOption = (fieldId: number, valueId?: number) => {
		answerDispatcher({ type: "ADD_OPTION", valueId, fieldId });
	};

	const onSubmit = (answerQuery: IAnswerDescriptionQuery) => {
		if (!state.isFormDirty) {
			// Form is not dirty.., No save before submit
			validateAndSubmit(answerDispatcher, answerQuery, onError, goToHome);
		} else {
			// Form is not dirty.., To save before submit
			answerDispatcher({
				type: "ON_SAVE",
				clientId,
				isBusy: true,
				answerViewType: "Answer",
				saveType: "ButtonSave",
				canSubmit: true,
			});
		}
	};

	return { state, onChange, onSave, removeOption, addOption, onSubmit };
};
