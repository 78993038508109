import React from "react";
import { IQuestionControlProps } from "shared/containers";
import { Stack, Checkbox } from "@fluentui/react";

const stackTokens = { childrenGap: 10 };

export const CheckBoxControl: React.FC<IQuestionControlProps> = (props) => {
	const {
		dataId,
		parentId,
		label,
		disabled,
		value,
		fieldId,
		formId,
		fieldAttribute,
		fieldType,
		valueId,
		onChange,
	} = props;

	const checked: boolean | undefined = value === undefined || value === "" ? false : true;

	const onChangeSelect = (ev?: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Checkbox", ev?.target.name);

		if (onChange) {
			onChange(parentId, fieldId, formId, fieldAttribute, fieldType, value, valueId, dataId);
		}
	};

	return (
		<>
			<div className="mt-1">
				<div className="pt-checkbox">
					<input
						type="checkbox"
						name={label}
						onChange={(event) => onChangeSelect(event)}
						checked={checked}
						disabled={disabled}
						id={`${valueId}`}
					/>
					<label className="pt-checkbox" htmlFor={`${valueId}`}>
						{label}
					</label>
				</div>
				{/* <div className="form-check">
					<input
						className="form-check-input"
						name={label}
						onChange={(event) => onChangeSelect(event)}
						checked={checked}
						type="checkbox"
						id="defaultCheck1"
					/>
					<label className="form-check-label" htmlFor="defaultCheck1">
						{label}
					</label>
				</div> */}
			</div>
		</>
	);

	// return (
	// 	<Stack tokens={stackTokens}>
	// 		<Stack.Item>
	// 			<Checkbox
	// 				label={label}
	// 				value={value}
	// 				onChange={onChangeSelect}
	// 				checked={checked}
	// 				disabled={disabled}
	// 			/>
	// 		</Stack.Item>
	// 		{/* <Stack.Item>
	// 			<div style={{ color: "rgb(164, 38, 44)" }}>{errorMessage}</div>
	// 		</Stack.Item> */}
	// 	</Stack>
	// );
};
