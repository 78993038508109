import { mergeStyleSets } from "@fluentui/react";

export const useStyles = () => {
	return mergeStyleSets({
		content: {
			height: "calc(100vh - 44px)",
			overflowY: "auto",
		},
		main: {
			height: "100vh",
		},
	});
};
