import { mergeStyleSets } from "@fluentui/react";

export const useStyles = (isOpen: boolean) => {
	return mergeStyleSets({
		root: {
			width: isOpen ? 200 : 1,
			height: "calc(100vh - 50px)",
			boxSizing: "border-box",
			border: "1px solid #eee",
			overflowY: "auto",
		},
		content: {
			height: "calc(100vh - 50px)",
			overflowY: "auto",
		},
	});
};
