import React from "react";
import { IStackTokens, Stack, Separator, PrimaryButton } from "@fluentui/react";
import { getTheme } from "@fluentui/react";
import { IQuestionFormProps, getQuestionFormStyle } from ".";
import { QuestionTypeSelector, MultiValue, ChildQuestionForm } from "..";
import { PreviewControl, QuestionControl } from "shared/containers";
import { ActionMode, FieldType } from "admin/questionare";

const theme = getTheme();

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 5,
	padding: 20,
};

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const QuestionForm: React.FC<IQuestionFormProps> = (props) => {
	const {
		action,
		formField,
		onChange,
		onQuestionTypeChange,
		addNewOption,
		onRemoveQuestion,
		removeOption,
		addSubQuestion,
		onDragQuestion,
	} = props;
	const {
		parentId,
		isRequired,
		title,
		questionNo,
		formId,
		fieldId,
		fieldType,
		fieldCode,
		fieldConfiguration,
		childFormFields,
	} = formField;
	const classes = getQuestionFormStyle();

	const isQuestionRequired = isRequired ? "True" : "";

	const onDelete = () => {
		if (onRemoveQuestion) onRemoveQuestion(parentId, fieldId);
	};

	const addNewSubQuestion = () => {
		if (addSubQuestion) addSubQuestion(fieldId);
	};

	const showDisplayWhen = parentId !== undefined;
	const showAddSubQuestionButton =
		action !== ActionMode.Edit &&
		//	parentId === undefined &&
		fieldType !== FieldType.PlainText &&
		fieldType !== FieldType.SectionHeading;

	const questionLabel =
		fieldType !== FieldType.PlainText && fieldType !== FieldType.SectionHeading
			? "Question"
			: "";

	return (
		<Stack
			style={{ boxShadow: theme.effects.elevation8 }}
			className={classes.root}
			tokens={verticalGapStackTokens}
		>
			<Stack.Item align={"end"}>
				<Stack horizontal tokens={horizontalGapStackTokens}>
					<Stack>
						{showDisplayWhen && (
							<QuestionControl
								parentId={parentId}
								fieldId={fieldId}
								formId={formId}
								label={"Display When"}
								fieldType={FieldType.Text}
								value={fieldConfiguration.displayWhen}
								fieldAttribute={"displayWhen"}
								onChange={onChange}
							/>
						)}
					</Stack>
					<Stack>
						<Stack.Item>
							<QuestionTypeSelector
								action={action}
								parentId={parentId}
								fieldType={fieldType}
								fieldId={fieldId}
								onQuestionTypeChange={onQuestionTypeChange}
							/>
						</Stack.Item>
					</Stack>
					{action === ActionMode.Create && (
						<Stack verticalAlign="end">
							<PrimaryButton text="Delete" onClick={onDelete} />
						</Stack>
					)}
				</Stack>
			</Stack.Item>
			{fieldType !== FieldType.PlainText && fieldType !== FieldType.SectionHeading && (
				<Stack horizontal grow={12} tokens={horizontalGapStackTokens}>
					<Stack.Item grow={6}>
						<QuestionControl
							parentId={parentId}
							fieldId={fieldId}
							formId={formId}
							label={"Question No:"}
							fieldType={FieldType.Text}
							value={questionNo}
							fieldAttribute={"questionNo"}
							onChange={onChange}
						/>
					</Stack.Item>
					<Stack.Item grow={6}>
						<QuestionControl
							parentId={parentId}
							fieldId={fieldId}
							formId={formId}
							label={"FieldCode"}
							fieldType={FieldType.Text}
							value={fieldCode}
							fieldAttribute={"fieldCode"}
							onChange={onChange}
						/>
					</Stack.Item>
				</Stack>
			)}
			<Stack>
				<QuestionControl
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					label={questionLabel}
					fieldType={FieldType.PlainText}
					value={title}
					fieldAttribute={"title"}
					onChange={onChange}
				/>
			</Stack>
			<Stack>
				<PreviewControl
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					valueId={fieldId}
					label={"Required"}
					fieldType={FieldType.Checkbox}
					value={isQuestionRequired}
					fieldAttribute={"Required"}
					onChange={onChange}
				/>
			</Stack>

			{fieldType !== FieldType.PlainText && fieldType !== FieldType.SectionHeading && (
				<MultiValue
					action={action}
					parentId={parentId}
					formfield={formField}
					onChange={onChange}
					addNewOption={addNewOption}
					removeOption={removeOption}
				/>
			)}
			{showAddSubQuestionButton && (
				<Stack verticalAlign="end">
					<PrimaryButton text="Add SubQuestion" onClick={addNewSubQuestion} />
				</Stack>
			)}
			<Stack.Item>
				{childFormFields.length > 0 && (
					<>
						<Separator alignContent="start">SubQuestions</Separator>
						<ChildQuestionForm
							parentId={fieldId}
							action={action}
							parentFieldId={fieldId}
							childFormFields={childFormFields}
							addNewOption={addNewOption}
							onChange={onChange}
							onQuestionTypeChange={onQuestionTypeChange}
							onRemoveQuestion={onRemoveQuestion}
							removeOption={removeOption}
							addSubQuestion={addSubQuestion}
							onDragQuestion={onDragQuestion}
						/>
					</>
				)}
			</Stack.Item>
		</Stack>
	);
};
