import { IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { Module } from "authentication/auth.types";
import { AuthStateContext } from "authentication/useAuthContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import { ErrorDialog, Loading, ModalControl, useModal } from "shared/components";
import { useError } from "shared/hooks";
import { IUsersProps } from ".";
import { UserList } from "../components";
import { useUser } from "./useUser";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 15,
	padding: 10,
};

export const Users: React.FC<IUsersProps> = (props) => {
	const { onGetDetail, onCreate } = props;
	const { errorstate, onErrorClose, onError } = useError();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { userRolePermissions } = user;
	const { state, onRemove } = useUser("LIST", { userId: undefined }, onError);
	const { modalState, onCloseModal, onShowModal } = useModal();
	const { SearchBar } = Search;
	const columns = [
		{
			dataField: "firstName",
			text: "Name",
			formatter: (cellContent: any, row: any) => {
				return (
					<>
						{row.firstName} {row.lastName}
					</>
				);
			},
		},
		{
			dataField: "emailAddress",
			text: "Email",
		},
		{
			dataField: "designation",
			text: "Designation",
		},
		{
			dataField: "price",
			text: "Status",
			formatter: (cellContent: any, row: any) => {
				return <>{row.userStatus === 1 ? "Active" : "Inactive"}</>;
			},
		},
		{
			dataField: "button",
			isDummyField: true,
			text: "",
			formatter: (cellContent: any, row: any) => {
				return (
					<>
						<button
							className="btn btn-outline-primary"
							onClick={() => onGetDetail && onGetDetail(row.userId)}
						>
							View Detail
						</button>
						{userRolePermissions.some((c) => c.moduleId === Module.User && c.delete) &&
							user.userId !== row.userId && (
								<button
									className="btn btn-outline-danger ml-2"
									//	onClick={() => onRemove(row.userId)}
									onClick={() =>
										onShowModal(
											"Are you sure?",
											`Delete ${row.firstName} ${row.lastName}`,
											row.userId
										)
									}
								>
									Delete
								</button>
							)}
					</>
				);
			},
		},
	];

	if (state.isBusy) {
		return <Loading />;
	}
	const options = {
		custom: true,
		totalSize: state.users.length,
	};

	const onDeleteConfirm = () => {
		onRemove(parseInt(modalState.data, 10));
		onCloseModal();
	};
	return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="float-right">
						<button className="btn btn-primary mb-4" onClick={onCreate}>
							<i className="fas fa-user-plus"></i> New User
						</button>
					</div>
				</div>
			</div>

			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem tag={Link} to="/admin/home">
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem active>User</BreadcrumbItem>
			</Breadcrumb>
			<ModalControl
				modalState={modalState}
				onCloseModal={onCloseModal}
				onConfirm={onDeleteConfirm}
			/>
			<ErrorDialog
				errorMessage={errorstate.errorMessage}
				show={errorstate.visible}
				onClose={onErrorClose}
			/>
			<div className="row">
				<div className="col-md-12">
					<Card>
						<ToolkitProvider keyField="id" data={state.users} columns={columns} search>
							{(props: any) => (
								<div>
									<div className="d-flex justify-content-end">
										<div className="col-md-4">
											<div className="float-right pt-2">
												<SearchBar {...props.searchProps} />
											</div>
										</div>
									</div>
									<PaginationProvider pagination={paginationFactory(options)}>
										{({ paginationProps, paginationTableProps }: any) => (
											<>
												<BootstrapTable
													keyField="id"
													headerClasses="thead-light"
													bordered={false}
													columns={columns}
													noDataIndication="No records found!"
													{...props.baseProps}
													{...paginationTableProps}
												/>
												<div className="d-flex justify-content-between">
													<div className="col-md-4">
														<PaginationTotalStandalone
															{...paginationProps}
														/>
													</div>
													<div className="col-md-4">
														<div className="float-right">
															<PaginationListStandalone
																{...paginationProps}
															/>
														</div>
													</div>
												</div>
											</>
										)}
									</PaginationProvider>
								</div>
							)}
						</ToolkitProvider>
						{/* <table className="table">
							<thead className="thead-light">
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Designation</th>
									<th>Status</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{state.users.map((item) => {
									return (
										<tr>
											<td>{`${item.firstName} ${item.lastName}`}</td>
											<td>{item.emailAddress}</td>
											<td>{item.designation}</td>
											<td>{item.userStatus === 1 ? "Active" : "Inactive"}</td>
											<td>
												<button
													className="btn btn-outline-primary"
													onClick={() =>
														onGetDetail && onGetDetail(item.userId)
													}
												>
													View Detail
												</button>
												{userRolePermissions.some(
													(c) => c.moduleId === Module.User && c.delete
												) &&
													user.userId !== item.userId && (
														<button
															className="btn btn-outline-danger ml-2"
															onClick={() => onRemove(item.userId)}
														>
															Delete
														</button>
													)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table> */}
					</Card>
				</div>
			</div>
		</>
	);

	// return (
	// 	<Stack tokens={verticalGapStackTokens}>
	// 		<Stack.Item align="end">
	// 			<PrimaryButton text="Add User" onClick={onCreate} />
	// 		</Stack.Item>
	// 		<Stack>
	// 			<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		</Stack>
	// 		<Stack.Item>
	// 			<UserList users={state.users} onGetDetail={onGetDetail} />
	// 		</Stack.Item>
	// 	</Stack>
	// );
};
