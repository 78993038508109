import { Reducer } from "use-immer";
import { BipUserFieldMode, IBipUser, IBipUserQuery, IBipUserState } from "admin/bipuser/containers";
import { baseUrl } from "api/config";
import { IUseErrorHook } from "shared/hooks";
import { error } from "console";

export type IBipUserAction =
	| {
			type: "SET_BIP_USERS";
			users: IBipUser[];
	  }
	| {
			type: "SET_BIP_USER";
			user: IBipUser;
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  }
	| {
			type: "ON_CHANGE";
			bipUserFieldMode: BipUserFieldMode;
			newValue?: string;
	  }
	| {
			type: "SUBMITTED";
	  };

export const bipUserReducer: Reducer<IBipUserState, IBipUserAction> = (
	draft,
	action
): IBipUserState => {
	switch (action.type) {
		case "SET_BIP_USERS":
			draft.users = action.users;
			draft.isBusy = false;
			draft.init = false;
			return draft;
		case "SET_BIP_USER":
			draft.init = false;
			draft.selectedUsers = action.user;
			draft.isBusy = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		case "ON_CHANGE":
			switch (action.bipUserFieldMode) {
				case "Email":
					draft.selectedUsers.emailAddress = action.newValue;
					return draft;
				case "LastName":
					draft.selectedUsers.lastName = action.newValue;
					return draft;
				case "FirstName":
					draft.selectedUsers.firstName = action.newValue;
					return draft;
				case "CompanyName":
					draft.selectedUsers.companyName = action.newValue;
					return draft;
				case "AddressLine1":
					draft.selectedUsers.addressLine1 = action.newValue;
					return draft;
				case "AddressLine2":
					draft.selectedUsers.addressLine2 = action.newValue;
					return draft;
				case "City":
					draft.selectedUsers.city = action.newValue;
					return draft;
				case "Street":
					draft.selectedUsers.street = action.newValue;
					return draft;
				case "Zip":
					draft.selectedUsers.zip = action.newValue;
					return draft;
				case "BusinessId":
					draft.selectedUsers.businessId = action.newValue;
					return draft;
				case "Mobile":
					draft.selectedUsers.mobile = action.newValue;
					return draft;
				case "OfficePhone":
					draft.selectedUsers.officePhone = action.newValue;
					return draft;
				case "CountryId":
					draft.selectedUsers.countryId = action.newValue
						? parseInt(action.newValue?.toString())
						: null;
					return draft;
				case "UserStatus":
					if (action.newValue) draft.selectedUsers.userStatus = parseInt(action.newValue);
					return draft;
				default:
					throw Error("unknown action");
			}
		case "SUBMITTED":
			draft.submitted = true;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");

export const getBipUsers = (
	dispatcher: React.Dispatch<IBipUserAction>,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "BIP")
			.then((res: any) => {
				const users: IBipUser[] = res.data;
				dispatcher({ type: "SET_BIP_USERS", users });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getBipUsersById = (
	dispatcher: React.Dispatch<IBipUserAction>,
	query: IBipUserQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "BIP/" + query.bipId)
			.then((res: any) => {
				const user: IBipUser = res.data;
				dispatcher({ type: "SET_BIP_USER", user });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const createBipUser = (
	dispatcher: React.Dispatch<IBipUserAction>,
	user: IBipUser,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.post(baseUrl + "BIP", user)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
				dispatcher({ type: "SET_BUSY", isBusy: false });
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_BUSY", isBusy: false });
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const updateBipUser = (
	dispatcher: React.Dispatch<IBipUserAction>,
	user: IBipUser,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.put(baseUrl + "BIP/" + user.bipId, user)
			.then((res: any) => {
				dispatcher({ type: "SUBMITTED" });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const deleteBipUser = (
	dispatcher: React.Dispatch<IBipUserAction>,
	bipId: number,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.delete(baseUrl + "BIP/" + bipId)
			.then((res: any) => {
				getBipUsers(dispatcher, onError);
			})
			.catch((err: any) => {
				onError(err.response.data);
				dispatcher({ type: "SET_BUSY", isBusy: false });
			});
	} catch (e) {
		onError(e.message);
		dispatcher({ type: "SET_BUSY", isBusy: false });
	}
};
