import { getAllSections, sectionReducer } from "admin/questionare";
import React from "react";
import { IUseErrorHook } from "shared/hooks";
import { useImmerReducer } from "use-immer";
import { IUseSectionHook } from ".";
import { ISectionQuery } from "..";

export const useSection = (
	query: ISectionQuery,
	onError: IUseErrorHook["onError"]
): IUseSectionHook => {
	const [state, sectionStateDispatcher] = useImmerReducer(sectionReducer, {
		init: true,
		query,
		sections: [],
		isBusy: true,
	});

	React.useEffect(() => {
		if (state.init && state.query.formId) {
			getAllSections(sectionStateDispatcher, query, onError);
		}
	}, [onError, query, sectionStateDispatcher, state.init, state.query]);

	return { state };
};
