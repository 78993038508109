import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";

export const Input = (props: any) => {
	const [state, setState] = useState({
		toggle: false,
		touched: false,
	});

	const ToggleInput = (value: any) => {
		if (value) {
			setState({
				toggle: true,
				touched: true,
			});
		} else {
			setState({
				toggle: false,
				touched: true,
			});
		}
	};
	const isFilled = () => {
		if (state.toggle === true) return "filled";
		else return "";
	};
	const isValid = () => {
		if (state.touched && props.required && !props.value) {
			return "is-invalid";
		}
	};

	return (
		<>
			<div className={"form-wrap " + isFilled()}>
				<AvField
					{...props}
					onBlur={() => ToggleInput(props.value)}
					onFocus={() => setState({ ...state, toggle: true })}
					helpMessage={props.helpMessage}
				></AvField>
			</div>
		</>
	);

	return (
		<>
			<div className={"form-wrap " + isFilled()}>
				<div className="form-group">
					<label htmlFor={props.name}>{props.label}</label>
					<input
						{...props}
						onBlur={() => ToggleInput(props.value)}
						onFocus={() => setState({ ...state, toggle: true })}
						className={"form-control " + isValid()}
						onChange={props.onChange}
					/>
					<div className="invalid-feedback">*Required</div>
					{props.type === "email" && <span className="oi oi-envelope-closed"></span>}
					{props.type === "password" && <span className="oi oi-eye"></span>}
				</div>
			</div>
		</>
	);
};
