import { useImmer } from "use-immer";
import { IUseModalHook, IModalState } from ".";
import React from "react";

export const useModal = (): IUseModalHook => {
	const [modalState, setModalState] = useImmer<IModalState>({
		message: "",
		header: "",
		visible: false,
		data: null,
	});

	const onShowModal = React.useCallback(
		(message: string | undefined, header: string | undefined, data: any) => {
			setModalState((draft) => {
				draft.visible = true;
				draft.message = message;
				draft.header = header;
				draft.data = data;
			});
		},
		[setModalState]
	);

	const onCloseModal = React.useCallback(() => {
		setModalState((draft) => {
			draft.visible = false;
			draft.message = "";
			draft.header = "";
			draft.data = null;
		});
	}, [setModalState]);

	return {
		modalState,
		onShowModal,
		onCloseModal,
	};
};
