import { mergeStyleSets } from "@fluentui/react";

export const getQuestionPreviewStyle = () => {
	return mergeStyleSets({
		root: {
			backgroundColor: "white",
			paddding: "10px",
		},
	});
};
