import React from "react";
import { IReportProps, IReportQuery } from ".";
import { PrimaryButton } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import { baseUrl } from "api/config";
import { useImmer } from "use-immer";
import { Loading } from "shared/components";

export const Report: React.FC<IReportProps> = (props) => {
	const queryString = require("query-string");
	const axios = require("axios");
	const location = useLocation();
	const reportQuery = queryString.parse(location.search) as IReportQuery;
	const [isBusy, setBusy] = useImmer(false);

	if (isBusy) {
		return <Loading label={"Processing..."} />;
	}

	const downlod = () => {
		try {
			setBusy((draft) => true);
			axios
				.get(baseUrl + "Report/" + reportQuery.form + "/" + reportQuery.client, {
					responseType: "blob",
				})
				.then((res: any) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "sample.docx");
					document.body.appendChild(link);
					link.click();
					setBusy((draft) => false);
				})
				.catch((err: any) => {});
		} catch (e) {}
	};

	return (
		<div>
			<PrimaryButton text="Download" onClick={downlod} />
		</div>
	);
};
