import { FieldType } from "admin/questionare";
import React from "react";
import { PreviewControl } from "shared/containers";
import { IAnswerFieldProps } from ".";

export const AnswerField: React.FC<IAnswerFieldProps> = (props) => {
	const { parentId, formFieldValue, fieldType, label, ...other } = props;
	const { answers, valueId, value } = formFieldValue;

	const getAnswer = (): string | undefined => {
		if (fieldType === FieldType.Sortable) {
			return value;
		} else {
			if (!answers.length) {
				return undefined;
			} else {
				return answers[0].value;
			}
		}
	};

	const isMultiValue =
		fieldType === FieldType.Checkbox || fieldType === FieldType.MultiSelectCheckbox;

	return (
		<>
			{isMultiValue && !value ? (
				<>
					<p>{label}</p>
					<PreviewControl
						parentId={parentId}
						valueId={valueId}
						label=""
						fieldType={FieldType.TextArea}
						value={getAnswer()}
						{...other}
					/>
				</>
			) : (
				<PreviewControl
					parentId={parentId}
					valueId={valueId}
					fieldType={fieldType}
					label={label}
					value={getAnswer()}
					{...other}
				/>
			)}
		</>
	);
};
