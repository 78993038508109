import { getTheme, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();

export const useStyles = () => {
	return mergeStyleSets({
		root: {
			width: "70%",
		},
		form: {
			padding: "25px",
			boxShadow: theme.effects.elevation8,
			backgroundColor: "white",
		},
	});
};
