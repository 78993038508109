import { FieldAttribute, FieldType, IFormFieldData, IFormFieldValue } from "admin/questionare";
import React from "react";
import { PreviewControl } from "shared/containers";
import { ILikertControlContainerProps } from "./LikertControlContainer.types";
import { Button } from "reactstrap";
import { useImmer } from "use-immer";

export type LikertActionType = "Add" | "Edit" | "Nothing";

export interface ILikertFormFieldState {
	action: LikertActionType;
	formFieldData: IFormFieldData | null;
}

export const LikertControlContainer: React.FC<ILikertControlContainerProps> = (props) => {
	const { formFieldValues, parentId, fieldId, onChange, removeOption } = props;
	const [likertFormFieldState, setLikertFormFieldState] = useImmer<ILikertFormFieldState>({
		formFieldData: null,
		action: "Nothing",
	});
	const { formFieldData } = likertFormFieldState;

	const onTextChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number,
		dataId?: number
	) => {
		onChange &&
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				FieldType.Likert,
				value,
				valueId,
				dataId
			);
	};
	const onCheckChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number,
		dataId?: number
	) => {
		if (onChange) {
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				FieldType.Likert,
				value,
				valueId,
				dataId
			);
		}
	};

	const onAdd = () => {
		setLikertFormFieldState((draft) => {
			draft.formFieldData = {
				clientId: 0,
				dataId: 0,
				fieldId: 0,
				formId: 0,
				valueId: 0,
				value: "",
			};
			draft.action = "Add";
			return draft;
		});
	};

	const onEdit = (formFieldData: IFormFieldData) => {
		setLikertFormFieldState((draft) => {
			draft.formFieldData = formFieldData;
			draft.action = "Edit";
			return draft;
		});
	};

	const cancel = () => {
		setLikertFormFieldState((draft) => {
			draft.formFieldData = null;
			draft.action = "Nothing";
			return draft;
		});
	};

	let formFieldDatas: IFormFieldData[] = [];
	formFieldValues.forEach((s) => {
		formFieldDatas = formFieldDatas.concat(s.answers);
	});
	formFieldDatas.sort((a: any, b: any) => {
		return a.dataId - b.dataId;
	});
	const getRows = (): JSX.Element => {
		return (
			<tbody>
				{likertFormFieldState.formFieldData && likertFormFieldState.action === "Add" && (
					<tr>
						<td>
							<div className="form-group">
								<input
									type="text"
									className="form-control"
									value={formFieldData ? formFieldData.value.split("#")[0] : ""}
									onChange={onValueChange}
								/>
							</div>
						</td>
						{formFieldValues.map((options: IFormFieldValue, index: number) => {
							if (formFieldData) {
								const array: string[] = formFieldData.value.split("#");
								const checked = array[1] === options.value ? true : false;
								return (
									<td>
										<div className="mt-1">
											<div className="pt-checkbox">
												<input
													id={`chk${index}`}
													type="checkbox"
													name={options.title}
													onChange={onChangeSelect}
													checked={checked}
												/>
												<label
													className="pt-checkbox"
													htmlFor={`chk${index}`}
												>
													{""}
												</label>
											</div>
										</div>
									</td>
								);
							}
							return null;
						})}
						<td>
							<Button color="btn btn-outline-primary" onClick={onAddConfirm}>
								Add
							</Button>
						</td>
						<td>
							<Button color="secondary" onClick={cancel}>
								Cancel
							</Button>
						</td>
					</tr>
				)}
				{formFieldDatas.map((formFieldData: IFormFieldData) => {
					if (
						likertFormFieldState.formFieldData &&
						formFieldData.dataId === likertFormFieldState.formFieldData.dataId &&
						likertFormFieldState.action === "Edit"
					) {
						return (
							<tr>
								<td>
									<div className="form-group">
										<input
											type="text"
											className="form-control"
											value={
												likertFormFieldState.formFieldData
													? likertFormFieldState.formFieldData.value.split(
															"#"
													  )[0]
													: ""
											}
											onChange={onValueChange}
										/>
									</div>
								</td>
								{formFieldValues.map((options: IFormFieldValue, index: number) => {
									if (likertFormFieldState.formFieldData) {
										const array: string[] = likertFormFieldState.formFieldData.value.split(
											"#"
										);
										const checked = array[1] === options.value ? true : false;
										return (
											<td>
												<div className="mt-1">
													<div className="pt-checkbox">
														<input
															id={`chk${index}`}
															type="checkbox"
															name={options.title}
															onChange={onChangeSelect}
															checked={checked}
														/>
														<label
															className="pt-checkbox"
															htmlFor={`chk${index}`}
														>
															{""}
														</label>
													</div>
												</div>
											</td>
										);
									}
									return null;
								})}
								<td>
									<Button
										color="btn btn-outline-primary"
										onClick={onUpdateConfirm}
									>
										Update
									</Button>
								</td>
								<td>
									<Button color="secondary" onClick={cancel}>
										Cancel
									</Button>
								</td>
							</tr>
						);
					} else {
						const array: string[] = formFieldData.value.split("#");
						return (
							<tr>
								<td>
									<PreviewControl
										dataId={formFieldData.dataId}
										parentId={parentId}
										fieldId={formFieldData.fieldId}
										valueId={formFieldData.valueId}
										fieldType={FieldType.Text}
										formId={formFieldData.formId}
										fieldAttribute={"LikertText"}
										disabled={true}
										value={array[0]}
										errorMessage={""}
										onChange={onTextChange}
									/>
								</td>
								{formFieldValues.map((options: IFormFieldValue) => {
									return (
										<td>
											<PreviewControl
												dataId={formFieldData.dataId}
												parentId={parentId}
												fieldId={formFieldData.fieldId}
												fieldType={FieldType.Checkbox}
												valueId={options.valueId}
												formId={formFieldData.formId}
												fieldAttribute={"LikertCheckBox"}
												disabled={true}
												value={
													formFieldData.fieldId === options.fieldId &&
													options.value === array[1]
														? array[1]
														: undefined
												}
												errorMessage={""}
												onChange={onCheckChange}
											/>
										</td>
									);
								})}
								<td>
									<button
										className="btn btn-outline-primary"
										onClick={() => {
											onEdit(formFieldData);
										}}
									>
										<i className="fa fa-edit"></i>
									</button>
								</td>
								<td>
									<button
										className="btn btn-outline-danger"
										onClick={() => {
											removeOption &&
												removeOption(
													fieldId,
													formFieldData.dataId,
													formFieldData.valueId
												);
										}}
									>
										<i className="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						);
					}
				})}
			</tbody>
		);
	};

	const onChangeSelect = React.useCallback(
		(ev?: React.ChangeEvent<HTMLInputElement>) => {
			if (formFieldData) {
				const array: string[] = formFieldData.value.split("#");
				const txt = array[0] ? array[0] : "";
				const dat = `${txt}#${ev?.target.name}`;
				setLikertFormFieldState((draft) => {
					if (draft.formFieldData) {
						draft.formFieldData.value = dat;
					}
					return draft;
				});
			}
		},
		[formFieldData, setLikertFormFieldState]
	);

	const onValueChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (formFieldData) {
				const array: string[] = formFieldData.value.split("#");
				const chk = array[1] ? array[1] : "";
				const dat = `${event.target.value}#${chk}`;
				setLikertFormFieldState((draft) => {
					if (draft.formFieldData) {
						draft.formFieldData.value = dat;
					}
					return draft;
				});
			}
		},
		[formFieldData, setLikertFormFieldState]
	);

	const onAddConfirm = () => {
		if (formFieldData) {
			const array: string[] = formFieldData.value.split("#");
			const ffv = formFieldValues.find((s) => s.value === array[1]);
			if (ffv) {
				onChange &&
					onChange(
						parentId,
						fieldId,
						0,
						"LikertText",
						FieldType.Likert,
						formFieldData.value,
						ffv.valueId,
						undefined
					);
				setLikertFormFieldState((draft) => {
					draft.formFieldData = null;
					return draft;
				});
				cancel();
			}
		}
	};

	const onUpdateConfirm = () => {
		if (formFieldData) {
			onChange &&
				onChange(
					parentId,
					fieldId,
					0,
					"LikertText",
					FieldType.Likert,
					formFieldData.value,
					formFieldData.valueId,
					formFieldData.dataId
				);
			setLikertFormFieldState((draft) => {
				draft.formFieldData = null;
				return draft;
			});
			cancel();
		}
	};

	return (
		<div>
			<div className="row">
				<button className="btn btn-outline-primary" onClick={() => onAdd()}>
					<i className="fa fa-plus"></i>
				</button>
			</div>
			<div className="row">
				<table>
					<tr>
						<th
							className="table table-table-striped"
							style={{ textAlign: "left", width: 300 }}
						>
							Name
						</th>
						{formFieldValues.map((s) => {
							return <th style={{ textAlign: "left" }}>{s.value}</th>;
						})}
						<th></th>
					</tr>
					{getRows()}
				</table>
			</div>
			{/* <Modal isOpen={modal.visible}>
				<ModalHeader>{modal.type === "Add" ? "Add new" : "Edit"}</ModalHeader>
				<ModalBody>{modal.type === "Add" ? addNewBody : editBody}</ModalBody>
				<ModalFooter>
					{modal.type === "Add" ? (
						<Button color="btn btn-outline-danger" onClick={onAddConfirm}>
							Add
						</Button>
					) : (
						<Button color="btn btn-outline-danger" onClick={onUpdateConfirm}>
							Update
						</Button>
					)}

					<Button color="secondary" onClick={cancelModal}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal> */}
		</div>
	);
};
