import React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { IQuestionControlProps, QuestionControl } from "shared/containers";
import { CurrencyControl } from "shared/components";
import { FieldAttribute, FieldType } from "admin/questionare";

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const TextAndCurrencyControl: React.FC<IQuestionControlProps> = (props) => {
	const { parentId, disabled, formId, fieldId, valueId, value, onChange } = props;
	const values = value?.split("#");
	let text: string | undefined = values && values[0] ? values[0] : "";
	let currency: string | undefined = values && values[1] ? values[1] : "";

	const onTextChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		if (onChange)
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				fieldType,
				`${value}#${currency}`,
				valueId
			);
	};

	const onCurrencyChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		if (onChange)
			onChange(
				parentId,
				fieldId,
				formId,
				fieldAttribute,
				fieldType,
				`${text}#${value}`,
				valueId
			);
	};

	return (
		<Stack horizontal tokens={horizontalGapStackTokens}>
			<Stack.Item>
				<QuestionControl
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					fieldType={FieldType.Text}
					value={text}
					valueId={valueId}
					disabled={disabled}
					fieldAttribute={"answer"}
					onChange={onTextChange}
				/>
			</Stack.Item>
			<Stack.Item>
				<CurrencyControl
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					fieldType={FieldType.Currency}
					value={currency}
					valueId={valueId}
					fieldAttribute={"answer"}
					disabled={disabled}
					onChange={onCurrencyChange}
				/>
			</Stack.Item>
		</Stack>
	);
};
