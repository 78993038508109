import React from "react";
import { IQuestionControlProps, QuestionControl } from "shared/containers";
import { Stack, IStackTokens } from "@fluentui/react";
import { FieldAttribute, FieldType, IFieldConfiguration } from "admin/questionare";

const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const MinMaxControl: React.FC<IQuestionControlProps> = (props) => {
	const { parentId, disabled, formId, fieldId, configuration, valueId, onChange } = props;
	const minMaxConfig: IFieldConfiguration = configuration as IFieldConfiguration;
	//const reg = /^(-?\d+(\.\d{0,2})?|\.?\d{1,2})$/;
	const reg = /^\-?\d*\.?\d*$/;
	const onMinValueChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		newValue?: string,
		valueId?: number
	) => {
		const val: string = newValue ? newValue : "";
		if (onChange) {
			if (val) {
				if (reg.test(val)) {
					onChange(
						parentId,
						fieldId,
						formId,
						fieldAttribute,
						fieldType,
						newValue,
						valueId
					);
				}
			} else {
				onChange(parentId, fieldId, formId, fieldAttribute, fieldType, newValue, valueId);
			}
		}
	};

	const onMaxValueChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		newValue?: string,
		valueId?: number
	) => {
		const val: string = newValue ? newValue : "";
		if (onChange) {
			if (val) {
				if (reg.test(val)) {
					onChange(
						parentId,
						fieldId,
						formId,
						fieldAttribute,
						fieldType,
						newValue,
						valueId
					);
				}
			} else {
				onChange(parentId, fieldId, formId, fieldAttribute, fieldType, newValue, valueId);
			}
		}
	};

	return (
		<Stack horizontal tokens={horizontalGapStackTokens}>
			<Stack.Item>
				<QuestionControl
					label="Min Value"
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					fieldType={1}
					value={minMaxConfig.min}
					valueId={valueId}
					fieldAttribute={"min"}
					disabled={disabled}
					onChange={onMinValueChange}
				/>
			</Stack.Item>
			<Stack.Item>
				<QuestionControl
					label="Max Value"
					parentId={parentId}
					fieldId={fieldId}
					formId={formId}
					fieldType={1}
					value={minMaxConfig.max}
					valueId={valueId}
					disabled={disabled}
					fieldAttribute={"max"}
					onChange={onMaxValueChange}
				/>
			</Stack.Item>
		</Stack>
	);
};
