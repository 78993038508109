import React, { useContext } from "react";
import { IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { ErrorDialog, Loading, ModalControl, useModal } from "shared/components";
import { IClientsProps, useClient } from ".";
import { ClientList } from "../components/ClientList";
import { useError } from "shared/hooks";
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { AuthStateContext } from "authentication/useAuthContext";
import { Module, UserType } from "authentication/auth.types";
import Pagination from "react-js-pagination";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 15,
	padding: 10,
};

export const Clients: React.FC<IClientsProps> = (props) => {
	const { onGetDetail, onCreate } = props;
	const { errorstate, onErrorClose, onError } = useError();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { userRolePermissions } = user;
	const { state, onRemoveClient } = useClient("LIST", { clientId: undefined }, onError);
	const { modalState, onCloseModal, onShowModal } = useModal();
	const { SearchBar } = Search;

	const columns = [
		{
			dataField: "firstName",
			text: "Name",
			formatter: (cellContent: any, row: any) => {
				return (
					<>
						{row.firstName} {row.lastName}
					</>
				);
			},
		},
		{
			dataField: "emailAddress",
			text: "Email",
		},
		{
			dataField: "baqStatus",
			text: "BAQ Status",
		},
		{
			dataField: "price",
			text: "Status",
			formatter: (cellContent: any, row: any) => {
				return <>{row.userStatus === 1 ? "Active" : "Inactive"}</>;
			},
		},
		{
			dataField: "button",
			isDummyField: true,
			text: "",
			formatter: (cellContent: any, row: any) => {
				return (
					<>
						<button
							className="btn btn-outline-primary"
							onClick={() => onGetDetail && onGetDetail(row.clientId)}
						>
							View Detail
						</button>

						{userRolePermissions.some(
							(c) => c.moduleId === Module.Client && c.delete
						) && (
							<button
								className="btn btn-outline-danger ml-2"
								onClick={() =>
									onShowModal(
										"Are you sure?",
										`Delete ${row.firstName} ${row.lastName}`,
										row.clientId
									)
								}
							>
								Delete
							</button>
						)}
					</>
				);
			},
		},
	];

	if (state.isBusy) {
		return <Loading />;
	}
	const options = {
		custom: true,
		totalSize: state.clients.length,
	};

	const onDeleteConfirm = () => {
		onRemoveClient(parseInt(modalState.data, 10));
		onCloseModal();
	};
	return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="float-right">
						<button className="btn btn-primary mb-4" onClick={onCreate}>
							<i className="fas fa-user-plus"></i> New Client
						</button>
					</div>
				</div>
			</div>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Dashboard
				</BreadcrumbItem>
				<BreadcrumbItem active>Client</BreadcrumbItem>
			</Breadcrumb>
			<ModalControl
				modalState={modalState}
				onCloseModal={onCloseModal}
				onConfirm={onDeleteConfirm}
			/>
			<ErrorDialog
				errorMessage={errorstate.errorMessage}
				show={errorstate.visible}
				onClose={onErrorClose}
			/>
			<div className="row">
				<div className="col-md-12">
					<Card>
						<ToolkitProvider
							keyField="id"
							data={state.clients}
							columns={columns}
							search
						>
							{(props: any) => (
								<div>
									<div className="d-flex justify-content-end">
										<div className="col-md-4">
											<div className="float-right pt-2">
												<SearchBar {...props.searchProps} />
											</div>
										</div>
									</div>
									<PaginationProvider pagination={paginationFactory(options)}>
										{({ paginationProps, paginationTableProps }: any) => (
											<>
												<BootstrapTable
													keyField="id"
													headerClasses="thead-light"
													bordered={false}
													columns={columns}
													noDataIndication="No records found!"
													{...props.baseProps}
													{...paginationTableProps}
												/>
												<div className="d-flex justify-content-between">
													<div className="col-md-4">
														<PaginationTotalStandalone
															{...paginationProps}
														/>
													</div>
													<div className="col-md-4">
														<div className="float-right">
															<PaginationListStandalone
																{...paginationProps}
															/>
														</div>
													</div>
												</div>
											</>
										)}
									</PaginationProvider>
								</div>
							)}
						</ToolkitProvider>
						{/* <PaginationProvider pagination={paginationFactory(options)}>
							{({ paginationProps, paginationTableProps }: any) => (
								<>
									<BootstrapTable
										keyField="id"
										headerClasses="thead-light"
										data={state.clients}
										bordered={false}
										columns={columns}
										noDataIndication="No records found!"
										{...paginationTableProps}
									/>
									<div className="d-flex justify-content-between">
										<div className="col-md-4">
											<PaginationTotalStandalone {...paginationProps} />
										</div>
										<div className="col-md-4">
											<div className="float-right">
												<PaginationListStandalone {...paginationProps} />
											</div>
										</div>
									</div>
								</>
							)}
						</PaginationProvider> */}
						{/* <table className="table">
							<thead className="thead-light">
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>BAQ Status</th>
									<th>Status</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{state.clients.map((item) => {
									return (
										<tr>
											<td>{`${item.firstName} ${item.lastName}`}</td>
											<td>{item.emailAddress}</td>
											<td>{item.baqStatus}</td>
											<td>{item.userStatus === 1 ? "Active" : "Inactive"}</td>
											<td>
												<button
													className="btn btn-outline-primary"
													onClick={() =>
														onGetDetail && onGetDetail(item.clientId)
													}
												>
													View Detail
												</button>

												{userRolePermissions.some(
													(c) => c.moduleId === Module.Client && c.delete
												) && (
													<button
														className="btn btn-outline-danger ml-2"
														onClick={() =>
															onRemoveClient(item.clientId)
														}
													>
														Delete
													</button>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<Pagination
									innerClass="pagination float-right"
									activePage={1}
									itemClass="page-item"
									linkClass="page-link"
									itemsCountPerPage={10}
									totalItemsCount={450}
									pageRangeDisplayed={5}
								/>
							</tfoot>
						</table> */}
					</Card>
				</div>
			</div>
		</>
	);
	// return (
	// 	<Stack tokens={verticalGapStackTokens}>
	// 		<Stack.Item align="end">
	// 			<PrimaryButton text="Add Clients" onClick={onCreate} />
	// 		</Stack.Item>
	// 		<Stack>
	// 			<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		</Stack>
	// 		<Stack.Item>
	// 			<ClientList clients={state.clients} onGetDetail={onGetDetail} />
	// 		</Stack.Item>
	// 	</Stack>
	// );
};
