import React from "react";

import { IPreviewControlProps } from ".";
import {
	TextFieldControl,
	CheckBoxControl,
	NumberControl,
	CurrencyControl,
	DateControl,
	TextAreaControl,
	HtmlControl,
	StackControl,
	PercentageControl,
	NoAnswerControl,
	TextAndCurrencyControl,
	LikertControl,
} from "shared/components";
import { FieldType } from "admin/questionare";

export const PreviewControl: React.FC<IPreviewControlProps> = (props) => {
	const { fieldType, ...other } = props;
	switch (fieldType) {
		case FieldType.Text:
		case FieldType.MultilineText:
			return <TextFieldControl fieldType={fieldType} {...other} />;
		case FieldType.Checkbox:
		case FieldType.MultiSelectCheckbox:
			return <CheckBoxControl fieldType={fieldType} {...other} />;
		case FieldType.Number:
			return <NumberControl {...props} />;
		case FieldType.Currency:
			return <CurrencyControl {...props} />;
		case FieldType.Date:
			return <DateControl {...props} />;
		case FieldType.TextArea:
		case FieldType.MultilineTextArea:
			return <TextAreaControl {...props} />;
		case FieldType.PlainText:
		case FieldType.SectionHeading:
			return <HtmlControl {...props} />;
		case FieldType.Sortable:
			return <StackControl {...props} />;
		case FieldType.Percentage:
			return <PercentageControl {...props} />;
		case FieldType.NoAnswer:
			return <NoAnswerControl {...props} />;
		case FieldType.TextAndCurrency:
			return <TextAndCurrencyControl {...props} />;
		case FieldType.Likert:
			return <LikertControl {...props} />;
	}
	return <div>unknown field type</div>;
};
