import { RouteProps } from "react-router-dom";
import { Module } from "./auth.types";

export interface IProtectedRoute extends RouteProps {}

export interface ModuleRoute {
	route: string;
	module: Module;
}
export interface UserModuleRoute {
	userType: number;
	moduleRoute: ModuleRoute[];
}

export const UserModuleRoutes: UserModuleRoute[] = [
	{
		userType: 1,
		moduleRoute: [
			{ route: "/admin/editprofile", module: Module.Home },
			{ route: "/admin/home", module: Module.Home },
			{ route: "/admin/user", module: Module.User },
			{ route: "/admin/user/create", module: Module.User },
			{ route: "/admin/user/edit", module: Module.User },
			{ route: "/admin/bipuser", module: Module.User },
			{ route: "/admin/bipuser/create", module: Module.User },
			{ route: "/admin/bipuser/details", module: Module.User },
			{ route: "/admin/client", module: Module.Client },
			{ route: "/admin/client/create", module: Module.Client },
			{ route: "/admin/client/details", module: Module.Client },
			{ route: "/admin/client/edit", module: Module.Client },
			{ route: "/admin/questionare", module: Module.BAQ },
			{ route: "/admin/questionare/details", module: Module.BAQ },
			{ route: "/admin/questionare/create", module: Module.BAQ },
			{ route: "/admin/questionare/edit", module: Module.BAQ },
			{ route: "/admin/questionare/preview", module: Module.BAQ },
			{ route: "/admin/settings", module: Module.Settings },
			{ route: "/admin/settings/edit", module: Module.Settings },
		],
	},
	{
		userType: 2,
		moduleRoute: [
			{ route: "/bip/editprofile", module: Module.Home },
			{ route: "/bip/home", module: Module.Home },
			{ route: "/bip/client", module: Module.Client },
			{ route: "/bip/client/create", module: Module.Client },
			{ route: "/bip/client/details", module: Module.Client },
			{ route: "/bip/client/edit", module: Module.Client },
			{ route: "/bip/client/edit", module: Module.Client },
			{ route: "/bip/client/edit", module: Module.Client },
			{ route: "/bip/questionare", module: Module.BAQ },
			{ route: "/bip/questionare/details", module: Module.BAQ },
			{ route: "/bip/questionare/edit", module: Module.BAQ },
			{ route: "/bip/questionare/preview", module: Module.BAQ },
			{ route: "/client/answer", module: Module.BAQ },
			{ route: "/bip/settings", module: Module.Settings },
			{ route: "/bip/settings/edit", module: Module.Settings },
		],
	},
	{
		userType: 3,
		moduleRoute: [
			{ route: "/client/home", module: Module.BAQ },
			{ route: "/client/editprofile", module: Module.BAQ },
			{ route: "/client/answer", module: Module.BAQ },
		],
	},
];

//{ route: "/admin/home", module: Module.BAQ }];
