import React from "react";
import { IChildQuestionFormProps } from ".";
import { QuestionsForm } from "..";

export const ChildQuestionForm: React.FC<IChildQuestionFormProps> = (props) => {
	const {
		action,
		childFormFields,
		parentId,
		addNewOption,
		onChange,
		onQuestionTypeChange,
		onRemoveQuestion,
		removeOption,
		addSubQuestion,
		onDragQuestion,
	} = props;
	return (
		<QuestionsForm
			parentId={parentId}
			isParent={false}
			action={action}
			formFields={childFormFields}
			addNewOption={addNewOption}
			addSubQuestion={addSubQuestion}
			onChange={onChange}
			onQuestionTypeChange={onQuestionTypeChange}
			onRemoveQuestion={onRemoveQuestion}
			removeOption={removeOption}
			onDragQuestion={onDragQuestion}
		/>
	);
};
