import React from "react";

import {
	bipUserReducer,
	createBipUser,
	deleteBipUser,
	getBipUsers,
	getBipUsersById,
	updateBipUser,
} from "admin/bipuser/actions";
import { useImmerReducer } from "use-immer";
import { BipUserFieldMode, BipUserMode, IBipUserQuery, IUseBipUserHook } from ".";
import { useHistory } from "react-router-dom";
import { IUseErrorHook } from "shared/hooks";

export const useBipUser = (
	mode: BipUserMode,
	query: IBipUserQuery,
	onError: IUseErrorHook["onError"]
): IUseBipUserHook => {
	const history = useHistory();
	const [state, bipUserDispatcher] = useImmerReducer(bipUserReducer, {
		init: true,
		isBusy: true,
		selectedUsers: {
			bipId: 0,
			emailAddress: "",
			firstName: "",
			lastName: "",
			addressLine1: "",
			addressLine2: "",
			businessId: "",
			companyName: "",
			city: "",
			countryId: null,
			mobile: "",
			officePhone: "",
			street: "",
			userStatus: 0,
			zip: "",
		},
		users: [],
		query,
		mode,
		submitted: false,
	});

	React.useEffect(() => {
		if (state.submitted) {
			//const link = "/admin/bipuser";
			history.goBack();
		}
	}, [history, state.mode, state.submitted]);

	React.useEffect(() => {
		if (mode === "CREATE" && state.init) {
			bipUserDispatcher({ type: "SET_BUSY", isBusy: false });
		}
	}, [bipUserDispatcher, mode, state.init]);

	React.useEffect(() => {
		if (mode === "LIST" && state.init && !query.bipId) {
			getBipUsers(bipUserDispatcher, onError);
		}
	}, [bipUserDispatcher, mode, onError, query.bipId, state.init]);

	React.useEffect(() => {
		if (mode === "EDIT" && state.init && query.bipId) {
			getBipUsersById(bipUserDispatcher, query, onError);
		}
	}, [bipUserDispatcher, mode, onError, query, query.bipId, state.init]);

	const onCreate = () => {
		createBipUser(bipUserDispatcher, state.selectedUsers, onError);
	};

	const onSave = () => {
		updateBipUser(bipUserDispatcher, state.selectedUsers, onError);
	};

	const onChange = (bipUserFieldMode: BipUserFieldMode, newValue?: string) => {
		bipUserDispatcher({ type: "ON_CHANGE", bipUserFieldMode, newValue });
	};

	const onDelete = (bipId: number) => {
		deleteBipUser(bipUserDispatcher, bipId, onError);
	};

	return { state, onCreate, onSave, onChange, onDelete };
};
