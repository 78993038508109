import React, { useContext } from "react";
import { IFormProps, useForm, IFormQuery } from ".";
import { Link, useLocation } from "react-router-dom";
import { ErrorDialog, Loading } from "shared/components";
import { FormList } from "admin/questionare";
import { useError } from "shared/hooks";
import { Stack } from "@fluentui/react";
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import { AuthStateContext, UserType } from "authentication";

export const Form: React.FC<IFormProps> = (props) => {
	const { onGetDetail } = props;
	const queryString = require("query-string");
	const location = useLocation();
	const formQuery = queryString.parse(location.search) as IFormQuery;
	const { errorstate, onErrorClose, onError } = useError();
	const { state } = useForm(formQuery, onError);
	const { forms, isBusy } = state;
	const authState = useContext(AuthStateContext);
	const { user } = authState;

	if (isBusy) {
		return <Loading />;
	}

	return (
		<>
			<Breadcrumb tag="nav" listTag="div">
				<BreadcrumbItem
					tag={Link}
					to={`/${user.userType === UserType.BIP ? "bip" : "admin"}/home`}
				>
					Home
				</BreadcrumbItem>
				<BreadcrumbItem active>BAQ</BreadcrumbItem>
			</Breadcrumb>
			<div className="row">
				<div className="col-md-12">
					<Card>
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>Name</th>
									<th>Status</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{forms.map((item) => {
									return (
										<tr>
											<td>{`${item.title}`}</td>
											<td>{item.formStatus === 1 ? "Published" : "Draft"}</td>
											<td>
												<button
													className="btn btn-outline-primary"
													onClick={() =>
														onGetDetail && onGetDetail(item.formId)
													}
												>
													View Detail
												</button>{" "}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Card>
				</div>
			</div>
		</>
	);

	// return (
	// 	<Stack>
	// 		<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 		<FormList forms={forms} onGetDetail={onGetDetail} />;
	// 	</Stack>
	// );
};
