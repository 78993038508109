import {
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	PrimaryButton,
	SelectionMode,
} from "@fluentui/react";
import { IBipUser } from "admin/bipuser/containers";
import React from "react";
import { IBipUserList, IBipUserListProps } from ".";

export const BipUserList: React.FC<IBipUserListProps> = (props) => {
	const { users, onGetDetail } = props;

	const bipUserList: IBipUserList[] = [];
	users.forEach((user: IBipUser) => {
		const bipUser: IBipUserList = {
			bipId: user.bipId,
			emailAddress: user.emailAddress,
			fullName: user.firstName + " " + user.lastName,
		};
		bipUserList.push(bipUser);
	});

	const _columns: IColumn[] = [
		{
			key: "fullName",
			name: "Full Name",
			fieldName: "fullName",
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "emailAddress",
			name: "Email",
			fieldName: "emailAddress",
			minWidth: 100,
			maxWidth: 200,
			isResizable: false,
		},
		{
			key: "view",
			name: "",
			fieldName: "",
			minWidth: 100,
			maxWidth: 200,
			isResizable: false,
		},
	];

	const onRenderItemColumn = (item: IBipUserList, index?: number, column?: IColumn) => {
		if (!column) return;
		if (column.key === "emailAddress") {
			return <> {item.emailAddress}</>;
		}
		if (column.key === "fullName") {
			return <> {item.fullName}</>;
		}
		if (column.key === "view") {
			return (
				<PrimaryButton
					text="View Details"
					onClick={() => onGetDetail && onGetDetail(item.bipId)}
				/>
			);
		}
	};

	return (
		<DetailsList
			items={bipUserList}
			columns={_columns}
			setKey="set"
			layoutMode={DetailsListLayoutMode.justified}
			selectionPreservedOnEmptyClick={true}
			selectionMode={SelectionMode.none}
			onRenderItemColumn={onRenderItemColumn}
		/>
	);
};
