import { FieldAttribute, FieldType } from "admin/questionare";
import React from "react";
import { IQuestionControlProps, QuestionControl } from "shared/containers";

export const SortableInputControl: React.FC<IQuestionControlProps> = (props) => {
	const { parentId, disabled, formId, fieldId, value, valueId, onChange } = props;

	const onOptionValueChange = (
		parentId: number | undefined,
		fieldId: number,
		formId: number,
		fieldAttribute: FieldAttribute,
		fieldType: FieldType,
		value?: string,
		valueId?: number
	) => {
		if (onChange) onChange(parentId, fieldId, formId, "optionValue", fieldType, value, valueId);
	};

	return (
		<div className="col-md-12">
			<QuestionControl
				parentId={parentId}
				fieldId={fieldId}
				formId={formId}
				fieldType={1}
				value={value}
				valueId={valueId}
				disabled={disabled}
				fieldAttribute={"optionValue"}
				onChange={onOptionValueChange}
			/>
		</div>
	);
};
