import React, { createContext, FC, Dispatch } from "react";

import {
	authReducer,
	IAuthState,
	IAuthAction,
	UserModuleRoute,
	UserModuleRoutes,
	ModuleRoute,
} from ".";
import { useImmerReducer } from "use-immer";
import { useHistory, useLocation } from "react-router-dom";
import { IAuthenticatedUser, UserType } from "./auth.types";

export const AuthStateContext = createContext<IAuthState>({
	authenticated: "false",
	token: null,
	expiry: null,
	refreshToken: null,
	user: {
		roleId: 0,
		firstName: "",
		lastName: "",
		emailAddress: "",
		userId: 0,
		userType: 0,
		currency: "$",
		userRolePermissions: [],
	},
	credential: {
		emailAddress: "",
		password: "",
		emailAddressErrorMessage: "",
		passwordErrorMessage: "",
		errorMessage: "",
	},
	isBusy: false,
});
export const AuthDispatcherContext = createContext<Dispatch<IAuthAction> | null>(null);

export const UseAuthProvider: FC = ({ children }) => {
	const history = useHistory();
	const location = useLocation();
	const getUser = (): IAuthenticatedUser => {
		let user: IAuthenticatedUser = {
			roleId: 0,
			firstName: "",
			lastName: "",
			emailAddress: "",
			userId: 0,
			userType: 0,
			currency: "$",
			userRolePermissions: [],
		};
		let authenticated: string | null = localStorage.getItem("ProfituneAuth");
		if (authenticated && authenticated === "true") {
			const retrievedObject: string | null = localStorage.getItem("UserInfo");
			if (retrievedObject) {
				user = JSON.parse(retrievedObject) as IAuthenticatedUser;
			}
		}
		return user;
	};

	const [authState, authDispatcher] = useImmerReducer(authReducer, {
		authenticated: localStorage.getItem("ProfituneAuth"),
		token: null,
		expiry: null,
		refreshToken: null,
		user: getUser(),
		credential: {
			emailAddress: "",
			password: "",
			emailAddressErrorMessage: "",
			passwordErrorMessage: "",
			errorMessage: "",
		},
		isBusy: false,
	});

	React.useEffect(() => {
		const { pathname, search } = location;
		if (authState.authenticated === "false" || authState.authenticated === null) {
			const pathArray = pathname.split("/");
			if (pathname === `/signin` || pathname === `/admin/signin`) {
				history.replace(`${pathname}`);
			} else if (pathArray[1] === "admin" || pathArray[1] === "bip") {
				history.replace(`/admin/signin`);
			} else {
				history.replace(`/signin`);
			}
		} else {
			if (authState.user.userType === UserType.Admin) {
				const Module: UserModuleRoute = UserModuleRoutes[0];
				const route: ModuleRoute | undefined = Module.moduleRoute.find(
					(s) => s.route === pathname
				);
				if (route) {
					history.replace(search ? `${pathname}${search}` : `${pathname}`);
				} else {
					history.replace(`/admin/home`);
				}
			}
			if (authState.user.userType === UserType.BIP) {
				const Module: UserModuleRoute = UserModuleRoutes[1];
				const route: ModuleRoute | undefined = Module.moduleRoute.find(
					(s) => s.route === pathname
				);
				if (route) {
					history.replace(search ? `${pathname}${search}` : `${pathname}`);
				} else {
					history.replace(`/bip/home`);
				}
			}
			if (authState.user.userType === UserType.Client) {
				const Module: UserModuleRoute = UserModuleRoutes[2];
				const route: ModuleRoute | undefined = Module.moduleRoute.find(
					(s) => s.route === pathname
				);
				if (route) {
					history.replace(search ? `${pathname}${search}` : `${pathname}`);
				} else {
					history.replace(`/client/home`);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, authState.authenticated]);

	return (
		<AuthDispatcherContext.Provider value={authDispatcher}>
			<AuthStateContext.Provider value={authState}>{children}</AuthStateContext.Provider>
		</AuthDispatcherContext.Provider>
	);
};
