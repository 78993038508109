import React from "react";
import { IQuestionControlProps } from "shared/containers";
import { Editor, RawDraftContentState } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import Draft, { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Label, Stack } from "@fluentui/react";

export const RichTextControl: React.FC<IQuestionControlProps> = (props) => {
	const {
		label,
		parentId,
		formId,
		fieldId,
		valueId,
		fieldAttribute,
		fieldType,
		value,
		onChange,
	} = props;

	const EditorState = Draft.EditorState;
	const ContentState = Draft.ContentState;
	const blocksFromHtml = htmlToDraft(value ? value : "");
	const { contentBlocks, entityMap } = blocksFromHtml;
	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
	const [editorState, setEditorState] = React.useState(
		EditorState.createWithContent(contentState)
	);

	const onContentStateChange = (contentState: RawDraftContentState) => {
		if (editorState) {
			const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
			if (onChange)
				onChange(parentId, fieldId, formId, fieldAttribute, fieldType, newValue, valueId);
		}
	};

	return (
		<Stack>
			<Stack.Item>
				<Label>{label}</Label>
			</Stack.Item>
			<Editor
				toolbar={{
					inline: { inDropdown: true, options: ["bold", "italic", "underline"] },
					list: { inDropdown: true },
					textAlign: { inDropdown: true },
					link: { inDropdown: true },
					history: { inDropdown: true },
				}}
				wrapperStyle={{ border: "1px solid black", minHeight: "170px" }}
				toolbarStyle={{ borderBottom: "1px solid black" }}
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={setEditorState}
				onContentStateChange={onContentStateChange}
			/>
		</Stack>
	);
};
