import { mergeStyleSets } from "office-ui-fabric-react";

export const useStyles = () => {
	return mergeStyleSets({
		root: {
			height: "100vh",
		},
		login: { height: "100vh", backgroundColor: "white" },
		loginInnerConent: {
			backgroundColor: "white",
			width: "65%",
			margin: "25vh 50px",
		},
	});
};
