import React from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { IModalState } from ".";

export interface IModalControlProps {
	modalState: IModalState;
	onCloseModal: () => void;
	onConfirm: () => void;
}

export const ModalControl: React.FC<IModalControlProps> = (props) => {
	const { modalState, onCloseModal, onConfirm } = props;
	return (
		<div>
			<Modal isOpen={modalState.visible}>
				<ModalHeader>{modalState.header}</ModalHeader>
				<ModalBody>{modalState.message}</ModalBody>
				<ModalFooter>
					<Button color="btn btn-outline-danger" onClick={onConfirm}>
						Confirm
					</Button>
					<Button color="secondary" onClick={onCloseModal}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
