import { Stack } from "@fluentui/react";
import ProtectedRoute from "authentication/ProtectedRoute";
import { BipUsers, CreateBipUser } from "admin/bipuser/containers";
import React from "react";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { IBipUserProps } from ".";
import { useStyles } from "./BipUser.styles";

export const BipUser: React.FC<IBipUserProps> = (props) => {
	const { onBipUserNavigateBack } = props;
	const match = useRouteMatch();
	const history = useHistory();
	const classes = useStyles();

	const onGetDetail = (bipId: number) => {
		const link = `${match.path}/details?bipId=${bipId}`;
		history.push(link);
	};

	const onCreate = () => {
		const link = `${match.path}/create`;
		history.push(link);
	};

	return (
		<Switch>
			<ProtectedRoute
				exact={true}
				path={`${match.path}`}
				render={() => {
					return <BipUsers onCreate={onCreate} onGetDetail={onGetDetail} />;
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/create`}
				render={() => {
					return (
						<CreateBipUser
							userId={0}
							mode={"CREATE"}
							onBipUserNavigateBack={onBipUserNavigateBack}
						/>
					);
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/details`}
				render={() => {
					return (
						<CreateBipUser
							userId={0}
							mode={"EDIT"}
							onBipUserNavigateBack={onBipUserNavigateBack}
						/>
					);
				}}
			/>
		</Switch>
	);
};
