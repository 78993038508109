import * as React from "react";
import { IErrorDialogProps } from ".";
import { Alert } from "reactstrap";

export const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
	const { show, errorMessage, onClose } = props;

	return show ? (
		<Alert color="danger" isOpen={show} toggle={onClose}>
			{errorMessage ? errorMessage : "Something went wrong"}
		</Alert>
	) : null;
};

