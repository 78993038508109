import { Reducer } from "use-immer";
import { baseUrl } from "api/config";
import {
	FieldAttribute,
	FieldType,
	FormField,
	IFormFieldData,
	IFormFieldValue,
	IFormSummary,
	ISection,
	ISectionQuery,
} from "../../admin/questionare";
import {
	AnswerViewType,
	IAnswerDescriptionQuery,
	IAnswerQuery,
	IAnswerState,
	IErrors,
	SaveType,
} from "client/containers";
import { IUseErrorHook } from "shared/hooks";
import { toast } from "react-toastify";

export type IAnswerAction =
	| {
			type: "UPDATE_DATAID";
			answers: IFormFieldData[];
	  }
	| {
			type: "SET_FORM_SUMMARY";
			formSummary: IFormSummary;
	  }
	| {
			type: "SET_QUESTIONS";
			questions: FormField[];
			formSummary: IFormSummary;
	  }
	| {
			type: "ON_CHANGE";
			parentId: number | undefined;
			fieldId: number;
			formId: number;
			fieldAttribute: FieldAttribute;
			fieldType: FieldType;
			value?: string;
			valueId?: number;
			dataId?: number;
	  }
	| {
			type: "ON_SAVE";
			clientId: number;
			isBusy: boolean;
			answerViewType: AnswerViewType;
			saveType: SaveType | null;
			canSubmit: boolean;
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  }
	| {
			type: "LOADING_SECTIONS";
			isLoadingSections: boolean;
	  }
	| {
			type: "SET_SECTIONS";
			sections: ISection[];
	  }
	| {
			type: "SET_NEXT_PREVIOUS";
			sectionId: number;
	  }
	| {
			type: "REMOVE_OPTION";
			fieldId: number;
			dataId: number;
			valueId?: number;
	  }
	| {
			type: "ADD_OPTION";
			fieldId: number;
			valueId?: number;
	  }
	| {
			type: "SET_VALIDATION_ERROR";
			errors: IErrors;
	  }
	| {
			type: "RESET_ANSWER_DATA";
	  }
	| {
			type: "RESET_CAN_SUBMIT";
	  };

export const answerReducer: Reducer<IAnswerState, IAnswerAction> = (
	draft,
	action
): IAnswerState => {
	switch (action.type) {
		case "RESET_CAN_SUBMIT":
			draft.canSubmit = false;
			return draft;
		case "UPDATE_DATAID":
			action.answers.forEach((answer) => {
				const field: FormField | undefined = findFormField(draft.questions, answer.fieldId);
				if (field) {
					const formFieldValue: IFormFieldValue | undefined = field.formFieldValues.find(
						(s) => s.valueId === answer.valueId
					);
					if (formFieldValue) {
						formFieldValue.answers[0].dataId =
							formFieldValue.answers[0].value === "" ? 0 : answer.dataId;
					}
				}
			});

			draft.isBusy = false;
			return draft;
		case "SET_FORM_SUMMARY":
			draft.formSummary = action.formSummary;
			return draft;
		case "RESET_ANSWER_DATA":
			draft.isFormDirty = false;
			draft.canSave = false;
			draft.canSubmit = false;
			draft.answers = [];
			draft.saveType = null;
			return draft;
		case "SET_VALIDATION_ERROR":
			draft.isBusy = false;
			draft.errors = action.errors;
			return draft;
		case "REMOVE_OPTION": {
			draft.isFormDirty = true;
			let field: FormField | undefined = draft.questions.find(
				(s) => s.fieldId === action.fieldId
			);
			if (field) {
				let formFieldValue: IFormFieldValue | undefined = undefined;
				formFieldValue = field.formFieldValues.find((s) => s.valueId === action.valueId);
				if (formFieldValue) {
					let formFieldData: IFormFieldData | undefined = undefined;
					formFieldData = formFieldValue.answers.find((s) => s.dataId === action.dataId);
					if (formFieldData) {
						formFieldValue.answers = formFieldValue.answers.filter(
							(s) => s.dataId !== action.dataId
						);
						return draft;
					}
				}
			}
			return draft;
		}

		case "ADD_OPTION": {
			draft.isFormDirty = true;
			let field: FormField | undefined = draft.questions.find(
				(s) => s.fieldId === action.fieldId
			);
			if (field) {
				let formFieldValue: IFormFieldValue | undefined = undefined;
				formFieldValue = field.formFieldValues.find((s) => s.valueId === action.valueId);
				if (!formFieldValue) {
					formFieldValue = field.formFieldValues[0];
				}
				draft.newDataId = draft.newDataId - 1;
				formFieldValue.answers.unshift({
					clientId: 0,
					fieldId: action.fieldId,
					formId: field.formId,
					value: "#" + formFieldValue.value,
					valueId: formFieldValue.valueId,
					dataId: draft.newDataId,
				});
			}

			return draft;
		}
		case "LOADING_SECTIONS":
			draft.isLoadingSections = true;
			return draft;
		case "SET_SECTIONS":
			draft.isLoadingSections = false;
			draft.sections = action.sections;
			return draft;
		case "SET_QUESTIONS":
			draft.isFormDirty = false;
			draft.canSave = false;
			draft.canSubmit = false;
			draft.answers = [];
			draft.formSummary = action.formSummary;
			draft.questions = onSetQuestion(action.questions);
			draft.isBusy = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		case "ON_CHANGE":
			let field: FormField | undefined = undefined;
			if (action.parentId !== undefined) {
				const parentFormField = findFormField(draft.questions, action.parentId);
				if (parentFormField) {
					field = parentFormField.childFormFields.find(
						(s) => s.fieldId === action.fieldId
					);
				}
			} else {
				field = findFormField(draft.questions, action.fieldId);
			}
			let answer: IFormFieldData | undefined = undefined;
			if (field) {
				// remove error
				if (draft.errors.errors.find((s) => s.fieldId === field?.fieldId)) {
					draft.errors.errors = draft.errors.errors.filter(
						(s) => s.fieldId !== field?.fieldId
					);
				}
				if (action.fieldType === FieldType.Likert) {
					draft.isFormDirty = true;
					const formFieldVal = field.formFieldValues.find(
						(s) => s.valueId === action.valueId
					);
					if (formFieldVal) {
						if (!action.dataId) {
							draft.newDataId = draft.newDataId - 1;
							formFieldVal.answers.push({
								clientId: 0,
								dataId: draft.newDataId,
								fieldId: field.fieldId,
								formId: field.formId,
								value: action.value ? action.value : "",
								valueId: formFieldVal.valueId,
							});
						} else {
							if (action.value) {
								const source = field.formFieldValues.find(
									(s) => s.valueId === action.valueId
								);
								if (source) {
									const dat = source.answers.find(
										(s) => s.dataId === action.dataId
									);
									if (dat && dat.value !== action.value) {
										const array: string[] = action.value.split("#");
										const destination = field.formFieldValues.find(
											(s) => s.value === array[1]
										);
										dat.value = action.value;

										if (destination && destination.valueId !== dat.valueId) {
											dat.valueId = destination.valueId;
											destination.answers.push(dat);
											source.answers = source.answers.filter(
												(s) => s !== dat
											);
										}
									}
								}
							}
						}
						return draft;
					}
				}

				if (action.fieldType === FieldType.Sortable) {
					draft.isFormDirty = true;
					const values = action.value?.split("#");
					let oldIndex: number = values && values[0] ? parseInt(values[0], 10) : 0;
					let newIndex: number = values && values[1] ? parseInt(values[1], 10) : 0;
					const [removed] = field.formFieldValues.splice(oldIndex, 1);
					field.formFieldValues.splice(newIndex, 0, removed);
					field.formFieldValues.forEach((option: IFormFieldValue, index: number) => {
						option.answers[0].value = index.toString();
					});
					return draft;
				}
				const formFieldValue = field.formFieldValues.find(
					(s) => s.valueId === action.valueId
				);

				if (formFieldValue) {
					draft.isFormDirty = true;
					switch (action.fieldType) {
						case FieldType.PF23:
							if (
								action.fieldAttribute === "PF_Good" ||
								action.fieldAttribute === "PF_Ok" ||
								action.fieldAttribute === "PF_Poor" ||
								action.fieldAttribute === "PF_N/A" ||
								action.fieldAttribute === "PF_None"
							) {
								const indexes = [3, 4, 5, 6, 7];
								answer = formFieldValue.answers.find(
									(s) => s.valueId === action.valueId
								);
								if (answer) {
									answer.value = answer.value ? "" : formFieldValue.value;
								}

								field.formFieldValues.forEach(
									(formfield: IFormFieldValue, index: number) => {
										if (
											formfield.valueId !== action.valueId &&
											indexes.includes(index)
										) {
											formfield.answers[0].value = "";
										}
									}
								);
							} else {
								formFieldValue.answers[0].value = action.value ? action.value : "";
							}
							return draft;
						case FieldType.Text:
						case FieldType.TextArea:
						case FieldType.Date:
							formFieldValue.answers[0].value = action.value ? action.value : "";
							showChildQuestion(field);
							return draft;
						case FieldType.Currency:
						case FieldType.Number:
						case FieldType.Percentage:
							formFieldValue.answers[0].value = action.value ? action.value : "";
							showChildQuestion(field);
							field = minMaxValidation(field);
							return draft;
						case FieldType.MultilineText:
						case FieldType.MultilineTextArea:
							answer = formFieldValue.answers.find(
								(s) => s.valueId === action.valueId
							);
							if (answer) {
								answer.value = action.value ? action.value : "";
							}
							showChildQuestion(field);
							return draft;
						case FieldType.Checkbox:
							answer = formFieldValue.answers.find(
								(s) => s.valueId === action.valueId
							);
							if (answer) {
								answer.value = answer.value ? "" : formFieldValue.value;
							}

							field.formFieldValues.forEach((formfield) => {
								if (formfield.valueId !== action.valueId) {
									formfield.answers[0].value = "";
								}
							});
							showChildQuestion(field);
							return draft;
						case FieldType.MultiSelectCheckbox:
							const max = field.fieldConfiguration.max;
							let count: number = 0;
							field.formFieldValues.forEach((s) =>
								s.answers.forEach((d) => {
									if (d.value) {
										count = count + 1;
									}
								})
							);
							answer = formFieldValue.answers.find(
								(s) => s.valueId === action.valueId
							);
							if (max) {
								if (count === 0) {
									if (answer) {
										answer.value = answer.value ? "" : formFieldValue.value;
									}
									showChildQuestion(field);
								} else {
									if (parseInt(max, 10) !== count || answer?.value) {
										if (answer) {
											answer.value = answer.value ? "" : formFieldValue.value;
										}
										showChildQuestion(field);
									}
								}
							}
							return draft;
						case FieldType.TextAndCurrency:
							answer = formFieldValue.answers.find(
								(s) => s.valueId === action.valueId
							);
							if (answer) {
								answer.value = action.value ? action.value : "";
							}
							return draft;
					}
				}
			}
			return draft;
		case "ON_SAVE":
			if (action.answerViewType === "Answer") {
				// const { isSuccess, questions } = validate(draft.questions);
				// draft.questions = questions;
				// if (isSuccess) {
				draft.answers = onSetAnswers(draft.questions, action.clientId);
				//}
				if (action.canSubmit) {
					draft.canSubmit = true;
				} else {
					draft.canSave = true;
				}
				draft.saveType = action.saveType;
				draft.autoSaveTime = new Date().toLocaleTimeString();

				// if (isSuccess) {
				draft.isBusy = action.isBusy;
				draft.isFormDirty = false;
				//}
			}
			return draft;
		case "SET_NEXT_PREVIOUS":
			const sections: ISection[] = [];
			draft.sections.forEach((s: ISection) => {
				sections.push(s);
				s.subSection &&
					s.subSection.forEach((sb: ISection) => {
						sections.push(sb);
					});
			});
			const totalLength = sections.length;
			const activeIndex = sections.findIndex(
				(s) => s.sectionId === parseInt(action.sectionId.toString(), 10)
			);
			draft.activeSection = sections[activeIndex].sectionId.toString();
			if (activeIndex >= 0) {
				if (totalLength !== 1) {
					if (activeIndex === 0) {
						draft.next.disable = false;
						draft.previous.disable = true;
						draft.next.sectionId = sections[activeIndex + 1].sectionId;
						return draft;
					}
					if (activeIndex + 1 < totalLength) {
						draft.next.disable = false;
						draft.next.sectionId = sections[activeIndex + 1].sectionId;
						draft.previous.disable = false;
						draft.previous.sectionId = sections[activeIndex - 1].sectionId;
						return draft;
					}
					if (activeIndex + 1 === totalLength) {
						draft.next.disable = true;
						draft.next.sectionId = 0;
						draft.previous.disable = false;
						draft.previous.sectionId = sections[activeIndex - 1].sectionId;
						return draft;
					}
				}
			} else {
				draft.next.disable = false;
				draft.next.sectionId = sections[0].sectionId;
				draft.previous.disable = true;
				draft.previous.sectionId = 0;
			}
			return draft;
		default:
			throw Error("unknown action");
	}
};

const findFormField = (formFields: FormField[], fieldId: number): FormField | undefined => {
	let result: FormField | undefined = undefined;
	for (let i: number = 0; i < formFields.length; i += 1) {
		if (formFields[i].fieldId === fieldId) {
			result = formFields[i];
			return result;
		} else {
			result = findFormField(formFields[i].childFormFields, fieldId);
			if (result !== undefined) {
				return result;
			}
		}
	}
	return result;
};

const onSetQuestion = (questions: FormField[]): FormField[] => {
	questions.forEach((question: FormField, index: number) => {
		question.sortOrder = index;
		question.parentId = question.parentId === null ? undefined : question.parentId;
		showChildQuestion(question);
		question.formFieldValues.forEach((option: IFormFieldValue, index: number) => {
			if (!option.answers.length && question.fieldType !== FieldType.Likert) {
				option.answers.push({
					dataId: 0,
					fieldId: question.fieldId,
					formId: question.formId,
					valueId: option.valueId,
					value: question.fieldType === FieldType.Sortable ? index.toString() : "",
					clientId: 0,
				});
			}
		});
		if (question.fieldType === FieldType.Sortable) {
			question.formFieldValues.sort((a: any, b: any) => {
				return a.answers[0].value - b.answers[0].value;
			});
		}
		question.childFormFields = onSetQuestion(question.childFormFields);
	});

	return questions;
};

const onSetAnswers = (questions: FormField[], clientId: number): IFormFieldData[] => {
	let answers: IFormFieldData[] = [];
	questions.forEach((s) => {
		s.formFieldValues.forEach((f) => {
			f.answers.forEach((q) => {
				if (q.dataId < 0) {
					q.dataId = 0;
				}
				if (q.dataId !== 0 || q.value) {
					q.clientId = clientId;
					answers.push(q);
				}
			});
		});
		answers = answers.concat(onSetAnswers(s.childFormFields, clientId));
	});
	return answers;
};

const minMaxValidation = (question: FormField): FormField => {
	const field = question;
	const config = field.fieldConfiguration;
	const formFieldValue = field.formFieldValues[0];
	const val = formFieldValue.answers[0].value;
	if (val) {
		if (config.min && config.max) {
			const reg = new RegExp(`^([${config.min}-${config.max}])$`);
			if (!reg.test(val)) {
				formFieldValue.errorMessage = `The answer should be between ${config.min} to ${config.max}`;
			} else {
				formFieldValue.errorMessage = ``;
			}
		}
	} else {
		formFieldValue.errorMessage = ``;
	}
	return field;
};

const showChildQuestion = (field: FormField): FormField => {
	field.childFormFields.forEach((child) => {
		let show: boolean = false;
		const fildConfig = child.fieldConfiguration.displayWhen;
		if (fildConfig) {
			field.formFieldValues.forEach((formfield) => {
				formfield.answers.forEach((answer) => {
					if (fildConfig) {
						if (answer.value && fildConfig.includes(answer.value)) {
							show = true;
						}
					} else {
						show = true;
					}
				});
			});
		} else {
			show = true;
		}
		child.visible = show;
		showChildQuestion(child);
	});
	return field;
};

const axios = require("axios");

export const getSectionsForAnswer = (
	dispatcher: React.Dispatch<IAnswerAction>,
	query: ISectionQuery,
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "LOADING_SECTIONS", isLoadingSections: true });
	try {
		axios
			.get(baseUrl + `Form/Sections/${query.formId}/${query.clientId}`)
			.then((res: any) => {
				console.log(res.data);
				const sections: ISection[] = res.data;
				dispatcher({ type: "SET_SECTIONS", sections });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getFormSummary = (
	dispatcher: React.Dispatch<IAnswerAction>,
	clientId: number,
	onError: IUseErrorHook["onError"]
) => {
	try {
		axios
			.get(baseUrl + `Client/GetBAQ/${clientId}`)
			.then((res: any) => {
				const formSummary: IFormSummary = res.data[0] as IFormSummary;
				dispatcher({ type: "SET_FORM_SUMMARY", formSummary });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const getQuestions = (
	dispatcher: React.Dispatch<IAnswerAction>,
	query: IAnswerQuery,
	viewType: AnswerViewType,
	isBusy: boolean,
	onError: IUseErrorHook["onError"]
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy });
		axios
			.post(baseUrl + "Form/GetAnswers", query)
			.then((res: any) => {
				const questions: FormField[] = res.data as FormField[];
				if ((viewType = "Answer")) {
					axios
						.get(baseUrl + `Client/GetBAQ/${query.clientId}`)
						.then((res: any) => {
							const formSummary: IFormSummary = res.data[0] as IFormSummary;
							dispatcher({ type: "SET_QUESTIONS", questions, formSummary });
						})
						.catch((err: any) => {
							onError(err.response.data);
						});
				} else {
					dispatcher({
						type: "SET_QUESTIONS",
						questions,
						formSummary: {
							answered: 0,
							formId: 0,
							isEnabled: false,
							pending: 0,
							reportId: 0,
							totalQuestions: 0,
							title: "",
							isSubmited: false,
						},
					});
				}
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const saveAnswer = (
	dispatcher: React.Dispatch<IAnswerAction>,
	answers: IFormFieldData[],
	query: IAnswerQuery,
	saveType: SaveType | null,
	onError: IUseErrorHook["onError"]
) => {
	dispatcher({ type: "RESET_ANSWER_DATA" });
	try {
		axios
			.post(baseUrl + "Form/AnswerQuestions", answers)
			.then((res: any) => {
				if (saveType === "AutoSave" || saveType === "ButtonSave") {
					const answersData: IFormFieldData[] = res.data as IFormFieldData[];
					dispatcher({ type: "UPDATE_DATAID", answers: answersData });
					getFormSummary(dispatcher, query.clientId, onError);
				}
				if (saveType === "PaginationSave") {
					getQuestions(dispatcher, query, "Answer", false, onError);
				}
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};

export const validateAndSubmit = (
	dispatcher: React.Dispatch<IAnswerAction>,
	query: IAnswerDescriptionQuery,
	onError: IUseErrorHook["onError"],
	goToHome: () => void
) => {
	try {
		dispatcher({ type: "SET_BUSY", isBusy: true });
		axios
			.get(baseUrl + "Form/ValidateBAQ?ClientId=" + query.client + "&FormId=" + query.form)
			.then((res: any) => {
				const errors: IErrors = res.data;
				if (errors.errors.length > 0) {
					toast.error(`Please answer all required questions!`);
					dispatcher({ type: "SET_VALIDATION_ERROR", errors });
				} else {
					axios
						.post(baseUrl + "Form/SubmitBAQ/" + query.client + "/" + query.form)
						.then((res: any) => {
							goToHome();
						})
						.catch((err: any) => {
							dispatcher({ type: "SET_BUSY", isBusy: false });
							onError(err.response.data);
						});
				}
			})
			.catch((err: any) => {
				dispatcher({ type: "SET_BUSY", isBusy: false });
				onError(err.response.data);
			});
	} catch (e) {
		dispatcher({ type: "SET_BUSY", isBusy: false });
		onError(e.message);
	}
};

export const saveValidateAndSubmit = (
	dispatcher: React.Dispatch<IAnswerAction>,
	answers: IFormFieldData[],
	query: IAnswerDescriptionQuery,
	onError: IUseErrorHook["onError"],
	goToHome: () => void
) => {
	dispatcher({ type: "SET_BUSY", isBusy: true });
	dispatcher({ type: "RESET_CAN_SUBMIT" });
	try {
		axios
			.post(baseUrl + "Form/AnswerQuestions", answers)
			.then((res: any) => {
				axios
					.get(
						baseUrl +
							"Form/ValidateBAQ?ClientId=" +
							query.client +
							"&FormId=" +
							query.form
					)
					.then((res: any) => {
						const errors: IErrors = res.data;
						if (errors.errors.length > 0) {
							toast.error(`Please answer all required questions!`);
							dispatcher({ type: "SET_VALIDATION_ERROR", errors });
						} else {
							axios
								.post(baseUrl + "Form/SubmitBAQ/" + query.client + "/" + query.form)
								.then((res: any) => {
									goToHome();
								})
								.catch((err: any) => {
									dispatcher({ type: "SET_BUSY", isBusy: false });
									onError(err.response.data);
								});
						}
					})
					.catch((err: any) => {
						dispatcher({ type: "SET_BUSY", isBusy: false });
						onError(err.response.data);
					});
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		dispatcher({ type: "SET_BUSY", isBusy: false });
		onError(e.message);
	}
};
