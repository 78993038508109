import { Stack } from "@fluentui/react";
import ProtectedRoute from "authentication/ProtectedRoute";
import React from "react";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { IClientProps } from ".";
import { ClientDetails, Clients, CreateClient } from "..";
import { useStyles } from "./Client.styles";

export const Client: React.FC<IClientProps> = (props) => {
	const { onClientNavigateBack } = props;
	const match = useRouteMatch();
	const history = useHistory();
	const classes = useStyles();

	const onGetDetail = (clientId?: number) => {
		const link = `${match.path}/details?clientId=${clientId}`;
		history.push(link);
	};

	const onEdit = (clientId?: number) => {
		const link = `${match.path}/edit?clientId=${clientId}`;
		history.push(link);
	};

	const onCreate = () => {
		const link = `${match.path}/create`;
		history.push(link);
	};

	return (
		<Switch>
			<ProtectedRoute
				exact={true}
				path={`${match.path}`}
				render={() => {
					return <Clients onGetDetail={onGetDetail} onCreate={onCreate} />;
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/create`}
				render={() => {
					return (
						<CreateClient mode={"CREATE"} onClientNavigateBack={onClientNavigateBack} />
					);
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/details`}
				render={() => {
					return (
						<ClientDetails
							mode={"DETAILS"}
							onEdit={onEdit}
							onClientNavigateBack={onClientNavigateBack}
						/>
					);
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/edit`}
				render={() => {
					return (
						<CreateClient
							mode={"EDIT"}
							onClientNavigateBack={onClientNavigateBack}
							gotoDetails={onGetDetail}
						/>
					);
				}}
			/>
		</Switch>
	);
};
