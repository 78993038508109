import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import ProtectedRoute from "authentication/ProtectedRoute";
import { UseAuthProvider } from "authentication/useAuthContext";
import { SignIn } from "admin/signIn";
import { DashBoard } from "admin/dashboard";
import { ClientDashBoard, ClientSignIn } from "client/pages";

export const Master: React.FC = () => {
	return (
		<BrowserRouter>
			<Switch>
				<UseAuthProvider>
					<Route exact path={"/signin"}>
						<ClientSignIn />
					</Route>
					<Route
						path={"/admin"}
						render={() => {
							return <DashBoard />;
						}}
					/>
					<Route
						path={"/bip"}
						render={() => {
							return <DashBoard />;
						}}
					/>
					<Route
						path={"/client"}
						render={() => {
							return <ClientDashBoard />;
						}}
					/>
					<Route
						path={"/notfound"}
						render={() => {
							return <div>Not Found</div>;
						}}
					/>
				</UseAuthProvider>
			</Switch>
		</BrowserRouter>
	);
};
