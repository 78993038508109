import { IStyle, ITheme } from "@fluentui/react";
import { mergeStyleSets, IProcessedStyleSet } from "@uifabric/styling";

export interface IObjectValueStyles {
	root: IStyle;
	handle: IStyle;
	handleInner: IStyle;
	inheritFunc: IStyle;
	withoutInheritFunc: IStyle;
	valueMarginalia: IStyle;
	valueGroup: IStyle;
	valueGroupChild: IStyle;
	valueGroupChildContent: IStyle;
}

export interface IObjectValueStylesProps {
	theme?: ITheme;
}

export const getQuestionFormDragStyles = (
	props: IObjectValueStylesProps
): IProcessedStyleSet<IObjectValueStyles> => {
	return mergeStyleSets({
		root: ["nx-ObjectValue", {}],
		valueGroup: [
			"nx-ObjectValue-someTest",
			{
				selectors: {
					".nx-ObjectValue-someChild:last-of-type": {
						marginBottom: 0,
					},
				},
			},
		],
		valueGroupChild: [
			"nx-ObjectValue-someChild",
			{
				marginBottom: 6,
			},
		],
		valueGroupChildContent: [
			"nx-ObjectValue-someChildContent",
			{
				zIndex: 200,
				//	height: 50,
				backgroundColor: "#dae3ea",
			},
		],
		handle: [
			"nx-ObjectValue-Handle",
			{
				position: "relative",
				width: 10,
				zIndex: 100,
			},
		],
		handleInner: [
			"nx-ObjectValue-HandleInner",
			{
				background:
					"-webkit-repeating-linear-gradient(-45deg, #F1F1F1, #F1F1F1 3px, #E9E8E8 0, #E9E8E8 6px)",
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				width: 9,
				marginRight: 1,
			},
		],
		inheritFunc: [
			"nv-ValueFunc",
			{
				width: 12,
			},
		],
		withoutInheritFunc: [
			"nv-noInheritanceFunc",
			{
				marginLeft: 12,
			},
		],
		valueMarginalia: [
			"nv-ValueMarginalia",
			{
				marginLeft: 10,
			},
		],
	});
};
