export type FieldAttribute =
	| "title"
	| "description"
	| "section"
	| "answer"
	| "Required"
	| "option"
	| "optionTitle"
	| "optionValue"
	| "fieldCode"
	| "displayWhen"
	| "questionNo"
	| "min"
	| "max"
	| "LikertText"
	| "LikertCheckBox"
	| "PF_Advisor"
	| "PF_Business"
	| "PF_Contact"
	| "PF_Good"
	| "PF_Ok"
	| "PF_Poor"
	| "PF_N/A"
	| "PF_None";

export enum FieldType {
	Text = 1,
	MultilineText = 2,
	Number = 3,
	Date = 4,
	TextArea = 5,
	RadioButton = 6,
	Checkbox = 7,
	Currency = 8,
	MultiSelectCheckbox = 9,
	PlainText = 10,
	Sortable = 11,
	SectionHeading = 12,
	MultilineTextArea = 13,
	Percentage = 14,
	NoAnswer = 15,
	TextAndCurrency = 16,
	Likert = 17,
	PF23 = 18,
}

export enum ActionMode {
	Create = 1,
	Edit = 2,
}
export interface IQuestionDescriptionQuery {
	form: number;
	section: string;
}

export interface FormField {
	questionNo: string;
	parentId: number | undefined;
	fieldId: number;
	formId: number;
	sectionId: number;
	fieldConfiguration: IFieldConfiguration;
	fieldCode: string | undefined;
	title: string | undefined;
	description: string | undefined;
	fieldType: FieldType;
	isRequired: boolean;
	default: string | undefined;
	formFieldValues: IFormFieldValue[];
	visible: boolean;
	childFormFields: FormField[];
	sortOrder: number;
}

export interface IFormFieldValue {
	valueId: number;
	fieldId: number;
	title: string;
	value: string;
	answers: IFormFieldData[];
	errorMessage?: string | JSX.Element;
	sortOrder: number;
}

export interface IFormFieldData {
	clientId: number;
	dataId: number;
	fieldId: number;
	formId: number;
	valueId: number;
	value: string;
}

export interface IFieldConfiguration {
	displayWhen: string | undefined;
	min: string | undefined;
	max: string | undefined;
	answerOptions: IFormFieldData[];
}
