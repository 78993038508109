import React from "react";
import { IQuestionPreviewProps, getQuestionPreviewStyle } from ".";
import { Stack, IStackTokens, getTheme } from "@fluentui/react";
import { PreviewControl } from "shared/containers";
import { FieldType, IFormFieldValue } from "admin/questionare";

const theme = getTheme();
const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const QuestionPreview: React.FC<IQuestionPreviewProps> = (props) => {
	const { question } = props;
	const classes = getQuestionPreviewStyle();
	const {
		questionNo,
		parentId,
		title,
		fieldType,
		fieldId,
		formId,
		fieldCode,
		formFieldValues,
	} = question;

	const isPlainText = fieldType !== FieldType.PlainText && fieldType !== FieldType.SectionHeading;

	return (
		<Stack
			style={{ boxShadow: theme.effects.elevation8 }}
			className={classes.root}
			tokens={verticalGapStackTokens}
		>
			{isPlainText && questionNo && <Stack.Item>{<b>{questionNo})</b>}</Stack.Item>}
			<PreviewControl
				parentId={parentId}
				fieldId={fieldId}
				formId={formId}
				fieldType={FieldType.PlainText}
				fieldAttribute={"answer"}
				value={title}
				disabled={true}
			/>
			{isPlainText && <Stack.Item>1{fieldCode}</Stack.Item>}

			{formFieldValues.length === 0 ? (
				<Stack.Item>
					<PreviewControl
						parentId={parentId}
						fieldId={fieldId}
						formId={formId}
						fieldType={fieldType}
						fieldAttribute={"answer"}
						disabled={true}
					/>
				</Stack.Item>
			) : (
				formFieldValues.map((formFieldValue: IFormFieldValue, index: number) => {
					return (
						<Stack.Item key={`Stack.Item_Option_${index}_${formFieldValue.valueId}`}>
							<PreviewControl
								parentId={parentId}
								fieldId={fieldId}
								formId={formId}
								fieldType={fieldType}
								fieldAttribute={"answer"}
								label={formFieldValue.title}
								disabled={true}
							/>
						</Stack.Item>
					);
				})
			)}
		</Stack>
	);
};
