import React, { useContext } from "react";

import {
	IProtectedRoute,
	AuthStateContext,
	UserModuleRoutes,
	UserModuleRoute,
	ModuleRoute,
	UserType,
} from ".";
import { Route, Redirect } from "react-router-dom";
import { IPermission } from "admin/settings";

const ProtectedRoute: React.FC<IProtectedRoute> = (props) => {
	const { render, ...rest } = props;
	const authState = useContext(AuthStateContext);

	const authenticateNotFound = (
		<Route
			{...rest}
			render={(renderProps) => (
				<Redirect
					{...renderProps}
					to={{
						pathname: "/notfound",
						state: { from: renderProps.location },
					}}
				/>
			)}
		/>
	);

	const signInRoute = (
		<Route
			{...rest}
			render={(renderProps) => (
				<Redirect
					{...renderProps}
					to={{
						pathname: "/signin",
						state: { from: renderProps.location },
					}}
				/>
			)}
		/>
	);

	const checkPermission = (matchUserModuleRoute: ModuleRoute | undefined): JSX.Element => {
		const permission: IPermission | undefined = authState.user.userRolePermissions.find(
			(s) => s.moduleId === matchUserModuleRoute?.module
		);
		if (permission && permission.read) {
			return successRoute;
		} else {
			return authenticateNotFound;
		}
	};

	const successRoute = <Route {...rest} render={render} />;

	if (authState.authenticated) {
		const userModuleRoute: UserModuleRoute | undefined = UserModuleRoutes.find(
			(s) => s.userType === authState.user.userType
		);
		const matchUserModuleRoute: ModuleRoute | undefined = userModuleRoute?.moduleRoute.find(
			(s) => s.route === rest.path
		);
		switch (authState.user.userType) {
			case UserType.Admin:
				if (
					matchUserModuleRoute &&
					(matchUserModuleRoute.route === "/admin/home" ||
						matchUserModuleRoute.route === "/admin/editprofile")
				) {
					return successRoute;
				} else {
					const route = checkPermission(matchUserModuleRoute);
					return route;
				}
			case UserType.BIP:
				if (
					matchUserModuleRoute &&
					(matchUserModuleRoute.route === "/bip/home" ||
						matchUserModuleRoute.route === "/bip/editprofile")
				) {
					return successRoute;
				} else {
					const route = checkPermission(matchUserModuleRoute);
					return route;
				}
			case UserType.Client:
				const clientModule: UserModuleRoute = UserModuleRoutes[2];
				const clientRoute: ModuleRoute | undefined = clientModule.moduleRoute.find(
					(s) => s.route === rest.location?.pathname
				);
				if (clientRoute) {
					return successRoute;
				} else {
					return authenticateNotFound;
				}

			default:
				return authenticateNotFound;
		}
	} else {
		return signInRoute;
	}
};

export default ProtectedRoute;
