import React, { useContext } from "react";
import { AuthStateContext, UserType } from "authentication";
import { useHistory } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { IUserQuery, IUseUserHook, UserFieldMode, UserMode } from ".";
import {
	createUser,
	getUserById,
	getUserRoles,
	getUsers,
	onRemoveUser,
	updateUser,
	userReducer,
} from "..";
import { IUseErrorHook } from "shared/hooks";

export const useUser = (
	mode: UserMode,
	query: IUserQuery,
	onError: IUseErrorHook["onError"]
): IUseUserHook => {
	const history = useHistory();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { roleId } = user;

	const [state, userDispatcher] = useImmerReducer(userReducer, {
		init: true,
		isBusy: true,
		selectedUser: {
			client: "",
			roleId: 0,
			userId: 0,
			userType: UserType.Admin,
			emailAddress: "",
			firstName: "",
			lastName: "",
			userStatus: 0,
			designation: "",
			roleName: "",
		},
		users: [],
		mode,
		query,
		userStatus: 0,
		submitted: false,
		roles: [],
	});

	React.useEffect(() => {
		if (state.submitted) {
			if (state.mode === "CREATE" || state.mode === "EDIT") {
				const link = "/admin/user";
				history.push(link);
			}
		}
	}, [history, mode, state.init, state.mode, state.query.userId, state.submitted]);

	React.useEffect(() => {
		if (mode === "CREATE" && state.init) {
			getUserRoles(userDispatcher, onError);
		}
	}, [mode, onError, state.init, userDispatcher]);

	React.useEffect(() => {
		if (mode === "LIST" && state.init && !query.userId) {
			getUsers(userDispatcher, onError);
		}
	}, [mode, state.init, roleId, user.userId, userDispatcher, query.userId, onError]);

	React.useEffect(() => {
		if (state.init && query.userId && mode === "EDIT") {
			getUserById(userDispatcher, query, onError);
			//getUserRoles(userDispatcher, onError);
		}
	}, [mode, onError, query, query.userId, state.init, userDispatcher]);

	const onCreate = () => {
		createUser(userDispatcher, state.selectedUser, onError);
	};

	const onSave = () => {
		updateUser(userDispatcher, state.selectedUser, onError);
	};

	const onChange = (userFieldMode: UserFieldMode, newValue?: string) => {
		userDispatcher({ type: "ON_CHANGE", userFieldMode, newValue });
	};
	const onRemove = (userId: number) => {
		onRemoveUser(userDispatcher, userId, onError);
	};

	return { state, onChange, onSave, onCreate, onRemove };
};
