import { CommandBar, ICommandBarItemProps, Stack } from "@fluentui/react";
import React, { useContext, useState } from "react";
import { useStyles } from "./Header.styles";
import { IHeaderProps } from ".";
import { LogOut, UserAuthDispatcher } from "authentication/auth.action";
import { AuthStateContext } from "authentication/useAuthContext";
import { UserType } from "authentication";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export const Header: React.FC<IHeaderProps> = (props) => {
	const { onNavigatioPanelChange, onEditProfile } = props;
	const classes = useStyles();
	//const buttonClass = useButtonStyles();
	const authState = useContext(AuthStateContext);
	const { user } = authState;

	const userAuthDispatcher = UserAuthDispatcher();
	const [toggle, setToggle] = useState(false);
	const ToggleMenu = () => {
		setToggle(!toggle);
	};
	const getShortName = () => {
		return `${user.firstName?.charAt(0).toString()}`;
	};

	const _items: ICommandBarItemProps[] = [
		{
			key: "GlobalNavButton",
			text: "",
			iconProps: { iconName: "GlobalNavButton" },
			onClick: () => {
				onNavigatioPanelChange();
			},
		},
		{
			key: "logo",
			onRender: (item) => (
				<a href="/">
					<img src="/profi_logo.png" alt="profitune logo"></img>
				</a>
			),
		},
	];

	if (authState.user.userType === UserType.Client)
		_items.splice(0, 1, {
			key: "",
			text: "",
		});

	// const PersonaCommandBarComponent = () => {
	// 	return <Persona text={authState.user.firstName} size={PersonaSize.size24} />;
	// };

	const _farItems: ICommandBarItemProps[] = [
		// {
		// 	key: "persona",
		// 	text: "",
		// 	cacheKey: "personamyCacheKey",
		// 	commandBarButtonAs: PersonaCommandBarComponent,
		// },
		{
			key: "newItem",
			text: authState.user.firstName,
			cacheKey: "myCacheKey",
			//	buttonStyles: buttonClass,
			subMenuProps: {
				items: [
					{
						key: "EditProfile",
						text: "Edit Profile",
						iconProps: { iconName: "Edit" },
						onClick: () => {
							onEditProfile();
						},
					},
					{
						key: "Logout",
						text: "Logout",
						iconProps: { iconName: "SignOut" },
						onClick: () => {
							LogOut(userAuthDispatcher);
						},
					},
				],
			},
		},
	];

	return (
		<nav className="navbar navbar-expand-lg navbar-light">
			<Link className="navbar-brand" to="/admin/home">
				<img src="/images/profitune-logo.svg" alt="Profitune Logo" />
			</Link>
			<UncontrolledDropdown>
				<DropdownToggle tag={Link} className="btn btn-secondary-c dropdown-toggle">
					<figure className="user">{getShortName()}</figure> {user.firstName}
				</DropdownToggle>
				{/* <a className="btn btn-secondary-c dropdown-toggle" href="#" role="button">
					<figure className="user">{getShortName()}</figure> {user.firstName}
				</a> */}
				<DropdownMenu>
					<DropdownItem onClick={onEditProfile}>
						<i className="icon-iregistration" /> Edit Profile
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={() => LogOut(userAuthDispatcher)}>
						<i className="icon-shutdown" />
						Logout
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			{/* <div className="dropdown" onClick={ToggleMenu}>
				<a className="btn btn-secondary-c dropdown-toggle" href="#" role="button">
					<figure className="user">{getShortName()}</figure> {user.firstName}
				</a>
				<div
					className={"dropdown-menu " + (toggle && "show")}
					aria-labelledby="dropdownMenuLink"
				>
					<button className="dropdown-item" onClick={onEditProfile}>
						<i className="icon-iregistration" />
						Edit Profile
					</button>
					<button className="dropdown-item" onClick={() => LogOut(userAuthDispatcher)}>
						<i className="icon-shutdown" />
						Logout
					</button>
				</div>
			</div> */}
		</nav>
	);

	return (
		<Stack>
			<CommandBar
				styles={classes}
				items={_items}
				farItems={_farItems}
				ariaLabel="Use left and right arrow keys to navigate between commands"
			/>
		</Stack>
	);
};
