import React from "react";
import { IQuestionsPreviewProps, QuestionPreview } from ".";
import { Stack, IStackTokens } from "@fluentui/react";
import { FormField } from "admin/questionare";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const QuestionsPreview: React.FC<IQuestionsPreviewProps> = (props) => {
	const { formFields } = props;
	return (
		<Stack.Item grow={11} tokens={verticalGapStackTokens}>
			{formFields.map((formField: FormField, index: number) => {
				return (
					<Stack.Item
						key={`Stack.Item ${index}_${formField.fieldId}`}
						tokens={verticalGapStackTokens}
					>
						<QuestionPreview
							key={`QuestionPreview_${index}_${formField.fieldId}`}
							question={formField}
						/>
					</Stack.Item>
				);
			})}
		</Stack.Item>
	);
};
