import React from "react";
import { IMultiValueProps } from ".";
import { QuestionControl } from "shared/containers";
import { Stack, PrimaryButton, IStackTokens, IconButton, Separator } from "@fluentui/react";
import { MinMaxControl } from "shared/components";
import { ActionMode, FieldType, IFormFieldValue } from "admin/questionare";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
};

export const MultiValue: React.FC<IMultiValueProps> = (props) => {
	const { parentId, formfield, action, addNewOption, removeOption, onChange } = props;
	const { fieldId, formId, fieldType, formFieldValues, fieldConfiguration } = formfield;

	const addNewFieldValue = () => {
		if (addNewOption) addNewOption(parentId, fieldId);
	};

	const removeNewFieldValue = (valueId: number) => {
		if (removeOption) removeOption(parentId, fieldId, valueId);
	};

	const showOptions =
		fieldType === FieldType.Sortable ||
		fieldType === FieldType.MultilineText ||
		fieldType === FieldType.MultiSelectCheckbox ||
		fieldType === FieldType.Checkbox ||
		fieldType === FieldType.MultilineTextArea ||
		fieldType === FieldType.TextAndCurrency ||
		fieldType === FieldType.Likert;
	const disableMultiline =
		fieldType === FieldType.MultilineText ||
		fieldType === FieldType.MultilineTextArea ||
		fieldType === FieldType.TextAndCurrency;
	const showMinMaxConfig =
		fieldType === FieldType.Number ||
		fieldType === FieldType.Currency ||
		fieldType === FieldType.Percentage ||
		fieldType === FieldType.MultiSelectCheckbox ||
		fieldType === FieldType.Sortable;

	return (
		<>
			{showMinMaxConfig && (
				<Stack tokens={verticalGapStackTokens}>
					<Stack horizontal>
						<MinMaxControl
							parentId={parentId}
							fieldId={fieldId}
							formId={formId}
							fieldType={fieldType}
							fieldAttribute={"option"}
							configuration={fieldConfiguration}
							disabled={disableMultiline}
							onChange={onChange}
						/>
					</Stack>
				</Stack>
			)}
			{showOptions && (
				<Stack tokens={verticalGapStackTokens}>
					<Stack.Item>
						<Separator alignContent="start">Options</Separator>
					</Stack.Item>
					{action === ActionMode.Create && (
						<Stack.Item>
							<PrimaryButton text="Add" onClick={addNewFieldValue} />
						</Stack.Item>
					)}
					{formFieldValues.map((formFieldValue: IFormFieldValue, index: number) => {
						return (
							<Stack
								horizontal
								key={`Stack.Item_multi_value${index}_${formFieldValue.fieldId}`}
							>
								<>
									<QuestionControl
										parentId={parentId}
										fieldId={fieldId}
										formId={formId}
										fieldType={fieldType}
										value={formFieldValue.value}
										valueId={formFieldValue.valueId}
										fieldAttribute={"option"}
										configuration={formFieldValue}
										disabled={disableMultiline}
										onChange={onChange}
									/>

									{action === ActionMode.Create && (
										<IconButton
											iconProps={{ iconName: "Delete" }}
											title="Delete"
											onClick={() =>
												removeNewFieldValue(formFieldValue.valueId)
											}
										/>
									)}
								</>
							</Stack>
						);
					})}
				</Stack>
			)}
		</>
	);
};
