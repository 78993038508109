import { CustomText } from "client/components/Controls/CustomText";
import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
export const FormConfig = (props: any) => {
	console.log(props);
	const { config, onChange } = props;
	return (
		<>
			<Modal centered isOpen={true} size="xl">
				<AvForm onValidSubmit={props.onSubmit}>
					<div className="modal-header">Calculation Settings</div>
					<ModalBody>
						<div className="form-wrap">
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"yearlyInt"}
										value={config.formula.yearlyInt}
										onChange={onChange}
										label={"Yearly Int"}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"taxPc"}
										label={"Tax Pc"}
										value={config.formula.taxPc}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"marketingCost"}
										label={"Marketing Cost"}
										value={config.formula.marketingCost}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"referralConversionRate"}
										label={"Referral Conversion Rate"}
										value={config.formula.referralConversionRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"conversionRateIncrease"}
										label={"Conversion Rate Increase"}
										value={config.formula.conversionRateIncrease}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"productIncrease"}
										label={"Product Increase"}
										value={config.formula.productIncrease}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"salesIncrease"}
										label={"Sales Increase"}
										value={config.formula.salesIncrease}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"cosReduce"}
										label={"COS Reduce"}
										value={config.formula.cosReduce}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"bankFeesSavingRate"}
										label={"Bank Fees Saving Rate"}
										value={config.formula.bankFeesSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"insuranceSavingRate"}
										label={"Insurance Saving Rate"}
										value={config.formula.insuranceSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"merchantFeesSavingRate"}
										label={"Merchant Fees Saving Rate"}
										value={config.formula.merchantFeesSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"telephoneCostsSavingRate"}
										label={"Telephone Costs Saving Rate"}
										value={config.formula.telephoneCostsSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"printingCostsSavingRate"}
										label={"Printing Costs Saving Rate"}
										value={config.formula.printingCostsSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"rentSavingRate"}
										label={"Rent Saving Rate"}
										value={config.formula.rentSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"utilitiesSavingRate"}
										label={"Utilities Saving Rate"}
										value={config.formula.utilitiesSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"bottleNeckReductionRate"}
										label={"BottleNeck Reduction Rate"}
										value={config.formula.bottleNeckReductionRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"professionalFeesReductionRate"}
										label={"Professional Fees Reduction Rate"}
										value={config.formula.professionalFeesReductionRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"vehicleExpensesReductionRate"}
										label={"Vehicle Expenses Reduction Rate"}
										value={config.formula.vehicleExpensesReductionRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"salesBaseIncreaseRate"}
										label={"Sales Base Increase Rate"}
										value={config.formula.salesBaseIncreaseRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
								<div className="col-md-3">
									<CustomText
										name={"overStockSavingRate"}
										label={"OverStockSaving Rate"}
										value={config.formula.overStockSavingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<CustomText
										name={"overstaffingRate"}
										label={"Overstaffing Rate"}
										value={config.formula.overstaffingRate}
										onChange={onChange}
										type="number"
										required="true"
										autoComplete="off"
										suffix="%"
									></CustomText>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-secondary" onClick={props.onCancel}>
							Cancel
						</button>
						<button type="submit" className="btn btn-primary">
							Save
						</button>
					</ModalFooter>
				</AvForm>
			</Modal>
		</>
	);
};
