import React, { useContext } from "react";
import { Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { IQuestionareProps } from ".";

import ProtectedRoute from "authentication/ProtectedRoute";
import { Stack } from "@fluentui/react";
import { CreateQuestion, EditQuestion, Form, QuestionDetails } from "..";
import { AnswerContainer, IAnswerDescriptionQuery, Report } from "client/containers";
import { IFormQuery, useForm } from "../containers";
import { useError } from "shared";
import { AuthStateContext, UserType } from "authentication";

export const Questionare: React.FC<IQuestionareProps> = (props) => {
	const match = useRouteMatch();
	const history = useHistory();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const queryString = require("query-string");
	const location = useLocation();
	const formQuery = queryString.parse(location.search) as IFormQuery;
	if (user.userType === UserType.BIP) formQuery.isActiveOnly = true;
	const { errorstate, onErrorClose, onError } = useError();
	//const { state } = useForm(formQuery, onError);
	//const { forms, isBusy } = state;

	const onGetDetail = (formId: number) => {
		const link = `${match.path}/details?formId=${formId}`;
		history.push(link);
	};

	const onEditForm = (formId: number | undefined) => {
		const link = `${match.path}/edit?form=${formId}`;
		history.push(link);
	};

	const onEditFromPreview = (query: IAnswerDescriptionQuery) => {
		const link = `${match.path}/edit?form=${query.form}&section=${query.section}`;
		history.push(link);
	};

	const goToForms = () => {
		const link = `${match.path}`;
		history.push(link);
	};

	return (
		<Switch>
			<ProtectedRoute
				exact={true}
				path={`${match.path}`}
				render={() => {
					return <Form onGetDetail={onGetDetail} />;
				}}
			/>
			<ProtectedRoute
				exact={true}
				path={`${match.path}/details`}
				render={() => {
					return <QuestionDetails onEditForm={onEditForm} goToForms={goToForms} />;
				}}
			/>
			<ProtectedRoute
				path={`${match.path}/edit`}
				render={() => {
					return <EditQuestion />;
				}}
			/>
			<ProtectedRoute
				path={`${match.path}/create`}
				render={() => {
					return <CreateQuestion />;
				}}
			/>
			<ProtectedRoute
				path={`${match.path}/preview`}
				render={() => {
					return (
						<AnswerContainer AnswerViewType={"Preview"} goToEdit={onEditFromPreview} />
					);
				}}
			/>
			<ProtectedRoute
				path={`${match.path}/answer`}
				render={() => {
					return <AnswerContainer AnswerViewType={"Answer"} />;
				}}
			/>
			<ProtectedRoute
				path={`${match.path}/report`}
				render={() => {
					return <Report />;
				}}
			/>
		</Switch>
	);
};
