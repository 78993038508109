import ProtectedRoute from "authentication/ProtectedRoute";
import React from "react";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useImmer } from "use-immer";
import { RolesContainer } from "..";
import { EditPermissionContainer } from "../containers";

export interface ISettingsProps {
	//editPermission: (roleId?: number) => void;
}

export const Settings: React.FC<ISettingsProps> = (props) => {
	const match = useRouteMatch();
	const history = useHistory();
	const [roleName, setRoleName] = useImmer("");
	const editPermission = (roleId: number, name: string) => {
		setRoleName((draft) => {
			draft = name;
			return draft;
		});
		const link = `${match.path}/edit?roleId=${roleId}`;
		history.push(link);
	};
	const goToSettings = (roleId?: number) => {
		const link = `${match.path}`;
		history.push(link);
	};

	return (
		<Switch>
			<ProtectedRoute
				path={`${match.path}/edit`}
				render={() => {
					return (
						<EditPermissionContainer roleName={roleName} goToSettings={goToSettings} />
					);
				}}
			/>
			<ProtectedRoute
				path={`${match.path}`}
				render={() => {
					return <RolesContainer editPermission={editPermission} />;
				}}
			/>
		</Switch>
	);
};
