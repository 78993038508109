import React from "react";
import { IFormQuery, IUseFormHook } from ".";
import { useImmerReducer } from "use-immer";
import { formReducer, getActiveForms, getForms } from "admin/questionare";
import { IUseErrorHook } from "shared/hooks";

export const useForm = (query: IFormQuery, onError: IUseErrorHook["onError"]): IUseFormHook => {
	const [state, formStateDispatcher] = useImmerReducer(formReducer, {
		init: true,
		forms: [],
		isBusy: false,
	});

	React.useEffect(() => {
		if (state.init && query.isActiveOnly) {
			getActiveForms(formStateDispatcher, onError);
		} else if (state.init && query.form === undefined) {
			getForms(formStateDispatcher, onError);
		}
	}, [formStateDispatcher, onError, query.form, state.init, query.isActiveOnly]);

	return {
		state,
	};
};
