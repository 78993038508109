import { Reducer } from "react";
import { baseUrl } from "api/config";
import { ISection, ISectionQuery, ISectionState } from "..";
import { IUseErrorHook } from "shared/hooks";

export type ISectionActions =
	| {
			type: "SET_SECTIONS";
			sections: ISection[];
	  }
	| {
			type: "SET_BUSY";
			isBusy: boolean;
	  };

export const sectionReducer: Reducer<ISectionState, ISectionActions> = (
	draft,
	action
): ISectionState => {
	switch (action.type) {
		case "SET_SECTIONS":
			draft.sections = action.sections;
			draft.isBusy = false;
			draft.init = false;
			return draft;
		case "SET_BUSY":
			draft.isBusy = action.isBusy;
			return draft;
		default:
			throw Error("unknown action");
	}
};

const axios = require("axios");

export const getAllSections = (
	dispatcher: React.Dispatch<ISectionActions>,
	query: ISectionQuery,
	onError: IUseErrorHook["onError"]
) => {
	try {
		axios
			.get(baseUrl + "Form/Sections/" + query.formId)
			.then((res: any) => {
				const sections: ISection[] = res.data;
				dispatcher({ type: "SET_SECTIONS", sections });
			})
			.catch((err: any) => {
				onError(err.response.data);
			});
	} catch (e) {
		onError(e.message);
	}
};
