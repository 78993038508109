import React from "react";
import { IFormListProps } from ".";
import {
	IColumn,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	PrimaryButton,
	Stack,
	IStackTokens,
} from "@fluentui/react";
import { IForm } from "admin/questionare";

export const FormList: React.FC<IFormListProps> = (props) => {
	const verticalGapStackTokens: IStackTokens = {
		childrenGap: 15,
		padding: 10,
	};
	const { forms, onGetDetail } = props;
	const _columns: IColumn[] = [
		{
			key: "questionare",
			name: "Questionare",
			fieldName: "title",
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "view",
			name: "",
			fieldName: "",
			minWidth: 100,
			maxWidth: 200,
			isResizable: false,
		},
	];

	const onRenderItemColumn = (item: IForm, index?: number, column?: IColumn) => {
		if (!column) return;

		if (column.key === "questionare") {
			return <>{item.title}</>;
		}
		if (column.key === "view") {
			return <PrimaryButton text="View Details" onClick={() => onGetDetail(item.formId)} />;
		}
	};

	return (
		<Stack horizontal tokens={verticalGapStackTokens}>
			<Stack.Item grow={8}>
				<DetailsList
					items={forms}
					columns={_columns}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.none}
					onRenderItemColumn={onRenderItemColumn}
				/>
			</Stack.Item>
			<Stack.Item></Stack.Item>
		</Stack>
	);
};
