import { IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { Answers } from "client/components";
import React from "react";
import { Spinner } from "reactstrap";
import { Loading } from "shared/components";
import { useStyles } from "./AnswerHolder.styles";
import { IAnswerHolderProps } from "./AnswerHolder.types";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const AnswerHolder: React.FC<IAnswerHolderProps> = (props) => {
	const {
		errors,
		questions,
		isBusy,
		previous,
		next,
		onChange,
		removeOption,
		addOption,
		onPreviousClick,
		onNextClick,
	} = props;
	const classes = useStyles();

	// return (
	// 	<Stack tokens={verticalGapStackTokens} grow={11} className={classes.formContent}>
	// 		{isBusy ? (
	// 			<Loading />
	// 		) : (
	// 			<Answers
	// 				questions={questions}
	// 				onChange={onChange}
	// 				removeOption={removeOption}
	// 				addOption={addOption}
	// 			/>
	// 		)}
	// 		<Stack horizontal verticalAlign="end" tokens={verticalGapStackTokens}>
	// 			<PrimaryButton
	// 				text="Previous"
	// 				disabled={previous.disable}
	// 				onClick={() => onPreviousClick()}
	// 			/>
	// 			<PrimaryButton text="Next" disabled={next.disable} onClick={() => onNextClick()} />
	// 		</Stack>
	// 	</Stack>
	// );

	return (
		<>
			{isBusy ? (
				<div className="d-flex justify-content-center align-items-center vh-100">
					<Spinner color="primary">Loading!</Spinner>
				</div>
			) : (
				<>
					<Answers
						errors={errors}
						questions={questions}
						onChange={onChange}
						removeOption={removeOption}
						addOption={addOption}
					/>
				</>
			)}
		</>
	);
};
