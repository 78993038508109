import { AuthStateContext, UserType } from "authentication";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { IUseErrorHook } from "shared/hooks";
import { useImmerReducer } from "use-immer";
import { ClientMode, IClientQuery, IUseClientHook } from ".";
import {
	clientReducer,
	createClient,
	downloadReport,
	getClientById,
	getClients,
	getClientsByBIP,
	getReports,
	removeClient,
	removeReport,
	updateClient,
	uploadReport,
} from "..";
import { ClientFieldMode } from "./useClient.types";

export const useClient = (
	mode: ClientMode,
	query: IClientQuery,
	onError: IUseErrorHook["onError"]
): IUseClientHook => {
	const history = useHistory();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { roleId, userType, userId } = user;
	const subUrl = userType === UserType.Admin ? "admin" : "bip";
	const [state, clientDispatcher] = useImmerReducer(clientReducer, {
		init: true,
		isBusy: true,
		selectedClient: {
			clientId: 0,
			emailAddress: "",
			firstName: "",
			lastName: "",
			businessName: "",
			currency: "",
			addressLine1: "",
			addressLine2: "",
			businessId: "",
			city: "",
			countryId: null,
			street: "",
			zip: "",
			mobile: "",
			officePhone: "",
			userStatus: 0,
			baqStatus: "",
			bip: "",
			bipid: userType === UserType.BIP ? userId : null,
		},
		clients: [],
		mode,
		query,
		submitted: false,
		roleId,
		userType,
		baqStatus: "",
		reports: [],
		pageinfo: {
			CurrentPage: 0,
			HasNext: false,
			HasPrevious: false,
			PageSize: 0,
			TotalCount: 0,
			TotalPages: 0,
		},
		reportLoaderIsBusy: false,
		uploadLoaderIsBusy: false,
	});

	React.useEffect(() => {
		if (state.submitted) {
			if (state.mode === "CREATE") {
				const link = `/${subUrl}/client`;
				history.push(link);
			}
			if (state.mode === "EDIT") {
				const link = `/${subUrl}/client/details?clientId=` + state.query.clientId;
				history.push(link);
			}
		}
	}, [
		clientDispatcher,
		history,
		mode,
		query.clientId,
		state.init,
		state.mode,
		state.query.clientId,
		state.submitted,
	]);

	React.useEffect(() => {
		if (mode === "CREATE" && state.init) {
			clientDispatcher({ type: "SET_BUSY", isBusy: false });
		}
	}, [clientDispatcher, mode, query.clientId, state.init]);

	React.useEffect(() => {
		if (mode === "LIST" && state.init && !query.clientId) {
			if (userType === 2) {
				getClientsByBIP(clientDispatcher, user.userId, onError);
			} else {
				getClients(clientDispatcher, onError);
			}
		}
	}, [clientDispatcher, mode, query.clientId, state.init, userType, user.userId, onError]);

	React.useEffect(() => {
		if (state.init && query.clientId && (mode === "EDIT" || mode === "DETAILS")) {
			getClientById(clientDispatcher, query, onError);
			//getReports(clientDispatcher, query.clientId, onError);
		}
	}, [clientDispatcher, mode, onError, query, query.clientId, state.init]);

	const onCreate = () => {
		createClient(clientDispatcher, state.selectedClient, onError);
	};

	const onSave = () => {
		updateClient(clientDispatcher, state.selectedClient, onError);
	};
	const onChange = (clientFieldMode: ClientFieldMode, newValue?: string) => {
		clientDispatcher({ type: "ON_CHANGE", clientFieldMode, newValue });
	};

	const onDownload = (
		form: number,
		client: number,
		selectedTemplate: string,
		reportDate: string
	) => {
		downloadReport(clientDispatcher, form, client, selectedTemplate, reportDate, onError);
	};

	const onUploadReport = (files: File[], formId: number, clientId: number) => {
		uploadReport(clientDispatcher, files, formId, clientId, onError);
	};
	const onRemoveReport = (reportId: number) => {
		removeReport(clientDispatcher, reportId, state.selectedClient.clientId, onError);
	};
	const onRemoveClient = (clientId: number) => {
		removeClient(clientDispatcher, clientId, onError);
	};

	return {
		state,
		dispatcher: clientDispatcher,
		onChange,
		onSave,
		onCreate,
		onDownload,
		onUploadReport,
		onRemoveReport,
		onRemoveClient,
	};
};
